/* eslint-disable @typescript-eslint/no-empty-interface */
// Components
import { EditOutlined } from '@ant-design/icons';
import { Quill } from '../../../components/form/Quill';
import { DrawerForm, ProFormItem } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { fetchAsyncRubrica } from '../../../store/slices/rubricaDetalle';

// Services
import { updateRubrica } from '../../../services/rubricas.service';

// Interfaces
import { memo, type FC } from 'react';
import type { NewRubricaFormValues } from '../../../interfaces/rubrica';

interface EditRubricaObservationsDetailsProps {}

export const EditRubricaObservationsDetails: FC<EditRubricaObservationsDetailsProps> = memo(() => {
  const dispatch = useAppDispatch();

  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);

  const onRefresh = () => {
    dispatch(fetchAsyncRubrica(rubrica?.id as number));
  };

  return (
    <DrawerForm<NewRubricaFormValues>
      title="Editar observaciones"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        observaciones: rubrica.observaciones || ''
      }}
      onFinish={async (values) => {
        return updateRubrica(rubrica.id, values)
          .then(() => {
            message.success('Observaciones actualizadas correctamente');
            onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar las observaciones del rubrica, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos del rubrica'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormItem name="observaciones">
            <Quill />
          </ProFormItem>
        </Col>
      </Row>
    </DrawerForm>
  );
});

EditRubricaObservationsDetails.displayName = 'EditRubricaObservationsDetails';
