import { memo } from 'react';
import StatusComponent from './StatusComponent';

const Pagina500: React.FC = memo(() => {
  return (
    <StatusComponent
      status="500"
      title="Oops!"
      subTitle="Por un error inesperado, no podemos mostrarte esta página."
    />
  );
});

Pagina500.displayName = 'Pagina500';

export default Pagina500;
