import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as SubsidiosService from '../../services/subsidios.service';

type Entity = Awaited<ReturnType<typeof SubsidiosService.getSubsidio>>;

export const fetchSubsidio = createAsyncThunk<Entity, number | string>(
  'subsidioDetalle/fetch',
  (id) => {
    return SubsidiosService.getSubsidio(id);
  }
);

export const fetchAsyncSubsidio = createAsyncThunk<Entity, number | string>(
  'subsidioDetalle/fetchAsync',
  (id) => {
    return SubsidiosService.getSubsidio(id);
  }
);

interface SubsidioDetalleState {
  loading: boolean;
  subsidio: Entity | null;
}

const initialState: SubsidioDetalleState = { loading: false, subsidio: null };

const SubsidioDetalleSlice = createSlice({
  name: 'subsidioDetalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubsidio.fulfilled, (state, action) => {
      state.subsidio = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSubsidio.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubsidio.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncSubsidio.fulfilled, (state, action) => {
      state.subsidio = action.payload;
      state.loading = false;
    });
  }
});

export const subsidioDetalleActions = {
  fetchSubsidio,
  ...SubsidioDetalleSlice.actions
};

export default SubsidioDetalleSlice.reducer;
