import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ExpedientesService from '../../services/expedientes.service';

type Entity = Awaited<ReturnType<typeof ExpedientesService.getExpediente>>;

export const fetchExpediente = createAsyncThunk<Entity, number | string>(
  'expedienteDetalle/fetch',
  (id) => {
    return ExpedientesService.getExpediente(id);
  }
);

export const fetchAsyncExpediente = createAsyncThunk<Entity, number | string>(
  'expedienteDetalle/fetchAsync',
  (id) => {
    return ExpedientesService.getExpediente(id);
  }
);

interface ExpedienteDetalleState {
  loading: boolean;
  expediente: Entity | null;
}

const initialState: ExpedienteDetalleState = { loading: false, expediente: null };

const ExpedienteDetalleSlice = createSlice({
  name: 'expedienteDetalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExpediente.fulfilled, (state, action) => {
      state.expediente = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchExpediente.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchExpediente.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncExpediente.fulfilled, (state, action) => {
      state.expediente = action.payload;
      state.loading = false;
    });
  }
});

export const expedienteDetalleActions = {
  fetchExpediente,
  ...ExpedienteDetalleSlice.actions
};

export default ExpedienteDetalleSlice.reducer;
