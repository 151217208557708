import { DireccionCooperativa } from '../interfaces/cooperativa';

export const parseAddress = (address: DireccionCooperativa) => {
  const { calle, altura, piso, departamento } = address;

  let fullAddress = '';

  if (calle) {
    fullAddress += calle;
  }

  if (altura) {
    fullAddress += ` Nro ${altura}`;
  }

  if (piso) {
    fullAddress += ` Piso ${piso}`;
  }

  if (departamento) {
    fullAddress += ` Depto ${departamento}`;
  }

  return fullAddress;
};
