import { GlobalOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';

// Interfaces
import type { IUser } from '../../interfaces/user';
import type { ProLayoutProps } from '@ant-design/pro-layout';

export default (isMobile: boolean, user: IUser) => {
  const routes: ProLayoutProps['route'][] = [];

  if (user.permissions.includes('browse_cooperativas')) {
    routes.push({ path: '/cooperativas', name: 'Cooperativas', icon: <GlobalOutlined /> });
  }

  if (user.permissions.includes('browse_personas')) {
    routes.push({ path: '/personas', name: 'Personas', icon: <TeamOutlined /> });
  }

  if (user.permissions.includes('browse_referentes')) {
    routes.push({ path: '/referentes', name: 'Referentes', icon: <SolutionOutlined /> });
  }

  return {
    routes
  };
};
