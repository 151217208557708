import dayjs from 'dayjs';
import { DatePickerProps } from 'antd';
import type { TipoDatoAdicional } from '../interfaces/tipoDatoAdicional';
import { ProFormDatePicker, ProFormDateTimePicker } from '@ant-design/pro-form';
import { ProFormDigit, ProFormText, ProFormTextArea } from '@ant-design/pro-form';

export const booleanOptions = [
  { value: 1, label: 'Sí' },
  { value: 0, label: 'No' }
];

export const DatePickerDefaultProps = {
  format: 'DD/MM/YYYY',
  style: { width: '100%' }
} as DatePickerProps;

export const DatePickerDefaultPropsWithDisabledDate = {
  ...DatePickerDefaultProps,
  disabledDate: (date: dayjs.Dayjs) => date.isAfter(dayjs())
} as DatePickerProps;

export const getProFieldForAditionalType = (type: TipoDatoAdicional) => {
  switch (type.tipo_de_dato) {
    case 'text':
      return ProFormText;
    case 'textarea':
      return ProFormTextArea;
    case 'number':
      return ProFormDigit;
    case 'date':
      return ProFormDatePicker;
    case 'datetime':
      return ProFormDateTimePicker;
    default:
      return ProFormText;
  }
};
