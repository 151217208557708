import axios from '../config/axios';

import type {
  Subsidio,
  SubsidioDetalle,
  SubsidiosQueryParams,
  NewSubsidioFormValues
} from '../interfaces/subsidio';
import type { PaginatedResponse } from '../interfaces/api';

/**************************************************
 *
 *            Subsidios
 *
 *************************************************/

const SUBSIDIOS_PATH = 'subsidios';

/**
 * @description Recupera desde la API los subsidios de una cooperativa
 * @returns {Promise<PaginatedResponse<Subsidio>>} Subsidios
 */
export const getSubsidios = async (params: SubsidiosQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Subsidio>>(SUBSIDIOS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un subsidio de una cooperativa
 * @param {number | string} id Identificador del subsidio
 * @returns {Promise<Subsidio>} Subsidio
 */
export const getSubsidio = async (id: number | string) => {
  return await axios.get<{ data: SubsidioDetalle }>(`${SUBSIDIOS_PATH}/${id}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Registra en la API un subsidio de una cooperativa
 * @returns {Promise<Subsidio>} Subsidio
 */
export const newSubsidio = async (data: NewSubsidioFormValues) => {
  return await axios.post<{ data: Subsidio }>(SUBSIDIOS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza en la API un subsidio de una cooperativa
 * @param {number | string} id Identificador del subsidio
 * @returns {Promise<Subsidio>} Subsidio
 */
export const updateSubsidio = async (id: number | string, data: NewSubsidioFormValues) => {
  return await axios.put<{ data: Subsidio }>(`${SUBSIDIOS_PATH}/${id}`, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Elimina en la API un subsidio de una cooperativa
 * @param {number | string} id Identificador del subsidio
 * @returns {Promise<Subsidio>} Subsidio
 */
export const deleteSubsidio = async (id: number | string) => {
  return await axios.delete<void>(`${SUBSIDIOS_PATH}/${id}`).then((response) => {
    return response.data;
  });
};
