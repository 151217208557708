import axios from '../config/axios';

import type { ActividadAfip, ActividadesAfipQueryParams } from '../interfaces/actividadAfip';

const PATH = `actividades_afip`;

/**
 * @description Recupera desde la API las actividades Afip
 * @returns {Promise<ActividadAfip[]>} Actividades Afip
 */
export const getActividadesAfip = async (params: ActividadesAfipQueryParams = {}) => {
  return await axios.get<{ data: ActividadAfip[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
