/* eslint-disable @typescript-eslint/no-empty-interface */

import { memo } from 'react';
import { Avatar } from 'antd';
import ProList from '@ant-design/pro-list';
import {
  BookOutlined,
  NumberOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  FieldNumberOutlined
} from '@ant-design/icons';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { fetchAsyncRubrica } from '../../../../../../store/slices/rubricaDetalle';

// Utils
import dayjs from 'dayjs';
import { EditRubricaDetails } from '../../../../components/editRubrica';

export const RubricasDetailsCard = memo(() => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user!);
  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);

  const onRefresh = () => {
    dispatch(fetchAsyncRubrica(rubrica.id));
  };

  const dataSource = [
    {
      label: 'Fecha de entrada',
      avatar: <Avatar icon={<CalendarOutlined />} />,
      value: rubrica?.fecha_entrada
        ? dayjs(rubrica.fecha_entrada).format('DD/MM/YYYY')
        : 'Sin especificar'
    },

    {
      label: 'Fecha de entrega',
      avatar: <Avatar icon={<CarryOutOutlined />} />,
      value: rubrica?.fecha_entrega
        ? dayjs(rubrica.fecha_entrega).format('DD/MM/YYYY')
        : 'Sin especificar'
    },

    {
      label: 'Fecha de rubrica',
      avatar: <Avatar icon={<CalendarOutlined />} />,
      value: rubrica?.fecha_rubrica
        ? dayjs(rubrica.fecha_rubrica).format('DD/MM/YYYY')
        : 'Sin especificar'
    },

    {
      label: 'Libro',
      avatar: <Avatar icon={<BookOutlined />} />,
      value: rubrica?.rubrica_libro.nombre || 'Sin especificar'
    },
    {
      label: 'Número de libro',
      avatar: <Avatar icon={<FieldNumberOutlined />} />,
      value: rubrica?.numero_libro || 'Sin especificar'
    },
    {
      label: 'Cantidad de folios',
      avatar: <Avatar icon={<NumberOutlined />} />,
      value: rubrica?.cantidad_folios || 'Sin especificar'
    },
    // {
    //   label: 'Hojas',
    //   avatar: <Avatar icon={<FileOutlined />} />,
    //   value: `${rubrica.desde_hoja} - ${rubrica.hasta_hoja}`
    // },
    {
      label: 'Fecha de registro',
      avatar: <Avatar icon={<CalendarOutlined />} />,
      value: rubrica?.created_at
        ? dayjs(rubrica.created_at).format('DD/MM/YYYY')
        : 'Sin especificar'
    }
  ];

  return (
    <ProList
      bordered
      dataSource={dataSource}
      cardProps={{
        title: 'Datos generales',
        bodyStyle: { paddingTop: 20 },
        extra:
          user && user.permissions.includes('edit_rubricas') ? (
            <EditRubricaDetails onRefresh={onRefresh} rubrica={rubrica} />
          ) : null
      }}
      metas={{
        avatar: {},
        title: { dataIndex: 'label' },
        description: { dataIndex: 'value' }
      }}
    ></ProList>
  );
});

RubricasDetailsCard.displayName = 'RubricasDetailsCard';
