import Avatar from './AvatarDropdown';

import { FC, memo } from 'react';
export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: FC<{ collapsed?: boolean }> = memo((props) => {
  return <Avatar collapsed={props.collapsed} />;
});

GlobalHeaderRight.displayName = 'GlobalHeaderRight';

export default GlobalHeaderRight;
