import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as EstadosSubsidiosService from '../../services/estadoSubsidio.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof EstadosSubsidiosService.getEstadosSubsidio>>[number];

export const fetchEstadosSubsidios = createAsyncThunk<Entity[]>(
  'estadosSubsidios/fetch',
  (_params, api) => {
    const { estadosSubsidios } = api.getState() as RootState;
    if (estadosSubsidios.entities.length) return Promise.resolve(estadosSubsidios.entities);
    return EstadosSubsidiosService.getEstadosSubsidio();
  }
);

interface EstadosSubsidiosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: EstadosSubsidiosState = { loading: false, entities: [] };

const estadosSubsidiosSlice = createSlice({
  name: 'estadosSubsidios',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEstadosSubsidios.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEstadosSubsidios.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEstadosSubsidios.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const estadosSubsidiosActions = estadosSubsidiosSlice.actions;

export default estadosSubsidiosSlice.reducer;
