// Components
import { EditOutlined } from '@ant-design/icons';
import { ProFormSelect } from '@ant-design/pro-form';
import { Button, message as Message, Row } from 'antd';
import { Tooltip, notification as Notification, Col } from 'antd';
import CustomFromDrawer from '../../../../../../components/drawer/form';

// Services
import {
  deleteEmpresaAnteriorActividad,
  newEmpresaAnteriorActividad
} from '../../../../../../services/empresaAnterior.service';

// Redux
import { useAppSelector } from '../../../../../../store/store';

// Interfaces
import { memo, type FC } from 'react';
import type { EmpresaAnteriorActividad } from '../../../../../../interfaces/empresaAnterior';

interface AddActividadAfipProps {
  onRefresh?: () => void;
  currentActivities: EmpresaAnteriorActividad[];
}

export const AddActividadAfip: FC<AddActividadAfipProps> = memo((props) => {
  const { currentActivities, onRefresh } = props;

  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const actividadesAfip = useAppSelector((state) => state.actividadesAfip.entities);
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);

  return (
    <CustomFromDrawer<{ actividades: number[] }>
      maxWidth={600}
      title={'Editar actividades'}
      drawerProps={{ destroyOnClose: true }}
      initialValues={{ actividades: currentActivities.map((a) => a.actividad_afip.id) }}
      trigger={
        <Tooltip title="Editar actividades">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      onFinish={async (values) => {
        const promises: Promise<any>[] = [];

        const actividadesToAdd = values.actividades.filter(
          (a) => !currentActivities.some((c) => c.actividad_afip.id === a)
        );
        const actividadesToRemove = currentActivities.filter(
          (c) => !values.actividades.some((a) => a === c.actividad_afip.id)
        );

        actividadesToAdd.forEach((a) => {
          promises.push(
            newEmpresaAnteriorActividad({
              empresas_anteriores_id: empresa.id,
              actividades_afip_id: a
            })
          );
        });

        actividadesToRemove.forEach((a) => {
          promises.push(deleteEmpresaAnteriorActividad(a.id));
        });

        return Promise.all(promises)
          .then(() => {
            message.success('Actividades actualizadas exitosamente');
            if (onRefresh) onRefresh();
            return true;
          })
          .catch((error) => {
            let description = `Ocurrió un error inesperado al actualizar las actividades de la cooperativa, por favor intente nuevamente`;
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormSelect
            showSearch
            mode="multiple"
            name="actividades"
            label="Actividades que realiza"
            placeholder="Seleccione las actividades que realiza la cooperativa"
            options={actividadesAfip.map((a) => ({ label: a.nombre, value: a.id }))}
          />
        </Col>
      </Row>
    </CustomFromDrawer>
  );
});

AddActividadAfip.displayName = 'AddActividadAfip';
