import axios from '../config/axios';

import type { Nacionalidad, NacionalidadQueryParams } from '../interfaces/nacionalidad';

const PATH = `nacionalidades`;

/**
 * @description Recupera desde la API las nacionalidades.
 * @returns {Promise<Nacionalidad[]>} Estados civiles
 */
export const getNacionalidades = async (params: NacionalidadQueryParams = {}) => {
  return await axios.get<{ data: Nacionalidad[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
