import axios from '../config/axios';

import type { TipoComision, TipoComisionQueryParams } from '../interfaces/tipoComision';

const PATH = `tipos_comisiones`;

/**
 * @description Recupera desde la API los tipos de comisiones
 * @returns {Promise<TipoComision[]>} Listado de Tipos de Comisiones
 */
export const getTiposComisiones = async (params: TipoComisionQueryParams = {}) => {
  return await axios.get<{ data: TipoComision[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
