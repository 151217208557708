import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as AsambleasService from '../../services/asambleas.service';

type Entity = Awaited<ReturnType<typeof AsambleasService.getAsamblea>>;

type EntityDato = Awaited<ReturnType<typeof AsambleasService.getAsambleaData>>;

export const fetchAsamblea = createAsyncThunk<Entity, number | string>(
  'asambleaDetalle/fetch',
  (id) => {
    return AsambleasService.getAsamblea(id);
  }
);

export const fetchAsyncAsamblea = createAsyncThunk<Entity, number | string>(
  'asambleaDetalle/fetchAsync',
  (id) => {
    return AsambleasService.getAsamblea(id);
  }
);

export const fetchAsambleaDato = createAsyncThunk<EntityDato, number | string>(
  'asambleaDetalle/fetchDato',
  (id) => {
    return AsambleasService.getAsambleaData(id);
  }
);

export const fetchAsyncAsambleaDato = createAsyncThunk<EntityDato, number | string>(
  'asambleaDetalle/fetchAsyncDato',
  (id) => {
    return AsambleasService.getAsambleaData(id);
  }
);

interface AsambleaDetalleState {
  loading: boolean;
  asamblea: Entity | null;
  dato: EntityDato | null;
}

const initialState: AsambleaDetalleState = { loading: false, asamblea: null, dato: null };

const AsambleaDetalleSlice = createSlice({
  name: 'asamblea_detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsamblea.fulfilled, (state, action) => {
      state.asamblea = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsamblea.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAsamblea.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncAsamblea.fulfilled, (state, action) => {
      state.asamblea = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsambleaDato.fulfilled, (state, action) => {
      state.dato = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsambleaDato.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAsambleaDato.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncAsambleaDato.fulfilled, (state, action) => {
      state.dato = action.payload;
      state.loading = false;
    });
  }
});

export const asambleaDetalleActions = { fetchAsamblea, ...AsambleaDetalleSlice.actions };

export default AsambleaDetalleSlice.reducer;
