import axios from '../config/axios';
import { NewOrigenFormValues, Origen, OrigenQueryParams } from '../interfaces/origen';

const PATH = `origenes`;

/**
 * @description Recupera desde la API los datos de origenes para expedientes
 * @returns {Promise<Origen[]>}
 */
export const getOrigenes = async (params: OrigenQueryParams = {}) => {
  return await axios.get<{ data: Origen[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Registra en la API un facturacion de una cooperativa
 * @returns {Promise<Origen>} Facturacion
 */
export const newOrigen = async (data: NewOrigenFormValues) => {
  return await axios.post<{ data: Origen }>(PATH, data).then((response) => {
    return response.data.data;
  });
};
