import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as TiposCooperativasService from '../../services/cooperativas.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposCooperativasService.getTiposCooperativas>>[number];

export const fetchTiposCooperativas = createAsyncThunk<Entity[]>(
  'tiposCooperativas/fetch',
  (_params, api) => {
    const { tiposCooperativas } = api.getState() as RootState;
    if (tiposCooperativas.entities.length) return Promise.resolve(tiposCooperativas.entities);
    return TiposCooperativasService.getTiposCooperativas();
  }
);

interface TiposCooperativasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposCooperativasState = { loading: false, entities: [] };

const tiposCooperativasSlice = createSlice({
  name: 'tiposCooperativas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposCooperativas.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposCooperativas.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposCooperativas.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposCooperativasActions = {
  ...tiposCooperativasSlice.actions,
  fetchTiposCooperativas
};

export default tiposCooperativasSlice.reducer;
