import { memo, useEffect } from 'react';

// Components
import { RubricaDetailsContainer } from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchRubrica } from '../../../../store/slices/rubricaDetalle';
import { fetchRubricasLibros } from '../../../../store/slices/rubricasLibros';

const LOADING_SLICES = ['rubricaDetalle'] as const;

export const CooperativaRubricaDetailsPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string; id_rubrica: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id && params.id_rubrica) {
      dispatch(fetchRubrica(params.id_rubrica));
    }
  }, [dispatch, params.id_rubrica, params.id]);

  useEffect(() => {
    dispatch(fetchRubricasLibros());
  }, [dispatch]);

  return <RubricaDetailsContainer loading={loading} />;
});

CooperativaRubricaDetailsPage.displayName = 'CooperativaRubricaDetailsPage';

export default CooperativaRubricaDetailsPage;
