import { memo, useEffect } from 'react';

// Components
import NewCooperativaEmpresaContainer from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchCooperativa } from '../../../../store/slices/cooperativaDetalle';
import { fetchActividadesAfip } from '../../../../store/slices/actividadesAfip';

const LOADING_SLICES = ['actividadesAfip'] as const;

export const NewCooperativaEmpresaAnteriorPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id) {
      dispatch(fetchActividadesAfip());
      dispatch(fetchCooperativa(params.id));
    }
  }, [dispatch, params.id]);

  return <NewCooperativaEmpresaContainer loading={loading} />;
});

NewCooperativaEmpresaAnteriorPage.displayName = 'NewCooperativaEmpresaAnteriorPage';

export default NewCooperativaEmpresaAnteriorPage;
