import axios from '../config/axios';

import type { TipoDocumento, TipoDocumentoQueryParams } from '../interfaces/tipoDocumento';

const PATH = `tipos_documentos`;

/**
 * @description Recupera desde la API los tipos de documentos
 * @returns {Promise<TipoDocumento[]>} Listado de tipos de documentos
 */
export const getTiposDocumentos = async (params: TipoDocumentoQueryParams = {}) => {
  return await axios.get<{ data: TipoDocumento[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
