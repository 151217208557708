/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react';
import { Button } from 'antd';
import Table from '@ant-design/pro-table';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import type { ProTableProps } from '@ant-design/pro-table';
import type { ParamsType } from '@ant-design/pro-provider';

type Props<T> = ProTableProps<T, ParamsType>;

const CustomTable = <T extends Record<string, any>>(props: Props<T>) => {
  return (
    <Table<T>
      rowKey={'id'}
      revalidateOnFocus
      scroll={{ x: 1000 }}
      dateFormatter="string"
      pagination={{
        defaultPageSize: 30,
        showQuickJumper: true
      }}
      {...props}
      showSorterTooltip={false}
      search={
        props.search === undefined || props.search
          ? {
              labelWidth: 120,
              layout: 'vertical',
              ...(props.search || {}),
              optionRender: (searchConfig: any, props: any, dom: any) => {
                const filters = Object.values(searchConfig.form?.getFieldsValue() || {}).filter(
                  (a) => (Array.isArray(a) ? a.length : !!a)
                ).length;

                return [
                  <Button
                    ghost={!!filters}
                    type={filters ? 'primary' : undefined}
                    icon={filters ? <ExclamationCircleOutlined /> : null}
                    onClick={() => {
                      searchConfig.form.resetFields();
                      searchConfig.form.submit();
                    }}
                    key="clear"
                  >
                    Limpiar {filters ? `(${filters})` : ''}
                  </Button>,
                  dom[1]
                ];
              }
            }
          : false
      }
    />
  );
};

export const ProTable = memo(CustomTable) as typeof CustomTable;
