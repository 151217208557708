import axios from '../config/axios';

import type {
  EstadoCooperativa,
  EstadosCooperativasQueryParams
} from '../interfaces/estadoCooperativa';

const PATH = `estados_cooperativas`;

/**
 * @description Recupera desde la API los estados de las cooperativas
 * @returns {Promise<EstadoCooperativa[]>} Estados de cooperativas
 */
export const getEstadosCooperativas = async (params: EstadosCooperativasQueryParams = {}) => {
  return await axios.get<{ data: EstadoCooperativa[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
