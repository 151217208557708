import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as TiposDireccionesService from '../../services/tiposDirecciones.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposDireccionesService.getTiposDirecciones>>[number];

export const fetchTiposDirecciones = createAsyncThunk<Entity[]>(
  'tiposDirecciones/fetch',
  (_params, api) => {
    const { tiposDirecciones } = api.getState() as RootState;
    if (tiposDirecciones.entities.length) return Promise.resolve(tiposDirecciones.entities);
    return TiposDireccionesService.getTiposDirecciones();
  }
);

interface TiposDireccionesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposDireccionesState = { loading: false, entities: [] };

const tiposDireccionesSlice = createSlice({
  name: 'tiposDirecciones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposDirecciones.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposDirecciones.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposDirecciones.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposDireccionesActions = { ...tiposDireccionesSlice.actions, fetchTiposDirecciones };

export default tiposDireccionesSlice.reducer;
