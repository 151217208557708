import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import createCompressor from 'redux-persist-transform-compress';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Reducers
import authReducer from './slices/auth';
import sexosReducer from './slices/sexos';
import partidosReducer from './slices/partidos';
import origenesReducer from './slices/origenes';
import programasReducer from './slices/programas';
import localidadesReducer from './slices/localidades';
import tiposCargosReducer from './slices/tiposCargos';
import tiposAlertasReducer from './slices/tiposAlertas';
import personaDetalleReducer from './slices/personaDetalle';
import tiposAsambleasReducer from './slices/tiposAsambleas';
import nacionalidadesReducer from './slices/nacionalidades';
import estadosCivilesReducer from './slices/estadosCiviles';
import rubricasLibrosReducer from './slices/rubricasLibros';
import tiposContactosReducer from './slices/tiposContactos';
import consejoDetalleReducer from './slices/consejoDetalle';
import rubricaDetalleReducer from './slices/rubricaDetalle';
import empresaDetalleReducer from './slices/empresaDetalle';
import subsidioDetalleReducer from './slices/subsidioDetalle';
import asambleaDetalleReducer from './slices/asambleaDetalle';
import tiposComisionesReducer from './slices/tiposComisiones';
import actividadesAfipReducer from './slices/actividadesAfip';
import tiposDocumentosReducer from './slices/tiposDocumentos';
import tiposDireccionesReducer from './slices/tiposDirecciones';
import estadosSubsidiosReducer from './slices/estadosSubsidios';
import expedienteDetalleReducer from './slices/expedienteDetalle';
import tiposCooperativasReducer from './slices/tiposCooperativas';
import cooperativaDetalleReducer from './slices/cooperativaDetalle';
import facturacionDetalleReducer from './slices/facturacionDetalle';
import origenesFechaEntradaReducer from './slices/origenFechaEntrada';
import estadosCooperativasReducer from './slices/estadosCooperativas';
import documentacionesAvalReducer from './slices/documentacionesAval';
import tiposDatosRequeridosReducer from './slices/tiposDatosRequeridos';
import fiscalizacionDetalleReducer from './slices/fiscalizacionDetalle';
import tiposDatosAdicionalesReducer from './slices/tiposDatosAdicionales';

const appReducer = combineReducers({
  auth: authReducer,
  sexos: sexosReducer,
  origenes: origenesReducer,
  partidos: partidosReducer,
  programas: programasReducer,
  localidades: localidadesReducer,
  tiposCargos: tiposCargosReducer,
  tiposAlertas: tiposAlertasReducer,
  personaDetalle: personaDetalleReducer,
  tiposAsambleas: tiposAsambleasReducer,
  nacionalidades: nacionalidadesReducer,
  estadosCiviles: estadosCivilesReducer,
  rubricasLibros: rubricasLibrosReducer,
  tiposContactos: tiposContactosReducer,
  consejoDetalle: consejoDetalleReducer,
  rubricaDetalle: rubricaDetalleReducer,
  empresaDetalle: empresaDetalleReducer,
  asambleaDetalle: asambleaDetalleReducer,
  tiposComisiones: tiposComisionesReducer,
  actividadesAfip: actividadesAfipReducer,
  tiposDocumentos: tiposDocumentosReducer,
  subsidioDetalle: subsidioDetalleReducer,
  tiposDirecciones: tiposDireccionesReducer,
  estadosSubsidios: estadosSubsidiosReducer,
  tiposCooperativas: tiposCooperativasReducer,
  expedienteDetalle: expedienteDetalleReducer,
  facturacionDetalle: facturacionDetalleReducer,
  cooperativaDetalle: cooperativaDetalleReducer,
  documentacionesAval: documentacionesAvalReducer,
  estadosCooperativas: estadosCooperativasReducer,
  tiposDatosRequeridos: tiposDatosRequeridosReducer,
  origenesFechaEntrada: origenesFechaEntradaReducer,
  fiscalizacionDetalle: fiscalizacionDetalleReducer,
  tiposDatosAdicionales: tiposDatosAdicionalesReducer
});

// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'auth/removeUser') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const whitelist = [
  'auth',
  'sexos',
  'partidos',
  'origenes',
  'estadosSubsidios',
  'actividadesAfip',
  'estadosCiviles',
  'nacionalidades',
  'localidades',
  'tiposCargos',
  'tiposAlertas',
  // 'programas',
  'tiposContactos',
  // 'tiposAsambleas',
  'estadosCooperativas',
  'tiposComisiones',
  'tiposDocumentos',
  // 'tiposDirecciones',
  'tiposCooperativas',
  'tiposDatosAdicionales',
  'rubricasLibros'
  // 'documentacionesAval'
] as string[];

const compressor = createCompressor();

const persistedReducer = persistReducer(
  {
    storage,
    whitelist,
    key: 'root',
    transforms: [
      compressor,
      ...whitelist
        .filter((s) => s !== 'auth')
        .map((s) => expireReducer(s, { expireSeconds: 60 * 15 }))
    ]
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
