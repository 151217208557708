import ProList from '@ant-design/pro-list';
import { memo, useRef, useState } from 'react';
import {
  EmpresaAnteriorActividad,
  EmpresaAnteriorActividadQueryParams
} from '../../../../../../interfaces/empresaAnterior';
import { getEmpresasAnterioresActividades } from '../../../../../../services/empresaAnterior.service';
import { useAppSelector } from '../../../../../../store/store';
import { orderBy } from 'lodash';
import { ActionType } from '@ant-design/pro-table';
import { Avatar, Empty } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { AddActividadAfip } from './addActivityDrawer';

export const EmpresaDetailsActivities = memo(() => {
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);

  const actionRef = useRef<ActionType>();
  const user = useAppSelector((state) => state.auth.user!);

  const [currentActivities, setCurrentActivities] = useState<EmpresaAnteriorActividad[]>([]);

  const onRefresh = () => {
    if (actionRef.current) {
      actionRef.current.reload();
    }
  };

  return (
    <ProList<EmpresaAnteriorActividad>
      bordered
      size="small"
      revalidateOnFocus
      actionRef={actionRef}
      cardProps={{
        title: 'Actividades de la empresa',
        bodyStyle: { paddingTop: 20 },
        extra:
          user && user.permissions.includes('edit_empresa_anterior_actividades') ? (
            <AddActividadAfip currentActivities={currentActivities} onRefresh={onRefresh} />
          ) : undefined
      }}
      rowKey={(record) => record.id}
      pagination={{
        pageSize: 50,
        hideOnSinglePage: true,
        showTotal(total, range) {
          return `${range[0]}-${range[1]} de ${total} actividades`;
        }
      }}
      request={(params) => {
        const { pageSize: perPage, current: page } = params;

        const queryParams: EmpresaAnteriorActividadQueryParams = {
          page,
          perPage,
          empresas_anteriores_id: empresa.id
        };

        return getEmpresasAnterioresActividades(queryParams).then((response) => {
          const data = orderBy(response.data, (a) => a.actividad_afip.nombre);
          setCurrentActivities(data);

          return {
            data,
            success: true,
            total: response.meta.total
          };
        });
      }}
      locale={{
        emptyText: <Empty description="No hay actividades registradas para la empresa" />
      }}
      metas={{
        avatar: {
          render: () => {
            return <Avatar icon={<BookOutlined />} />;
          }
        },
        actions: {},
        title: {
          render(dom, entity) {
            return entity.actividad_afip.nombre;
          }
        },
        description: {
          render(dom, entity) {
            return entity.actividad_afip.codigo;
          }
        }
      }}
    ></ProList>
  );
});

EmpresaDetailsActivities.displayName = 'EmpresaDetailsActivities';
