import axios from '../config/axios';

import type { Localidad, LocalidadQueryParams } from '../interfaces/localidad';

const PATH = `localidades`;

/**
 * @description Recupera desde la API las localidades de la provincia de Buenos Aires
 * @returns {Promise<Localidad[]>} Estados civiles
 */
export const getLocalidades = async (params: LocalidadQueryParams = {}) => {
  return await axios.get<{ data: Localidad[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
