// Components
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip, notification as Notification, Col } from 'antd';
import { Button, FormInstance, message as Message, Row } from 'antd';
import { DrawerForm, ProFormDatePicker, ProFormText } from '@ant-design/pro-form';

import { DatePickerDefaultProps } from '../../../../../../constants/form';

// Utils
import dayjs from 'dayjs';

// Redux
import { useAppSelector } from '../../../../../../store/store';

// Interfaces
import { memo, type FC, useRef, useEffect } from 'react';
import type {
  LeyApropiacion,
  NewLeyExpropiacionFormValues
} from '../../../../../../interfaces/leyApropiacion';
import {
  newLeyDeApropiacion,
  updateLeyDeApropiacion
} from '../../../../../../services/empresaAnterior.service';

interface AddFacturacionDetalleRecordProps {
  isEdit?: boolean;
  onClose?: () => void;
  onRefresh?: () => void;
  ley?: LeyApropiacion;
}

export const AddLawRecord: FC<AddFacturacionDetalleRecordProps> = memo((props) => {
  const { ley, isEdit, onClose } = props;

  const form = useRef<FormInstance>();
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);

  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  useEffect(() => {
    if (ley)
      form.current?.setFieldsValue({
        ...ley,
        fecha_sancion: dayjs(ley.fecha_sancion),
        fecha_vencimiento: dayjs(ley.fecha_vencimiento),
        fecha_promulgacion: dayjs(ley.fecha_promulgacion),
        fecha_publicacion_bo: dayjs(ley.fecha_publicacion_bo)
      });
  }, [ley]);

  return (
    <DrawerForm<NewLeyExpropiacionFormValues>
      formRef={form}
      drawerProps={{
        onClose,
        width: 750,
        destroyOnClose: true
      }}
      open={ley ? true : undefined}
      title={isEdit ? 'Editar ley' : 'Registrar nueva ley de expropriación'}
      trigger={
        isEdit ? undefined : (
          <Tooltip title="Registrar nueva ley de expropriación">
            <Button type="primary" size="small" shape="round" icon={<PlusOutlined />} />
          </Tooltip>
        )
      }
      onFinish={async (values) => {
        if (!isEdit) {
          values.empresas_anteriores_id = empresa.id;
        }

        values.fecha_promulgacion = dayjs(values.fecha_promulgacion).format('YYYY-MM-DD');
        values.fecha_publicacion_bo = dayjs(values.fecha_publicacion_bo).format('YYYY-MM-DD');
        values.fecha_sancion = dayjs(values.fecha_sancion).format('YYYY-MM-DD');
        values.fecha_vencimiento = values.fecha_vencimiento
          ? dayjs(values.fecha_vencimiento).format('YYYY-MM-DD')
          : undefined;

        const action = isEdit
          ? updateLeyDeApropiacion(ley?.id as number, values)
          : newLeyDeApropiacion(values);

        return action
          .then(() => {
            message.success(isEdit ? 'Ley actualizada con éxito' : 'Ley registrada con éxito');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description = `Ocurrió un error inesperado al cargar la ley, por favor intente nuevamente`;
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormText
            name="descripcion"
            label="Descripción"
            placeholder="Ingrese la descripción"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                max: 45,
                message: 'Este campo no puede tener más de 45 caracteres'
              }
            ]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            name="fecha_sancion"
            label="Fecha de sanción"
            placeholder="Seleccione la fecha de sanción"
            fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            name="fecha_promulgacion"
            label="Fecha de promulgación"
            placeholder="Seleccione la fecha de promulgación"
            fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            name="fecha_publicacion_bo"
            label="Fecha de publicación en el boletín oficial"
            placeholder="Seleccione la fecha de publicación en el boletín oficial"
            fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            name="fecha_vencimiento"
            label="Fecha de vencimiento"
            placeholder="Seleccione la fecha de vencimiento"
            fieldProps={{
              format: 'DD/MM/YYYY',
              style: { width: '100%' }
            }}
          />
        </Col>
      </Row>
    </DrawerForm>
  );
});

AddLawRecord.displayName = 'AddLawRecord';
