/* eslint-disable @typescript-eslint/no-empty-interface */
// Components
import { EditOutlined } from '@ant-design/icons';
import { Quill } from '../../../components/form/Quill';
import { DrawerForm, ProFormItem } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { fetchAsyncRubrica } from '../../../store/slices/rubricaDetalle';

// Services
import { updateRubrica } from '../../../services/rubricas.service';

// Interfaces
import { memo, type FC } from 'react';
import type { NewRubricaFormValues } from '../../../interfaces/rubrica';

interface EditRubricaNotasDetailsProps {}

export const EditRubricaNotasDetails: FC<EditRubricaNotasDetailsProps> = memo(() => {
  const dispatch = useAppDispatch();

  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);

  const onRefresh = () => {
    dispatch(fetchAsyncRubrica(rubrica?.id as number));
  };

  return (
    <DrawerForm<NewRubricaFormValues>
      title="Editar notas"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        notas: rubrica.notas || ''
      }}
      onFinish={async (values) => {
        return updateRubrica(rubrica.id, values)
          .then(() => {
            message.success('Notas actualizadas correctamente');
            onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar las notas de la rúbrica, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar las notas de la rúbrica'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormItem name="notas">
            <Quill />
          </ProFormItem>
        </Col>
      </Row>
    </DrawerForm>
  );
});

EditRubricaNotasDetails.displayName = 'EditRubricaNotasDetails';
