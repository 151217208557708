/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as OrigenesService from '../../services/origenes.service';

type Entity = Awaited<ReturnType<typeof OrigenesService.getOrigenes>>[number];

export const fetchOrigenes = createAsyncThunk<Entity[]>('origenes/fetch', (_params, api) => {
  return OrigenesService.getOrigenes();
});

interface OrigenesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: OrigenesState = { loading: false, entities: [] };

const origenesSlice = createSlice({
  name: 'origenes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrigenes.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchOrigenes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchOrigenes.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const origenesActions = { ...origenesSlice.actions, fetchOrigenes };

export default origenesSlice.reducer;
