export const ROUTES = {
  HOMEPAGE_ROUTE: '/',

  // Auth
  LOGIN_ROUTE: '/ingresar',
  PROFILE_ROUTE: '/perfil',
  FORGOT_PASSWORD_ROUTE: '/olvide-contraseña',
  RESET_PASSWORD_ROUTE: '/recuperar-contraseña',

  // Cooperativas
  COOPERATIVAS_LIST: '/cooperativas',
  COOPERATIVAS_NEW: '/cooperativas/nueva',
  COOPERATIVAS_DETAILS: '/cooperativas/:id',

  // Consejos
  COOPERATIVAS_NEW_CONSEJO: '/cooperativas/:id/consejo/nuevo',
  COOPERATIVAS_CONSEJO_DETAILS: '/cooperativas/:id/consejo/:id_consejo',

  // Asambleas
  COOPERATIVAS_NEW_ASAMBLEA: '/cooperativas/:id/asamblea/nueva',
  COOPERATIVAS_ASAMBLEA_DETAILS: '/cooperativas/:id/asamblea/:id_asamblea',
  COOPERATIVAS_ASAMBLEA_NEW_RECORD: '/cooperativas/:id/asamblea/:id_asamblea/registros/nuevo',
  COOPERATIVAS_ASAMBLEA_RECORD_DETAILS:
    '/cooperativas/:id/asamblea/:id_asamblea/registros/:id_registro',

  // Facturación
  COOPERATIVAS_NEW_FACTURACION: '/cooperativas/:id/balances/nueva',
  COOPERATIVAS_FACTURACION_DETAILS: '/cooperativas/:id/balances/:id_facturacion',

  // Fiscalizaciones
  COOPERATIVAS_NEW_FISCALIZACION: '/cooperativas/:id/fiscalizacion/nueva',
  COOPERATIVAS_FISCALIZACION_DETAILS: '/cooperativas/:id/fiscalizacion/:id_fiscalizacion',

  // Expedientes
  COOPERATIVAS_NEW_EXPEDIENTE: '/cooperativas/:id/expediente/nuevo',
  COOPERATIVAS_EXPEDIENTE_DETAILS: '/cooperativas/:id/expediente/:id_expediente',

  // Subsidios
  COOPERATIVAS_NEW_SUBSIDIO: '/cooperativas/:id/subsidios/nuevo',
  COOPERATIVAS_SUBSIDIO_DETAILS: '/cooperativas/:id/subsidios/:id_subsidio',

  // EmpresaAnterior
  COOPERATIVAS_NEW_EMPRESA_ANTERIOR: '/cooperativas/:id/empresa-anterior/nueva',
  COOPERATIVAS_EMPRESA_ANTERIOR_DETAILS: '/cooperativas/:id/empresa-anterior/:id_empresa_anterior',

  // Rubricas
  COOPERATIVAS_NEW_RUBRICA: '/cooperativas/:id/rubricas/nueva',
  COOPERATIVAS_RUBRICA_DETAILS: '/cooperativas/:id/rubricas/:id_rubrica',

  // Personas
  PERSONAS_LIST: '/personas',
  PERSONAS_NEW: '/personas/nueva',
  PERSONAS_DETAILS: '/personas/:id',

  // Referentes
  REFERENTES_LIST: '/referentes'
};
