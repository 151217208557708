import React, { lazy, Suspense } from 'react';
import { useAppSelector } from './store/store';
import { createBrowserHistory } from 'history';
import { ProtectedRoute } from './components/routes';
import { PageLoading } from '@ant-design/pro-layout';
import { Route, Routes, Navigate } from 'react-router-dom';
import { unstable_HistoryRouter as Router } from 'react-router-dom';

// Constants
import { ROUTES } from './constants/routes-constants';

// Styles
import './styles/main.scss';

// Interfaces
import type { IUser } from './interfaces/user';

// Components
import LoginPage from './pages/auth/login';
import Error500Page from './pages/status/Pagina500';
import NotFoundPage from './pages/status/Pagina404';
import NotAllowedPage from './pages/status/Pagina403';
import ResetPassword from './pages/auth/resetPassword';
import ForgetPassword from './pages/auth/forgetPassword';
import NewCooperativaRubricaPage from './pages/rubricas/pages/new';
import NewCooperativaSubsidioPage from './pages/subsidios/pages/new';
import CooperativaRubricaDetailsPage from './pages/rubricas/pages/details';
import NewCooperativaEmpresaAnteriorPage from './pages/empresas/pages/new';
import CooperativaEmpresaDetailsPage from './pages/empresas/pages/details';
import CooperativaSubsidioDetailsPage from './pages/subsidios/pages/details';

const ProfilePage = lazy(() => import('./pages/profile'));

// Cooperativas
const CooperativasNewPage = lazy(() => import('./pages/cooperativas/new'));
const CooperativasListPage = lazy(() => import('./pages/cooperativas/list'));
const CooperativasDetailsPage = lazy(() => import('./pages/cooperativas/details'));

// Consejos
const CooperativasNewConsejoPage = lazy(() => import('./pages/consejos/pages/new'));
const CooperativaConsejoDetailsPage = lazy(() => import('./pages/consejos/pages/details'));

// Fiscalizaciones
const FiscalizacionesNewPage = lazy(() => import('./pages/fiscalizaciones/pages/new'));
const FiscalizacionesDetailsPage = lazy(() => import('./pages/fiscalizaciones/pages/details'));

// Asambleas
const AsambleasNewPage = lazy(() => import('./pages/asambleas/pages/new'));
const AsambleasDetailsPage = lazy(() => import('./pages/asambleas/pages/details'));
const AsambleasNewRecordPage = lazy(() => import('./pages/asambleas/pages/newRecord'));
const AsambleasRecordDetailsPage = lazy(() => import('./pages/asambleas/pages/recordDetails'));

// Facturación
const FacturacionNewPage = lazy(() => import('./pages/facturacion/pages/new'));
const FacturacionDetailsPage = lazy(() => import('./pages/facturacion/pages/details'));

// Expedientes
const ExpedientesNewPage = lazy(() => import('./pages/expedientes/pages/new'));
const ExpedientesDetailsPage = lazy(() => import('./pages/expedientes/pages/details'));

// Personas
const PersonasNewPage = lazy(() => import('./pages/personas/new'));
const PersonasListPage = lazy(() => import('./pages/personas/list'));
const PersonasDetailsPage = lazy(() => import('./pages/personas/details'));

// Referentes
const ReferentesListPage = lazy(() => import('./pages/referentes/pages/list'));

/** Rutas a las que se puede acceder sin necesidad de autenticarse. */
const publicRoutes = [
  { path: '*', element: <NotFoundPage /> },
  { path: '/500', element: <Error500Page /> },
  { path: '/403', element: <NotAllowedPage /> },
  { path: ROUTES.LOGIN_ROUTE, element: <LoginPage /> },
  { path: ROUTES.RESET_PASSWORD_ROUTE, element: <ResetPassword /> },
  { path: ROUTES.FORGOT_PASSWORD_ROUTE, element: <ForgetPassword /> },
  { path: ROUTES.HOMEPAGE_ROUTE, element: <Navigate to={ROUTES.COOPERATIVAS_LIST} replace /> }
];

/** Rutas que requieren autenticación. */
const privateRoutes = [
  /* ---------------------------------------
            RUTAS DEL PERFIL
 ---------------------------------------- */
  { path: ROUTES.PROFILE_ROUTE, element: <ProfilePage />, isAllowed: (user: IUser) => !!user },

  /* ---------------------------------------
            RUTAS DE COOPERATIVAS
 ---------------------------------------- */
  {
    path: ROUTES.COOPERATIVAS_LIST,
    element: <CooperativasListPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('browse_cooperativas')
  },
  {
    path: ROUTES.COOPERATIVAS_NEW,
    element: <CooperativasNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_cooperativas')
  },
  {
    path: ROUTES.COOPERATIVAS_DETAILS,
    element: <CooperativasDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_cooperativas')
  },

  /* ---------------------------------------
            RUTAS DE CONSEJOS
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_CONSEJO,
    element: <CooperativasNewConsejoPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_cooperativa_consejos')
  },
  {
    path: ROUTES.COOPERATIVAS_CONSEJO_DETAILS,
    element: <CooperativaConsejoDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_cooperativa_consejos')
  },

  /* ---------------------------------------
            RUTAS DE FISCALIZACIONES
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_FISCALIZACION,
    element: <FiscalizacionesNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_fiscalizaciones')
  },
  {
    path: ROUTES.COOPERATIVAS_FISCALIZACION_DETAILS,
    element: <FiscalizacionesDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_fiscalizaciones')
  },

  /* ---------------------------------------
            RUTAS DE Expedientes
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_EXPEDIENTE,
    element: <ExpedientesNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_expedientes')
  },
  {
    path: ROUTES.COOPERATIVAS_EXPEDIENTE_DETAILS,
    element: <ExpedientesDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_expedientes')
  },

  /* ---------------------------------------
            RUTAS DE Subsidios
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_SUBSIDIO,
    element: <NewCooperativaSubsidioPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_subsidios')
  },
  {
    path: ROUTES.COOPERATIVAS_SUBSIDIO_DETAILS,
    element: <CooperativaSubsidioDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_subsidios')
  },

  /* ---------------------------------------
            RUTAS DE RUBRICAS
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_RUBRICA,
    element: <NewCooperativaRubricaPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_rubricas')
  },
  {
    path: ROUTES.COOPERATIVAS_RUBRICA_DETAILS,
    element: <CooperativaRubricaDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_subsidios')
  },

  /* ---------------------------------------
            RUTAS DE EMPRESAS ANTERIORES
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_EMPRESA_ANTERIOR,
    element: <NewCooperativaEmpresaAnteriorPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_empresas_anteriores')
  },
  {
    path: ROUTES.COOPERATIVAS_EMPRESA_ANTERIOR_DETAILS,
    element: <CooperativaEmpresaDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_empresas_anteriores')
  },

  /* ---------------------------------------
            RUTAS DE ASAMBLEAS
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_ASAMBLEA,
    element: <AsambleasNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_asambleas')
  },
  {
    path: ROUTES.COOPERATIVAS_ASAMBLEA_DETAILS,
    element: <AsambleasDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_asambleas')
  },
  {
    path: ROUTES.COOPERATIVAS_ASAMBLEA_NEW_RECORD,
    element: <AsambleasNewRecordPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_datos_asamblea')
  },
  {
    path: ROUTES.COOPERATIVAS_ASAMBLEA_RECORD_DETAILS,
    element: <AsambleasRecordDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_datos_asamblea')
  },

  /* ---------------------------------------
            RUTAS DE FACTURACIÓN
 ---------------------------------------- */

  {
    path: ROUTES.COOPERATIVAS_NEW_FACTURACION,
    element: <FacturacionNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_facturaciones')
  },

  {
    path: ROUTES.COOPERATIVAS_FACTURACION_DETAILS,
    element: <FacturacionDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_facturaciones')
  },

  /* ---------------------------------------
            RUTAS DE PERSONAS
 ---------------------------------------- */
  {
    path: ROUTES.PERSONAS_LIST,
    element: <PersonasListPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('browse_personas')
  },
  {
    path: ROUTES.PERSONAS_NEW,
    element: <PersonasNewPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('add_personas')
  },
  {
    path: ROUTES.PERSONAS_DETAILS,
    element: <PersonasDetailsPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('read_personas')
  },

  /* ---------------------------------------
            RUTAS DE REFERENTES
 ---------------------------------------- */
  {
    path: ROUTES.REFERENTES_LIST,
    element: <ReferentesListPage />,
    isAllowed: (user: IUser) => !!user && user.permissions.includes('browse_referentes')
  }
];

export const history = createBrowserHistory();

const RootComponent: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    // @ts-ignore
    <Router history={history}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute isAllowed={Boolean(user)}>
                <Suspense fallback={<PageLoading />}>{route.element}</Suspense>
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
    </Router>
  );
};

export default RootComponent;
