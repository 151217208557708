import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as TiposContactosService from '../../services/tiposContactos.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposContactosService.getTiposContactos>>[number];

export const fetchTiposContactos = createAsyncThunk<Entity[]>(
  'tiposContactos/fetch',
  (_params, api) => {
    const { tiposContactos } = api.getState() as RootState;
    if (tiposContactos.entities.length) return Promise.resolve(tiposContactos.entities);
    return TiposContactosService.getTiposContactos();
  }
);

interface TipoContactosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TipoContactosState = { loading: false, entities: [] };

const tiposContactosSlice = createSlice({
  name: 'tiposContactos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposContactos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposContactos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposContactos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposContactosActions = { ...tiposContactosSlice.actions, fetchTiposContactos };

export default tiposContactosSlice.reducer;
