import axios from '../config/axios';

import type { EstadoCivil, EstadosCivilesQueryParams } from '../interfaces/estadoCivil';

const PATH = `estados_civiles`;

/**
 * @description Recupera desde la API los estados civiles
 * @returns {Promise<EstadoCivil[]>} Estados civiles
 */
export const getEstadosCiviles = async (params: EstadosCivilesQueryParams = {}) => {
  return await axios.get<{ data: EstadoCivil[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
