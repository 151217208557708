import axios from '../config/axios';

import type { Programa, ProgramaQueryParams } from '../interfaces/programa';

const PATH = `programas`;

/**
 * @description Recupera desde la API los programas de cooperativas
 * @returns {Promise<Programa[]>} Programas
 */
export const getProgramas = async (params: ProgramaQueryParams = {}) => {
  return await axios.get<{ data: Programa[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
