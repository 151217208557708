import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../layout';

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children: any;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = '/ingresar',
  children
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <Layout>{children}</Layout> : <Outlet />;
};
