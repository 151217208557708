import { memo, useEffect } from 'react';

// Components
import { EmpresaDetailsContainer } from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { fetchEmpresa } from '../../../../store/slices/empresaDetalle';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchActividadesAfip } from '../../../../store/slices/actividadesAfip';

const LOADING_SLICES = ['empresaDetalle'] as const;

export const CooperativaEmpresaDetailsPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string; id_empresa_anterior: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id && params.id_empresa_anterior) {
      dispatch(fetchEmpresa(params.id_empresa_anterior));
    }
  }, [dispatch, params.id_empresa_anterior, params.id]);

  useEffect(() => {
    dispatch(fetchActividadesAfip());
  }, [dispatch]);

  return <EmpresaDetailsContainer loading={loading} />;
});

CooperativaEmpresaDetailsPage.displayName = 'CooperativaEmpresaDetailsPage';

export default CooperativaEmpresaDetailsPage;
