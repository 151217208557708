import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as PersonasService from '../../services/personas.service';

type Entity = Awaited<ReturnType<typeof PersonasService.getPersona>>;

export const fetchPersona = createAsyncThunk<Entity, number | string>(
  'personaDetalle/fetch',
  (id) => {
    return PersonasService.getPersona(id);
  }
);

export const fetchAsyncPersona = createAsyncThunk<Entity, number | string>(
  'personaDetalle/fetchAsync',
  (id) => {
    return PersonasService.getPersona(id);
  }
);

interface PersonaDetalleState {
  loading: boolean;
  persona: Entity | null;
}

const initialState: PersonaDetalleState = { loading: false, persona: null };

const PersonaDetalleSlice = createSlice({
  name: 'persona_detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPersona.fulfilled, (state, action) => {
      state.persona = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPersona.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPersona.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncPersona.fulfilled, (state, action) => {
      state.persona = action.payload;
      state.loading = false;
    });
  }
});

export const CooperativaDetalleActions = { fetchPersona, ...PersonaDetalleSlice.actions };

export default PersonaDetalleSlice.reducer;
