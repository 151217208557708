import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as TiposComisionesService from '../../services/tiposComisiones.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposComisionesService.getTiposComisiones>>[number];

export const fetchTiposComisiones = createAsyncThunk<Entity[]>(
  'tiposComisiones/fetch',
  (_params, api) => {
    const { tiposComisiones } = api.getState() as RootState;
    if (tiposComisiones.entities.length) return Promise.resolve(tiposComisiones.entities);
    return TiposComisionesService.getTiposComisiones();
  }
);

interface TipoComisionesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TipoComisionesState = { loading: false, entities: [] };

const tiposComisionesSlice = createSlice({
  name: 'tiposComisiones',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposComisiones.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposComisiones.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposComisiones.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposComisionesActions = { ...tiposComisionesSlice.actions, fetchTiposComisiones };

export default tiposComisionesSlice.reducer;
