import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Modal, message as Message, notification as Notification, Dropdown, Button } from 'antd';

// Redux
import { useAppSelector } from '../../../../../../store/store';

// Services
import { deleteEmpresaAnterior } from '../../../../../../services/empresaAnterior.service';

// Constants
import { ROUTES } from '../../../../../../constants/routes-constants';

// Interfaces
import type { MenuProps } from 'antd';

export const EmpresaActionsMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user!);
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);
  const cooperativa = empresa?.cooperativa;

  const items: MenuProps['items'] = [];
  const [modal, modalContext] = Modal.useModal();
  const [notification, notificationContext] = Notification.useNotification();

  if (!empresa) return null;

  if (user.permissions.includes('delete_empresas_anteriores')) {
    items.push({
      key: '1',
      icon: <DeleteOutlined />,
      label: 'Eliminar empresa',
      onClick: () =>
        modal.confirm({
          width: 600,
          centered: true,
          title: '¿Está seguro que desea eliminar esta empresa?',
          content: 'Esta acción no se puede deshacer.',
          okText: 'Sí, eliminar',
          okType: 'danger',
          okButtonProps: { type: 'primary' },
          cancelText: 'Cancelar',
          onOk: async () => {
            return deleteEmpresaAnterior(empresa.id)
              .then(() => {
                Message.success('Empresa eliminada con éxito.');
                navigate(
                  ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa.id}`) +
                    '?seccion=empresas'
                );
              })
              .catch((error) => {
                notification.error({
                  message: 'Error',
                  placement: 'bottomRight',
                  description:
                    error?.response?.data?.message ||
                    'Ocurrió un error inesperado al intentar eliminar la empresa.'
                });
              });
          }
        })
    });
  }

  return items.length ? (
    <>
      {modalContext}
      {notificationContext}
      <Dropdown menu={{ items }} placement="bottomLeft">
        <Button icon={<DownOutlined />} shape="round">
          Acciones
        </Button>
      </Dropdown>
    </>
  ) : null;
};
