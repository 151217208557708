import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as RubricasService from '../../services/rubricas.service';

type Entity = Awaited<ReturnType<typeof RubricasService.getRubrica>>;

export const fetchRubrica = createAsyncThunk<Entity, number | string>(
  'rubricaDetalle/fetch',
  (id) => {
    return RubricasService.getRubrica(id);
  }
);

export const fetchAsyncRubrica = createAsyncThunk<Entity, number | string>(
  'rubricaDetalle/fetchAsync',
  (id) => {
    return RubricasService.getRubrica(id);
  }
);

interface RubricaDetalleState {
  loading: boolean;
  rubrica: Entity | null;
}

const initialState: RubricaDetalleState = { loading: false, rubrica: null };

const RubricaDetalleSlice = createSlice({
  name: 'rubricaDetalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRubrica.fulfilled, (state, action) => {
      state.rubrica = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchRubrica.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchRubrica.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncRubrica.fulfilled, (state, action) => {
      state.rubrica = action.payload;
      state.loading = false;
    });
  }
});

export const rubricaDetalleActions = {
  fetchRubrica,
  ...RubricaDetalleSlice.actions
};

export default RubricaDetalleSlice.reducer;
