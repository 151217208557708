/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable one-var */
/* eslint-disable no-empty-pattern */

// React
import { useMemo, useCallback, memo } from 'react';

// Components
import { Avatar, Space } from 'antd';
import HeaderDropdown from '../HeaderDropdown';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

// Interfaces
import type { FC } from 'react';
import type { MenuProps } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { authActions } from '../../../../store/slices/auth';
import { useNavigate } from 'react-router-dom';

export interface GlobalHeaderRightProps {
  menu?: boolean;
  collapsed?: boolean;
}

const AvatarDropdown: FC<GlobalHeaderRightProps> = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((store) => store.auth.user);

  const menuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'user',
        label: <span>{user?.name}</span>
      },
      {
        type: 'divider'
      },
      { label: 'Cerrar sesión', key: 'logout', icon: <LogoutOutlined /> } // which is required
    ],
    [user]
  );

  const onClick: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      if (e.key === 'user') {
        navigate('/perfil');
      }
      if (e.key === 'logout') {
        dispatch(authActions.removeUser());
      }
    },
    [dispatch, navigate]
  );

  return (
    <div style={{ textAlign: 'center', cursor: 'pointer' }}>
      <HeaderDropdown
        menu={{
          onClick,
          items: menuItems,
          selectedKeys: []
        }}
        placement="topLeft"
      >
        <span>
          <Space>
            <Avatar
              key="avatar"
              size="small"
              alt="avatar"
              icon={<UserOutlined />}
              style={{ marginBottom: 10 }}
            />
          </Space>
        </span>
      </HeaderDropdown>
    </div>
  );
});

AvatarDropdown.displayName = 'AvatarDropdown';

export default AvatarDropdown;
