import { memo, useRef, useState } from 'react';

// Components
import { Button, Empty, Modal, Space, Tooltip } from 'antd';
import { ProTable } from '../../../../../../components/table';
import { notification as Notification, message as Messsage } from 'antd';

// Utils
import dayjs from 'dayjs';
import ProCard from '@ant-design/pro-card';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../../store/store';

// Services
import {
  deleteLeyDeApropiacion,
  getLeyesDeApropiacion
} from '../../../../../../services/empresaAnterior.service';

// Interfaces
import type {
  LeyApropiacion,
  LeyApropiacionQueryParams
} from '../../../../../../interfaces/leyApropiacion';
import type { ActionType } from '@ant-design/pro-table';
import { AddLawRecord } from './addLaw';

export const EmpresaDetailsLaws = memo(() => {
  const [modal, modalContext] = Modal.useModal();
  const [message, messageContext] = Messsage.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const user = useAppSelector((state) => state.auth.user!);
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);

  const [selectedRow, setSelectedRow] = useState<LeyApropiacion | undefined>(undefined);

  const actionRef = useRef<ActionType>();

  const onRefresh = () => {
    actionRef.current?.reload();
    setSelectedRow(undefined);
  };

  return (
    <>
      {modalContext}
      {messageContext}
      {notificationContext}

      <AddLawRecord
        isEdit
        ley={selectedRow}
        onRefresh={onRefresh}
        onClose={() => {
          setSelectedRow(undefined);
        }}
      />

      <ProTable<LeyApropiacion>
        size="small"
        revalidateOnFocus
        actionRef={actionRef}
        search={{ span: 8 }}
        toolBarRender={() => [
          user && user.permissions.includes('add_leyes_expropiacion') ? (
            <AddLawRecord key="add" onRefresh={onRefresh} />
          ) : undefined
        ]}
        headerTitle={'Leyes de expropiación asociadas a la empresa'}
        rowKey={(record) => record.id}
        pagination={{
          pageSize: 50,
          hideOnSinglePage: true,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} de ${total} actividades`;
          }
        }}
        request={(params, sort) => {
          const { pageSize: perPage, current: page } = params;

          const queryParams: LeyApropiacionQueryParams = {
            page,
            perPage,
            empresas_anteriores_id: empresa.id
          };

          if (Object.keys(sort).length) {
            const key = Object.keys(sort)[0];
            queryParams.orderBy = key as LeyApropiacionQueryParams['orderBy'];
            queryParams.orderCriteria = sort[key] === 'ascend' ? 'ASC' : 'DESC';
          }

          return getLeyesDeApropiacion(queryParams).then((response) => {
            return {
              success: true,
              data: response.data,
              total: response.meta.total
            };
          });
        }}
        locale={{
          emptyText: (
            <Empty description="No hay leyes de expropiación registradas para la empresa" />
          )
        }}
        expandable={{
          rowExpandable(record) {
            return !!record.descripcion;
          },
          expandedRowRender(record) {
            return (
              <ProCard style={{ margin: 0 }}>
                <p style={{ margin: 0 }}>{record.descripcion}</p>
              </ProCard>
            );
          }
        }}
        columns={[
          {
            title: 'Registro',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            hideInSearch: true,
            render: (_, entity) => entity.id
          },
          {
            sorter: true,
            title: 'Fecha de sanción',
            dataIndex: 'fecha_sancion',
            valueType: 'dateRange',
            fieldProps: {
              format: 'DD/MM/YYYY',
              placeholder: ['Desde', 'Hasta'],
              disabledDate(current: dayjs.Dayjs) {
                return current && current > dayjs().endOf('day');
              }
            },
            render(dom, entity) {
              return dayjs(entity.fecha_sancion).format('DD/MM/YYYY');
            }
          },
          {
            sorter: true,
            title: 'Fecha de promulgación',
            dataIndex: 'fecha_promulgacion',
            valueType: 'dateRange',
            fieldProps: {
              format: 'DD/MM/YYYY',
              placeholder: ['Desde', 'Hasta'],
              disabledDate(current: dayjs.Dayjs) {
                return current && current > dayjs().endOf('day');
              }
            },
            render(dom, entity) {
              return dayjs(entity.fecha_promulgacion).format('DD/MM/YYYY');
            }
          },
          {
            sorter: true,
            title: 'Fecha de publicación en el boletín oficial',
            dataIndex: 'fecha_publicacion_bo',
            valueType: 'dateRange',
            fieldProps: {
              format: 'DD/MM/YYYY',
              placeholder: ['Desde', 'Hasta'],
              disabledDate(current: dayjs.Dayjs) {
                return current && current > dayjs().endOf('day');
              }
            },
            render(dom, entity) {
              return dayjs(entity.fecha_publicacion_bo).format('DD/MM/YYYY');
            }
          },
          {
            sorter: true,
            title: 'Fecha de vencimiento',
            dataIndex: 'fecha_vencimiento',
            valueType: 'dateRange',
            fieldProps: {
              format: 'DD/MM/YYYY',
              placeholder: ['Desde', 'Hasta']
            },
            render(dom, entity) {
              return dayjs(entity.fecha_vencimiento).format('DD/MM/YYYY');
            }
          },
          {
            align: 'right',
            sorter: false,
            title: 'Acciones',
            fixed: 'right',
            hideInSetting: true,
            key: 'options',
            valueType: 'option',
            fieldProps: {
              format: 'DD/MM/YYYY',
              placeholder: ['Desde', 'Hasta']
            },
            render(dom, entity) {
              return (
                <Space>
                  {user && user.permissions.includes('edit_leyes_expropiacion') ? (
                    <Tooltip title="Editar">
                      <Button
                        type="primary"
                        shape="round"
                        size="small"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setSelectedRow(entity);
                        }}
                      ></Button>
                    </Tooltip>
                  ) : null}
                  {user && user.permissions.includes('delete_leyes_expropiacion') ? (
                    <Tooltip title="Eliminar">
                      <Button
                        type="primary"
                        danger
                        shape="round"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          modal.confirm({
                            centered: true,
                            width: 600,
                            okButtonProps: {
                              danger: true
                            },
                            title: '¿Está seguro de eliminar esta ley de expropiación?',
                            content: 'Esta acción no se puede deshacer.',
                            onOk() {
                              return deleteLeyDeApropiacion(entity.id)
                                .then(() => {
                                  actionRef.current?.reload();
                                  message.success(
                                    'La ley de expropiación se eliminó correctamente.'
                                  );
                                })
                                .catch(() => {
                                  notification.error({
                                    message: 'Error',
                                    description:
                                      'Ocurrió un error inesperado al eliminar la ley de expropiación.'
                                  });
                                });
                            }
                          });
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </Space>
              );
            }
          }
        ]}
      />
    </>
  );
});

EmpresaDetailsLaws.displayName = 'EmpresaDetailsLaws';
