import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ActividadesAfipService from '../../services/actividadesAfip.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof ActividadesAfipService.getActividadesAfip>>[number];

export const fetchActividadesAfip = createAsyncThunk<Entity[]>(
  'actividadesAfip/fetch',
  (_params, api) => {
    const { actividadesAfip } = api.getState() as RootState;
    if (actividadesAfip.entities.length) return Promise.resolve(actividadesAfip.entities);
    return ActividadesAfipService.getActividadesAfip();
  }
);

interface ActividadesAfipState {
  loading: boolean;
  entities: Entity[];
}

const initialState: ActividadesAfipState = { loading: false, entities: [] };

const actividadesAfipSlice = createSlice({
  name: 'actividades_afip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActividadesAfip.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchActividadesAfip.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchActividadesAfip.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const actividadesAfipActions = { ...actividadesAfipSlice.actions, fetchActividadesAfip };

export default actividadesAfipSlice.reducer;
