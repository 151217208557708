import axios from '../config/axios';

import type { RubricaLibro, RubricaLibrosQueryParams } from '../interfaces/rubricaLibro';

const PATH = `rubrica_libros`;

/**
 * @description Recupera desde la API las rubricas de libro
 * @returns {Promise<RubricaLibro[]>} Rubricas de libros
 */
export const getRubricasLibros = async (params: RubricaLibrosQueryParams = {}) => {
  return await axios.get<{ data: RubricaLibro[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
