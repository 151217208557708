import React, { useCallback } from 'react';

// Components
import { Button, Result } from 'antd';
import Layout from '../../components/layout';
import { PageContainer } from '@ant-design/pro-layout';

// Navigation
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes-constants';

// Redux
import { useAppSelector } from '../../store/store';

// Interfaces
import type { ResultProps } from 'antd';

type StatusComponentProps = Pick<ResultProps, 'status' | 'title' | 'subTitle'> & {
  headerTitle?: string;
};

const StatusComponent: React.FC<StatusComponentProps> = (props) => {
  const { title, status, subTitle, headerTitle } = props;

  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const redirectToHomePage = useCallback(() => navigate(ROUTES.HOMEPAGE_ROUTE), [navigate]);

  const page = (
    <PageContainer title={headerTitle} pageHeaderRender={!headerTitle ? () => null : undefined}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: user ? 'calc(100vh - 180px)' : 'calc(100vh - 100px)'
        }}
      >
        <Result
          title={title}
          status={status}
          subTitle={subTitle}
          extra={
            <Button onClick={redirectToHomePage} type="primary">
              Volver al inicio
            </Button>
          }
        />
      </div>
    </PageContainer>
  );

  return user && user.id ? <Layout>{page}</Layout> : page;
};

export default StatusComponent;
