import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ConsejosService from '../../services/consejos.service';

type Entity = Awaited<ReturnType<typeof ConsejosService.getConsejo>>;

export const fetchConsejo = createAsyncThunk<
  Entity,
  { cooperativa_id: number | string; consejo_id: number | string }
>('consejoDetalle/fetch', (params) => {
  return ConsejosService.getConsejo(params.cooperativa_id, params.consejo_id);
});

export const fetchAsyncConsejo = createAsyncThunk<
  Entity,
  { cooperativa_id: number | string; consejo_id: number | string }
>('consejoDetalle/fetchAsync', (params) => {
  return ConsejosService.getConsejo(params.cooperativa_id, params.consejo_id);
});

interface ConsejoDetalleState {
  loading: boolean;
  consejo: Entity | null;
}

const initialState: ConsejoDetalleState = { loading: false, consejo: null };

const ConsejoDetalleSlice = createSlice({
  name: 'consejo_detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchConsejo.fulfilled, (state, action) => {
      state.consejo = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchConsejo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchConsejo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncConsejo.fulfilled, (state, action) => {
      state.consejo = action.payload;
      state.loading = false;
    });
  }
});

export const consejoDetalleActions = { fetchConsejo, ...ConsejoDetalleSlice.actions };

export default ConsejoDetalleSlice.reducer;
