// Components
import { EditOutlined } from '@ant-design/icons';
import CustomFromDrawer from '../../../components/drawer/form';
import {
  ProFormDatePicker,
  ProFormDependency,
  ProFormSelect,
  ProFormText
} from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { updateEmpresaAnterior } from '../../../services/empresaAnterior.service';

// Interfaces
import { memo, type FC } from 'react';
import type {
  EmpresaAnterior,
  EmpresaAnteriorNewFormValues
} from '../../../interfaces/empresaAnterior';

import { DatePickerDefaultProps } from '../../../constants/form';
import dayjs from 'dayjs';

interface EditEmpresaPRAERProps {
  empresa: EmpresaAnterior;
  onRefresh?: () => void;
}

export const EditEmpresaPRAER: FC<EditEmpresaPRAERProps> = memo((props) => {
  const { empresa } = props;
  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  return (
    <CustomFromDrawer<EmpresaAnteriorNewFormValues>
      maxWidth={700}
      title="Editar datos"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        ...empresa,
        fecha_nro_disposicion_praer: empresa.fecha_nro_disposicion_praer
          ? dayjs(empresa.fecha_nro_disposicion_praer)
          : undefined
      }}
      // @ts-ignore
      onValuesChange={(changedValues, values, form) => {
        if (!values.solicitud_inscripcion_praer) {
          form?.current?.setFieldsValue({
            nro_registro_praer: undefined,
            nro_disposicion_praer: undefined,
            fecha_nro_disposicion_praer: undefined
          });
        }
      }}
      onFinish={async (values) => {
        return updateEmpresaAnterior(empresa.id, {
          ...values,
          nro_registro_praer: values.nro_registro_praer || '',
          nro_disposicion_praer: values.nro_disposicion_praer || '',
          fecha_nro_disposicion_praer: values.fecha_nro_disposicion_praer
            ? dayjs(values.fecha_nro_disposicion_praer).format('YYYY-MM-DD')
            : null
        })
          .then(() => {
            message.success('Empresa actualizada correctamente');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos de la empresa, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos de la empresa'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormSelect
            name="solicitud_inscripcion_praer"
            placeholder="Seleccione una opción"
            label="Tiene solicitud de inscripción en PRAER"
            options={[
              { label: 'Sí', value: 1 },
              { label: 'No', value: 0 }
            ]}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <ProFormDependency name={['solicitud_inscripcion_praer']}>
          {({ solicitud_inscripcion_praer }) => (
            <>
              <Col span={24}>
                <ProFormText
                  name="nro_registro_praer"
                  label="Número de registro PRAER"
                  disabled={!solicitud_inscripcion_praer}
                  placeholder="Ingrese el número de registro PRAER"
                  rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
                />
              </Col>

              <Col span={24}>
                <ProFormText
                  name="nro_disposicion_praer"
                  label="Número de disposición PRAER"
                  disabled={!solicitud_inscripcion_praer}
                  placeholder="Ingrese el número de disposición PRAER"
                  rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
                />
              </Col>

              <Col span={24}>
                <ProFormDatePicker
                  name="fecha_nro_disposicion_praer"
                  label="Fecha de disposición PRAER"
                  disabled={!solicitud_inscripcion_praer}
                  placeholder="Seleccione la fecha de disposición PRAER"
                  fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
                />
              </Col>
            </>
          )}
        </ProFormDependency>
      </Row>
    </CustomFromDrawer>
  );
});

EditEmpresaPRAER.displayName = 'EditEmpresaPRAER';
