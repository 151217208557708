import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/api';
import type { Direccion, DireccionNewForm } from '../interfaces/personas';
import type { ContactoPersona, ContactoNewForm } from '../interfaces/personas';
import type { Persona, PersonaNewForm, PersonaQueryParams } from '../interfaces/personas';

/**************************************************
 *
 *            Personas
 *
 *************************************************/

const PERSONAS_PATH = 'personas';

/**
 * @description Recupera desde la API las personas registradas en la base de datos
 * @returns {Promise<Persona[]>} Personas
 */

export const getPersonas = async (params: PersonaQueryParams = {}) => {
  return await axios.get<PaginatedResponse<Persona>>(PERSONAS_PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Registra en la API una nueva persona
 * @returns {Promise<Persona>} Persona
 */
export const newPersona = async (data: PersonaNewForm) => {
  return await axios.post<{ data: Persona }>(PERSONAS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Recupera desde la API una persona
 * @returns {Promise<Persona>} Persona
 */
export const getPersona = async (id: number | string) => {
  return await axios.get<{ data: Persona }>(`${PERSONAS_PATH}/${id}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza en la API una persona
 * @returns {Promise<Persona>} Persona
 */
export const updatePersona = async (id: number | string, data: PersonaNewForm) => {
  return await axios.put<{ data: Persona }>(`${PERSONAS_PATH}/${id}`, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Elimina de la base de datos una persona
 * @returns {Promise<Persona>} Persona
 */
export const deletePersona = async (id: number | string) => {
  return await axios.delete<void>(`${PERSONAS_PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Hace un request a la api y genera un archivo con formato .XLSX que contiene el listado de personas.
 * @returns Un archivo con formato `.xlsx`.
 */
export const exportPersonas = async () => {
  return await axios.get(`exportar/personas`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Direcciones
 *
 *************************************************/

/**
 * @description Recupera desde la API las direcciones de una persona
 * @param {number | string} id Identificador de la persona
 * @returns {Promise<Direccion>} Direccion
 */
export const getPersonaDirecciones = async (id: number | string) => {
  return await axios
    .get<{ data: Direccion[] }>(`${PERSONAS_PATH}/${id}/direcciones`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API una nueva dirección para una persona
 * @param {number | string} id Identificador de la persona
 * @param {Direccion} data Datos de la dirección
 * @returns {Promise<Direccion>} Direccion
 */
export const newPersonaDireccion = async (id: number | string, data: DireccionNewForm) => {
  return await axios
    .post<{ data: Direccion }>(`${PERSONAS_PATH}/${id}/direcciones`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API una dirección de una persona
 * @param {number | string} id Identificador de la persona
 * @param {number | string} direccionId Identificador de la dirección
 * @returns {Promise<Direccion>} Direccion
 */
export const updatePersonaDireccion = async (
  id: number | string,
  direccionId: number | string,
  data: DireccionNewForm
) => {
  return await axios
    .put<{ data: Direccion }>(`${PERSONAS_PATH}/${id}/direcciones/${direccionId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina de la base de datos una dirección de una persona
 * @param {number | string} id Identificador de la persona
 * @param {number | string} direccionId Identificador de la dirección
 * @returns {Promise<Direccion>} Direccion
 */
export const deletePersonaDireccion = async (id: number | string, direccionId: number | string) => {
  return await axios
    .delete<{ data: Direccion }>(`${PERSONAS_PATH}/${id}/direcciones/${direccionId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**************************************************
 *
 *            Datos de contacto
 *
 *************************************************/

/**
 * @description Recupera desde la API los datos de contacto de una persona
 * @param {number | string} id Identificador de la persona
 * @returns {Promise<ContactoPersona>} ContactoPersona
 */
export const getPersonaDatosContacto = async (id: number | string) => {
  return await axios
    .get<{ data: ContactoPersona[] }>(`${PERSONAS_PATH}/${id}/contactos`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un nuevo dato de contacto para una persona
 * @param {number | string} id Identificador de la persona
 * @param {ContactoPersona} data Datos de contacto
 * @returns {Promise<ContactoPersona>} ContactoPersona
 */
export const newPersonaDatosContacto = async (id: number | string, data: ContactoNewForm) => {
  return await axios
    .post<{ data: ContactoPersona }>(`${PERSONAS_PATH}/${id}/contactos`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un dato de contacto de una persona
 * @param {number | string} id Identificador de la persona
 * @param {number | string} contactoId Identificador del dato de contacto
 * @returns {Promise<ContactoPersona>} ContactoPersona
 */
export const updatePersonaDatosContacto = async (
  id: number | string,
  contactoId: number | string,
  data: ContactoNewForm
) => {
  return await axios
    .put<{ data: ContactoPersona }>(`${PERSONAS_PATH}/${id}/contactos/${contactoId}`, data)
    .then((response) => {
      return response.data.data;
    });
};
