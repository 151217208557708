import axios from '../config/axios';

import type {
  TipoDatoAdicional,
  TipoDatoAdicionalQueryParams
} from '../interfaces/tipoDatoAdicional';

const PATH = `tipos_datos_adicionales`;

/**
 * @description Recupera desde la API los tipos de datos adicionales
 * @returns {Promise<TipoDatoAdicional[]>} Tipos de Datos Adicionales
 */
export const getTiposDatosAdicionales = async (params: TipoDatoAdicionalQueryParams = {}) => {
  return await axios.get<{ data: TipoDatoAdicional[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
