import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as TipoAsambleasService from '../../services/tiposAsambleas.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TipoAsambleasService.getTiposAsambleas>>[number];

export const fetchTiposAsambleas = createAsyncThunk<Entity[]>(
  'tiposAsambleas/fetch',
  (_params, api) => {
    const { tiposAsambleas } = api.getState() as RootState;
    if (tiposAsambleas.entities.length) return Promise.resolve(tiposAsambleas.entities);
    return TipoAsambleasService.getTiposAsambleas();
  }
);

interface TiposAsambleasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposAsambleasState = { loading: false, entities: [] };

const tiposAsambleasSlice = createSlice({
  name: 'tiposAsambleas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposAsambleas.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposAsambleas.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposAsambleas.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposAsambleasActions = { ...tiposAsambleasSlice.actions, fetchTiposAsambleas };

export default tiposAsambleasSlice.reducer;
