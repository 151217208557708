/* eslint-disable @typescript-eslint/no-empty-interface */
import { FC, memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

// Redux

// Services
import ProCard from '@ant-design/pro-card';
import { useAppSelector } from '../../../../../../store/store';
import { EditSubsidioObservationsDetails } from '../../../../components/editSubsidioObservations';

interface SubsidioCommentsDetailsContainerProps {}

export const SubsidioCommentsDetailsContainer: FC<SubsidioCommentsDetailsContainerProps> = memo(
  () => {
    const user = useAppSelector((state) => state.auth.user!);
    const subsidio = useAppSelector((state) => state.subsidioDetalle.subsidio!);

    return (
      <ProCard
        collapsible
        title="Observaciones"
        extra={
          user && user.permissions.includes('edit_subsidios') ? (
            <EditSubsidioObservationsDetails />
          ) : null
        }
      >
        {ReactHtmlParser(subsidio?.observaciones || 'Sin especificar')}
      </ProCard>
    );
  }
);

SubsidioCommentsDetailsContainer.displayName = 'SubsidioCommentsDetailsContainer';
