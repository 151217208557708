import axios from '../config/axios';

import type {
  TipoDatoRequerido,
  TipoDatoRequeridoQueryParams
} from '../interfaces/tipoDatoRequerido';

const PATH = `tipos_datos_requeridos`;

/**
 * @description Recupera desde la API los tipos de datos requeridos. Se usan para los formularios de asambleas.
 * @returns {Promise<TipoDatoRequerido[]>} Tipos de datos requeridos
 */
export const getTiposDatosRequeridos = async (params: TipoDatoRequeridoQueryParams = {}) => {
  return await axios.get<{ data: TipoDatoRequerido[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
