import React from 'react';

// Theme
import { ConfigProvider } from 'antd';
import { PRIMARY_COLOR } from './constants/colors';

// Components
import RootComponent from './RootComponent';

// Redux
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';

// Locale
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import es from 'antd/locale/es_ES';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'numeral/locales/es';
import numeral from 'numeral';

dayjs.locale('es');
numeral.locale('es');

dayjs.extend(customParseFormat);

const theme = {
  token: {
    fontFamily: 'DINR',
    colorPrimary: PRIMARY_COLOR
  }
} as const;

const App: React.FC = () => {
  return (
    <ConfigProvider locale={es} theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootComponent />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
