import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ProgramasService from '../../services/programas.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof ProgramasService.getProgramas>>[number];

export const fetchProgramas = createAsyncThunk<Entity[]>('programas/fetch', (_params, api) => {
  const { programas } = api.getState() as RootState;
  if (programas.entities.length) return Promise.resolve(programas.entities);
  return ProgramasService.getProgramas();
});

interface ProgramasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: ProgramasState = { loading: false, entities: [] };

const programasSlice = createSlice({
  name: 'programas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProgramas.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchProgramas.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProgramas.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const programasActions = { ...programasSlice.actions, fetchProgramas };

export default programasSlice.reducer;
