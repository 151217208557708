import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/api';
import type {
  Fiscalizacion,
  FiscalizacionDetallada,
  FiscalizacionQueryParams,
  NewFiscalizacionFormValues,
  NewReferenteFiscalizacionFormValue
} from '../interfaces/fiscalizacion';

type ReferenteFiscalizacion = FiscalizacionDetallada['referentes'][number];

/**************************************************
 *
 *            Fiscalizaciones
 *
 *************************************************/

/**
 * @description Recupera desde la API los fiscalizaciones de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Fiscalizacion[]>} Fiscalizaciones
 */
export const getFiscalizaciones = async (id: number | string, params: FiscalizacionQueryParams) => {
  return await axios
    .get<PaginatedResponse<Fiscalizacion>>(`cooperativas/${id}/fiscalizaciones`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un fiscalizacion de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Fiscalizacion>} Fiscalizacion
 */
export const getFiscalizacion = async (cooperativa_id: number | string, id: number | string) => {
  return await axios
    .get<{ data: FiscalizacionDetallada }>(`cooperativas/${cooperativa_id}/fiscalizaciones/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un fiscalizacion de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Fiscalizacion>} Fiscalizacion
 */
export const newFiscalizacion = async (id: number | string, data: NewFiscalizacionFormValues) => {
  return await axios
    .post<{ data: Fiscalizacion }>(`cooperativas/${id}/fiscalizaciones`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un fiscalizacion de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Fiscalizacion>} Fiscalizacion
 */
export const updateFiscalizacion = async (
  cooperativa_id: number | string,
  id: number | string,
  data: NewFiscalizacionFormValues
) => {
  return await axios
    .put<{ data: Fiscalizacion }>(`cooperativas/${cooperativa_id}/fiscalizaciones/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un fiscalizacion de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Fiscalizacion>} Fiscalizacion
 */
export const deleteFiscalizacion = async (cooperativa_id: number | string, id: number | string) => {
  return await axios
    .delete<void>(`cooperativas/${cooperativa_id}/fiscalizaciones/${id}`)
    .then((response) => {
      return response.data;
    });
};

/**************************************************
 *
 *           Referentes
 *
 * ***********************************************/

/**
 * @description Recupera desde la API los referentes de una fiscalizacion
 * @param {number | string} id Identificador de la fiscalizacion
 * @returns {Promise<ReferenteFiscalizacion[]>} Referentes
 */
export const getReferentesFiscalizacion = async (id: number | string) => {
  return await axios
    .get<PaginatedResponse<ReferenteFiscalizacion>>(`fiscalizaciones/${id}/referentes`)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un referente de una fiscalizacion
 * @param {number | string} id Identificador de la fiscalizacion
 * @returns {Promise<ReferenteFiscalizacion>} Referente
 */
export const getReferenteFiscalizacion = async (
  fiscalizacion_id: number | string,
  id: number | string
) => {
  return await axios
    .get<{ data: ReferenteFiscalizacion }>(`fiscalizaciones/${fiscalizacion_id}/referentes/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un referente de una fiscalizacion
 * @param {number | string} id Identificador de la fiscalizacion
 * @returns {Promise<ReferenteFiscalizacion>} Referente
 */
export const newReferenteFiscalizacion = async (
  id: number | string,
  data: NewReferenteFiscalizacionFormValue
) => {
  return await axios
    .post<{ data: ReferenteFiscalizacion }>(`fiscalizaciones/${id}/referentes`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un referente de una fiscalizacion
 * @param {number | string} id Identificador de la fiscalizacion
 * @returns {Promise<ReferenteFiscalizacion>} Referente
 */
export const updateReferenteFiscalizacion = async (
  fiscalizacion_id: number | string,
  id: number | string,
  data: NewReferenteFiscalizacionFormValue
) => {
  return await axios
    .put<{ data: ReferenteFiscalizacion }>(
      `fiscalizaciones/${fiscalizacion_id}/referentes/${id}`,
      data
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un referente de una fiscalizacion
 * @param {number | string} id Identificador de la fiscalizacion
 * @returns {Promise<ReferenteFiscalizacion>} Referente
 */
export const deleteReferenteFiscalizacion = async (
  fiscalizacion_id: number | string,
  id: number | string
) => {
  return await axios
    .delete<void>(`fiscalizaciones/${fiscalizacion_id}/referentes/${id}`)
    .then((response) => {
      return response.data;
    });
};
