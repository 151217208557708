import axios from '../config/axios';

import type {
  DocumentacionAval,
  DocumentacionAvalQueryParams
} from '../interfaces/documentacionAval';

const PATH = `documentaciones_aval`;

/**
 * @description Recupera desde la API los partidos de la provincia de Buenos Aires
 * @returns {Promise<DocumentacionAval[]>} Partidos
 */
export const getDocumentacionesAval = async (params: DocumentacionAvalQueryParams = {}) => {
  return await axios.get<{ data: DocumentacionAval[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
