import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as FacturacionesService from '../../services/facturaciones.service';

type Entity = Awaited<ReturnType<typeof FacturacionesService.getFacturacion>>;

export const fetchFacturacion = createAsyncThunk<Entity, number | string>(
  'facturacionDetalle/fetch',
  (id) => {
    return FacturacionesService.getFacturacion(id);
  }
);

export const fetchAsyncFacturacion = createAsyncThunk<Entity, number | string>(
  'facturacionDetalle/fetchAsync',
  (id) => {
    return FacturacionesService.getFacturacion(id);
  }
);

interface FacturacionDetalleState {
  loading: boolean;
  facturacion: Entity | null;
}

const initialState: FacturacionDetalleState = { loading: false, facturacion: null };

const FacturacionDetalleSlice = createSlice({
  name: 'facturacion_detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacturacion.fulfilled, (state, action) => {
      state.facturacion = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchFacturacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchFacturacion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncFacturacion.fulfilled, (state, action) => {
      state.facturacion = action.payload;
      state.loading = false;
    });
  }
});

export const facturacionDetalleActions = {
  fetchFacturacion,
  ...FacturacionDetalleSlice.actions
};

export default FacturacionDetalleSlice.reducer;
