import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as TiposCargosService from '../../services/tiposCargos.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposCargosService.getTiposCargos>>[number];

export const fetchTiposCargos = createAsyncThunk<Entity[]>('tiposCargos/fetch', (_params, api) => {
  const { tiposCargos } = api.getState() as RootState;
  if (tiposCargos.entities.length) return Promise.resolve(tiposCargos.entities);
  return TiposCargosService.getTiposCargos();
});

interface TipoCargosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TipoCargosState = { loading: false, entities: [] };

const tiposCargosSlice = createSlice({
  name: 'tiposCargos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposCargos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposCargos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposCargos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposCargosActions = { ...tiposCargosSlice.actions, fetchTiposCargos };

export default tiposCargosSlice.reducer;
