import axios from '../config/axios';

import type { Sexo, SexoLibrosQueryParams } from '../interfaces/sexo';

const PATH = `sexos`;

/**
 * @description Recupera desde la API los sexos
 * @returns {Promise<Sexo[]>} Sexos
 */
export const getSexos = async (params: SexoLibrosQueryParams = {}) => {
  return await axios.get<{ data: Sexo[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
