import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as EmpresasService from '../../services/empresaAnterior.service';

type Entity = Awaited<ReturnType<typeof EmpresasService.getEmpresaAnterior>>;

export const fetchEmpresa = createAsyncThunk<Entity, number | string>(
  'empresaDetalle/fetch',
  (id) => {
    return EmpresasService.getEmpresaAnterior(id);
  }
);

export const fetchAsyncEmpresa = createAsyncThunk<Entity, number | string>(
  'empresaDetalle/fetchAsync',
  (id) => {
    return EmpresasService.getEmpresaAnterior(id);
  }
);

interface EmpresaDetalleState {
  loading: boolean;
  empresa: Entity | null;
}

const initialState: EmpresaDetalleState = { loading: false, empresa: null };

const EmpresaDetalleSlice = createSlice({
  name: 'empresaDetalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmpresa.fulfilled, (state, action) => {
      state.empresa = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEmpresa.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEmpresa.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncEmpresa.fulfilled, (state, action) => {
      state.empresa = action.payload;
      state.loading = false;
    });
  }
});

export const EmpresaDetalleActions = {
  fetchEmpresa,
  ...EmpresaDetalleSlice.actions
};

export default EmpresaDetalleSlice.reducer;
