import ProCard from '@ant-design/pro-card';
import { RouteContext } from '@ant-design/pro-layout';
import { Menu } from 'antd';
import { FC, memo } from 'react';

interface EmpresaMenuProps {
  tab: string;
  setTab: (tab: string) => void;
}

export const EmpresaMenu: FC<EmpresaMenuProps> = memo((props) => {
  return (
    <RouteContext.Consumer>
      {({ isMobile }) => (
        <ProCard title="Detalles" collapsible={isMobile} collapsed={isMobile ? undefined : false}>
          <Menu
            mode="vertical"
            items={[
              {
                key: '1',
                label: 'Datos generales'
              },
              {
                key: '2',
                label: 'Actividades'
              },
              {
                key: '3',
                label: 'Leyes de expropiación'
              }
            ]}
            selectedKeys={[props.tab]}
            onClick={(a) => {
              props.setTab(a.key);
            }}
          />
        </ProCard>
      )}
    </RouteContext.Consumer>
  );
});

EmpresaMenu.displayName = 'EmpresaMenu';
