import axios from '../config/axios';

import type {
  EmpresaAnterior,
  EmpresaAnteriorDetallada,
  EmpresaAnteriorQueryParams,
  EmpresaAnteriorNewFormValues,
  EmpresaAnteriorActividadQueryParams,
  EmpresaAnteriorActividad,
  NewActividadAnteriorActividad
} from '../interfaces/empresaAnterior';

import type { PaginatedResponse } from '../interfaces/api';

import type {
  LeyApropiacion,
  LeyApropiacionQueryParams,
  NewLeyExpropiacionFormValues
} from '../interfaces/leyApropiacion';

/**************************************************
 *
 *            EmpresaAnterior
 *
 *************************************************/

const EMPRESAS_PATH = 'empresas-anteriores';

/**
 * @description Recupera desde la API las empresas anteriores de una cooperativa
 * @returns {Promise<PaginatedResponse<EmpresaAnterior>>} EmpresaAnterior
 */
export const getEmpresasAnteriores = async (params: EmpresaAnteriorQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<EmpresaAnterior>>(EMPRESAS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API una empresa anterior asociada a una cooperativa
 * @param {number | string} id Identificador de la empresa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const getEmpresaAnterior = async (id: number | string) => {
  return await axios
    .get<{ data: EmpresaAnteriorDetallada }>(`${EMPRESAS_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API una empresa anterior asociada a una cooperativa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const newEmpresaAnterior = async (data: EmpresaAnteriorNewFormValues) => {
  return await axios.post<{ data: EmpresaAnterior }>(EMPRESAS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza en la API una empresa de una cooperativa
 * @param {number | string} id Identificador de la empresa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const updateEmpresaAnterior = async (
  id: number | string,
  data: EmpresaAnteriorNewFormValues
) => {
  return await axios
    .put<{ data: EmpresaAnterior }>(`${EMPRESAS_PATH}/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API una empresa de una cooperativa
 * @param {number | string} id Identificador de la empresa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const deleteEmpresaAnterior = async (id: number | string) => {
  return await axios.delete<void>(`${EMPRESAS_PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Actividades
 *
 *************************************************/

const EMPRESAS_ACTIVIDADES_PATH = 'empresas-anteriores-actividades';

/**
 * @description Recupera desde la API las actividades de las empresas anteriores de una cooperativa
 * @returns {Promise<PaginatedResponse<EmpresaAnterior>>} EmpresaAnteriorctividad
 */
export const getEmpresasAnterioresActividades = async (
  params: EmpresaAnteriorActividadQueryParams = {}
) => {
  return await axios
    .get<PaginatedResponse<EmpresaAnteriorActividad>>(EMPRESAS_ACTIVIDADES_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API una actividad de una empresa anterior asociada a una cooperativa
 * @param {number | string} id Identificador de la empresa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const getEmpresaAnteriorActividad = async (id: number | string) => {
  return await axios
    .get<{ data: EmpresaAnteriorActividad }>(`${EMPRESAS_ACTIVIDADES_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API una actividad de empresa anterior asociada a una cooperativa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const newEmpresaAnteriorActividad = async (data: NewActividadAnteriorActividad) => {
  return await axios
    .post<{ data: EmpresaAnterior }>(EMPRESAS_ACTIVIDADES_PATH, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API una actividad de una empresa de una cooperativa
 * @param {number | string} id Identificador de la empresa
 * @returns {Promise<EmpresaAnterior>} EmpresaAnterior
 */
export const deleteEmpresaAnteriorActividad = async (id: number | string) => {
  return await axios.delete<void>(`${EMPRESAS_ACTIVIDADES_PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Actividades
 *
 *************************************************/

const LEYES_EXPROPIACION_PATH = 'leyes-expropiacion';

/**
 * @description Recupera desde la API las leyes de apropiación
 * @returns {Promise<PaginatedResponse<LeyApropiacion>>} EmpresaAnteriorctividad
 */
export const getLeyesDeApropiacion = async (params: LeyApropiacionQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<LeyApropiacion>>(LEYES_EXPROPIACION_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API una ley de apropiación
 * @param {number | string} id Identificador de la ley de apropiación
 * @returns {Promise<LeyApropiacion>} LeyApropiacion
 */
export const getLeyDeApropiacion = async (id: number | string) => {
  return await axios
    .get<{ data: LeyApropiacion }>(`${LEYES_EXPROPIACION_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API una ley de apropiación
 * @returns {Promise<LeyApropiacion>} LeyApropiacion
 */
export const newLeyDeApropiacion = async (data: NewLeyExpropiacionFormValues) => {
  return await axios
    .post<{ data: LeyApropiacion }>(LEYES_EXPROPIACION_PATH, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API una ley de apropiación
 * @param {number | string} id Identificador de la ley de apropiación
 * @returns {Promise<LeyApropiacion>} LeyApropiacion
 */
export const updateLeyDeApropiacion = async (
  id: number | string,
  data: Partial<NewLeyExpropiacionFormValues>
) => {
  return await axios
    .put<{ data: LeyApropiacion }>(`${LEYES_EXPROPIACION_PATH}/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API una ley de apropiación
 * @param {number | string} id Identificador de la ley de apropiación
 * @returns {Promise<LeyApropiacion>} LeyApropiacion
 */
export const deleteLeyDeApropiacion = async (id: number | string) => {
  return await axios.delete<void>(`${LEYES_EXPROPIACION_PATH}/${id}`).then((response) => {
    return response.data;
  });
};
