/* eslint-disable @typescript-eslint/no-empty-interface */
import { FC, memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

// Redux

// Services
import ProCard from '@ant-design/pro-card';
import { useAppSelector } from '../../../../../../store/store';
import { EditRubricaNotasDetails } from '../../../../components/editRubricaNotas';

interface RubricaNotasDetailsContainerProps {}

export const RubricaNotasDetailsContainer: FC<RubricaNotasDetailsContainerProps> = memo(() => {
  const user = useAppSelector((state) => state.auth.user!);
  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);

  return (
    <ProCard
      collapsible
      title="Notas"
      extra={
        user && user.permissions.includes('edit_rubricas') ? <EditRubricaNotasDetails /> : null
      }
    >
      {ReactHtmlParser(rubrica?.notas || 'Sin especificar')}
    </ProCard>
  );
});

RubricaNotasDetailsContainer.displayName = 'RubricaNotasDetailsContainer';
