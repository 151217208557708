/* eslint-disable @typescript-eslint/no-empty-interface */
import { FC, memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

// Redux

// Services
import ProCard from '@ant-design/pro-card';
import { useAppSelector } from '../../../../../../store/store';
import { EditRubricaObservationsDetails } from '../../../../components/editRubricaObservations';

interface RubricaCommentsDetailsContainerProps {}

export const RubricaCommentsDetailsContainer: FC<RubricaCommentsDetailsContainerProps> = memo(
  () => {
    const user = useAppSelector((state) => state.auth.user!);
    const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);

    return (
      <ProCard
        collapsible
        title="Observaciones"
        extra={
          user && user.permissions.includes('edit_rubricas') ? (
            <EditRubricaObservationsDetails />
          ) : null
        }
      >
        {ReactHtmlParser(rubrica?.observaciones || 'Sin especificar')}
      </ProCard>
    );
  }
);

RubricaCommentsDetailsContainer.displayName = 'RubricaCommentsDetailsContainer';
