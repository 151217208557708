/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Card, Col } from 'antd';
import ProCard from '@ant-design/pro-card';
import { SolutionOutlined } from '@ant-design/icons';
import { Quill } from '../../../../../components/form/Quill';
import { ProFormDigit, ProFormText } from '@ant-design/pro-form';
import ProForm, { ProFormDatePicker } from '@ant-design/pro-form';
import { ProFormItem, ProFormSelect } from '@ant-design/pro-form';
import { Modal, Row, Space, notification as Notification } from 'antd';

// Utils
import dayjs from 'dayjs';
import {
  setLocalStorageWithExpiry,
  getFromLocalStorageWithExpiry
} from '../../../../../utils/localstorage';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';
import { DatePickerDefaultProps } from '../../../../../constants/form';

// Services
import { newSubsidio } from '../../../../../services/subsidios.service';

// Interfaces
import type { ProFormInstance } from '@ant-design/pro-form';
import type { NewSubsidioFormValues } from '../../../../../interfaces/subsidio';
import ProFormDatePickerYear from '@ant-design/pro-form/es/components/DatePicker/YearPicker';

export const NewSubsidioForm = memo(() => {
  const form = useRef<ProFormInstance>();
  const estados = useAppSelector((state) => state.estadosSubsidios.entities);
  const cooperativa = useAppSelector((state) => state.cooperativaDetalle.cooperativa!);

  const [modal, modalContext] = Modal.useModal();
  const [notification, notificationContext] = Notification.useNotification();

  useEffect(() => {
    const initialData =
      getFromLocalStorageWithExpiry(`new_subsidio_formdata_${cooperativa.id}`) || {};

    if (Object.keys(initialData).length > 0) {
      Modal.destroyAll();
      modal.confirm({
        width: 600,
        centered: true,
        title: 'Datos guardados',
        content:
          'Se encontraron datos guardados de un formulario anterior, ¿desea cargarlos en el formulario?',
        okText: 'Sí',
        cancelText: 'No',
        onOk: () => {
          form.current?.setFieldsValue({
            ...initialData,
            fecha_acreditacion: initialData.fecha
              ? dayjs(initialData.fecha, 'DD/MM/YYYY')
              : undefined
          });
        }
      });
    }
  }, []);

  const onFinish = async (values: NewSubsidioFormValues) => {
    const data: NewSubsidioFormValues = {
      ...values,
      cooperativas_id: cooperativa.id,
      fecha_acreditacion: dayjs(values.fecha_acreditacion, 'DD/MM/YYYY').format('YYYY-MM-DD')
    };

    return newSubsidio(data)
      .then((response) => {
        localStorage.removeItem(`new_subsidio_formdata_${cooperativa.id}`);
        modal.success({
          width: 800,
          centered: true,
          keyboard: false,
          closable: false,
          maskClosable: false,
          title: 'Subsidio creado',
          content: 'El subsidio se creó correctamente',
          footer: (
            <Space
              wrap
              align="end"
              style={{ marginTop: 20, width: '100%', flexDirection: 'row-reverse' }}
            >
              <Link
                to={`${ROUTES.COOPERATIVAS_SUBSIDIO_DETAILS.replace(
                  ':id_subsidio',
                  response.id.toString()
                ).replace(':id', cooperativa.id.toString())}}`}
              >
                <Button type="primary">Ver detalle</Button>
              </Link>

              <Link
                to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                  ':id',
                  cooperativa.id.toString()
                )}?seccion=subsidios`}
              >
                <Button>Volver a la cooperativa</Button>
              </Link>

              <Button
                type="dashed"
                onClick={() => {
                  form.current?.resetFields();
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  Modal.destroyAll();
                }}
              >
                Cargar nuevo subsidio
              </Button>
            </Space>
          )
        });

        return true;
      })
      .catch((error) => {
        let description =
          'Ocurrió un error inesperado al crear el subsidio, por favor intente nuevamente';
        if (error && error.response.data.message) {
          description = error.response.data.message;
        }
        if (error && error.response.data.errors) {
          const errors = Object.keys(error.response.data.errors).map((key) => ({
            name: key,
            errors: error.response.data.errors[key]
          }));

          form.current?.setFields(errors);
        }
        notification.error({
          description,
          placement: 'bottomRight',
          message: 'Error al crear el subsidio'
        });
        return false;
      });
  };

  return (
    <div style={{ maxWidth: 1100, margin: '0 auto' }}>
      {modalContext}
      {notificationContext}

      <ProForm<NewSubsidioFormValues>
        formRef={form}
        autoComplete="off"
        scrollToFirstError
        onFinish={onFinish}
        submitter={{
          render: (actions) => (
            <Card style={{ marginTop: 20 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    {...actions.submitButtonProps}
                    onClick={actions.submit || actions.submit}
                  >
                    Confirmar
                  </Button>
                </Col>

                <Col span={24}>
                  <Link
                    to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                      ':id',
                      cooperativa.id.toString()
                    )}?seccion=subsidios`}
                  >
                    <Button style={{ width: '100%' }}>Cancelar</Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        }}
        onValuesChange={(changedValues, values) => {
          setLocalStorageWithExpiry(`new_subsidio_formdata_${cooperativa.id}`, values);
        }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ProCard
              headerBordered
              collapsible={false}
              title={
                <Space>
                  <SolutionOutlined />
                  <span>Datos generales</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormText
                    name="expediente"
                    label="Número de expediente"
                    placeholder="Ingrese el número de expediente"
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormText
                    name="numero_resolucion"
                    label="Número de resolución"
                    placeholder="Ingrese el número de resolución"
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDigit
                    min={1}
                    name="monto"
                    label="Monto"
                    placeholder="Ingrese el monto del subsidio"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormSelect
                    showSearch
                    label="Estado"
                    name="estado_subsidio_id"
                    fieldProps={{
                      style: { width: '100% !important' }
                    }}
                    options={estados.map((estado) => ({
                      value: estado.id,
                      label: estado.nombre
                    }))}
                    placeholder="Seleccione un estado para el subsidio"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDatePickerYear
                    required
                    name="anio"
                    label="Año"
                    placeholder="Seleccione el año del subsidio"
                    fieldProps={{ ...DatePickerDefaultProps, format: 'YYYY' }}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDatePicker
                    required
                    name="fecha_acreditacion"
                    label="Fecha de acreditación"
                    fieldProps={DatePickerDefaultProps}
                    placeholder="Seleccione la fecha de acreditación del subsidio"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24}>
                  <ProFormItem label="Comentarios" name="comentarios">
                    <Quill
                      theme="snow"
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                          ['blockquote', 'code-block'],

                          [{ header: 1 }, { header: 2 }], // custom button values
                          [{ list: 'ordered' }, { list: 'bullet' }],
                          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                          [{ direction: 'rtl' }], // text direction

                          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],

                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],

                          ['clean'] // remove formatting button
                        ]
                      }}
                    />
                  </ProFormItem>
                </Col>

                <Col span={24}>
                  <ProFormItem label="Actuaciones" name="actuaciones">
                    <Quill
                      theme="snow"
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                          ['blockquote', 'code-block'],

                          [{ header: 1 }, { header: 2 }], // custom button values
                          [{ list: 'ordered' }, { list: 'bullet' }],
                          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                          [{ direction: 'rtl' }], // text direction

                          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],

                          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                          [{ font: [] }],
                          [{ align: [] }],

                          ['clean'] // remove formatting button
                        ]
                      }}
                    />
                  </ProFormItem>
                </Col>
              </Row>
            </ProCard>
          </Col>
        </Row>
      </ProForm>
    </div>
  );
});

NewSubsidioForm.displayName = 'NewSubsidioForm';

export default NewSubsidioForm;
