import axios from '../config/axios';

import type {
  OrigenFechaEntrada,
  OrigenFechaEntradaQueryParams
} from '../interfaces/origenFechaEntrada';

const PATH = `origenes_fecha_entrada`;

/**
 * @description Recupera desde la API los datos de origenes-fechas-entrada
 * @returns {Promise<OrigenFechaEntrada[]>}
 */
export const getOrigenesFechaEntrada = async (params: OrigenFechaEntradaQueryParams = {}) => {
  return await axios.get<{ data: OrigenFechaEntrada[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
