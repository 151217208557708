import axios from '../config/axios';

import type { Partido, PartidosQueryParams } from '../interfaces/partido';

const PATH = `partidos`;

/**
 * @description Recupera desde la API los partidos de la provincia de Buenos Aires
 * @returns {Promise<Partido[]>} Partidos
 */
export const getPartidos = async (params: PartidosQueryParams = {}) => {
  return await axios.get<{ data: Partido[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
