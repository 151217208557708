import axios from '../config/axios';

// Interfaces
import type {
  Cooperativa,
  CooperativaNewForm,
  CooperativaEditForm,
  CooperativaQueryParams,
  NewCooperativaDatoAdicional,
  CooperativaDatoAdicional,
  DireccionCooperativa,
  DireccionCooperativaNewForm,
  ActividadCooperativa,
  ActividadCooperativaQueryParams,
  ActividadCooperativaNewForm,
  ActividadCooperativaNativa
} from '../interfaces/cooperativa';
import type {
  ContactoCooperativa,
  CooperativaDetallada,
  ContactoCooperativaNewForm
} from '../interfaces/cooperativa';
import type { PaginatedResponse } from '../interfaces/api';
import type { TipoCooperativa, TipoCooperativaQueryParams } from '../interfaces/cooperativa';

/**************************************************
 *
 *            Cooperativas
 *
 *************************************************/

const COOPERATIVAS_PATH = 'cooperativas';

/**
 * @description Recupera desde la API las cooperativas
 * @returns {Promise<Cooperativa[]>} Cooperativas
 */

export const getCooperativas = async (params: CooperativaQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Cooperativa>>(COOPERATIVAS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la API una nueva cooperativa
 * @returns {Promise<Cooperativa>} Cooperativa
 */
export const newCooperativa = async (data: CooperativaNewForm) => {
  return await axios.post<{ data: Cooperativa }>(COOPERATIVAS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Recupera desde la API una cooperativa
 * @param id Identificador de la cooperativa
 * @returns {Promise<CooperativaDetallada>} Cooperativa
 */
export const getCooperativa = async (id: number | string) => {
  return await axios
    .get<{ data: CooperativaDetallada }>(`${COOPERATIVAS_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API una cooperativa
 * @param id Identificador de la cooperativa
 * @returns {Promise<Cooperativa>} Cooperativa
 */
export const updateCooperativa = async (id: number | string, data: CooperativaEditForm) => {
  return await axios.put<Cooperativa>(`${COOPERATIVAS_PATH}/${id}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina en la API una cooperativa
 * @param id Identificador de la cooperativa
 * @returns {Promise<void>} Cooperativa
 */
export const deleteCooperativa = async (id: number | string) => {
  return await axios.delete<void>(`${COOPERATIVAS_PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**
 * @description Hace un request a la api y genera un archivo con formato .XLSX que contiene el listado de cooperativas.
 * @returns Un archivo con formato `.xlsx`.
 */
export const exportCooperativas = async (params: any) => {
  return await axios.get(`exportar/cooperativas`, { params }).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Datos de adicionales
 *
 *************************************************/

/**
 * @description Recupera desde la API los datos adicionales de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<CooperativaDetallada>} Cooperativa
 */
export const getCooperativaDatosAdicionales = async (id: number | string) => {
  return await axios
    .get<{ data: CooperativaDatoAdicional }>(`${COOPERATIVAS_PATH}/${id}/datos_adicionales`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API los datos adicionales de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Cooperativa>} Cooperativa
 */
export const newCooperativaDatoAdicional = async (
  id: number | string,
  data: NewCooperativaDatoAdicional
) => {
  return await axios
    .post<CooperativaDatoAdicional>(`${COOPERATIVAS_PATH}/${id}/datos_adicionales`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la API los datos adicionales de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Cooperativa>} Cooperativa
 */

export const updateCooperativaDatoAdicional = async (
  cooperativa_id: number | string,
  id: number | string,
  data: NewCooperativaDatoAdicional
) => {
  return await axios
    .patch<CooperativaDatoAdicional>(
      `${COOPERATIVAS_PATH}/${cooperativa_id}/datos_adicionales/${id}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la API los datos adicionales de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<void>} Cooperativa
 */
export const deleteCooperativaDatoAdicional = async (
  cooperativa_id: number | string,
  id: number | string
) => {
  return await axios
    .delete<void>(`${COOPERATIVAS_PATH}/${cooperativa_id}/datos_adicionales/${id}`)
    .then((response) => {
      return response.data;
    });
};

/**************************************************
 *
 *            Tipos de cooperativas
 *
 *************************************************/

const TIPOS_COOPERATIVAS_PATH = 'tipos_cooperativas';

/**
 * @description Recupera desde la API los tipos de cooperativas
 * @returns {Promise<TipoCooperativa[]>} Tipos de cooperativas
 */
export const getTiposCooperativas = async (params: TipoCooperativaQueryParams = {}) => {
  return await axios
    .get<{ data: TipoCooperativa[] }>(TIPOS_COOPERATIVAS_PATH, { params })
    .then((response) => {
      return response.data.data;
    });
};

/**************************************************
 *
 *            Datos de contacto
 *
 *************************************************/

/**
 * @description Recupera desde la API los datos de contacto de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<ContactoCooperativa>} ContactoCooperativa
 */
export const getCooperativaDatosContacto = async (id: number | string) => {
  return await axios
    .get<{ data: ContactoCooperativa[] }>(`${COOPERATIVAS_PATH}/${id}/contactos`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un nuevo dato de contacto para una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @param {ContactoPersona} data Datos de contacto
 * @returns {Promise<ContactoCooperativa>} ContactoCooperativa
 */
export const newCooperativaDatosContacto = async (
  id: number | string,
  data: ContactoCooperativaNewForm
) => {
  return await axios
    .post<{ data: ContactoCooperativa }>(`${COOPERATIVAS_PATH}/${id}/contactos`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un dato de contacto de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @param {number | string} contactoId Identificador del dato de contacto
 * @returns {Promise<ContactoCooperativa>} ContactoCooperativa
 */
export const updateCooperativaDatosContacto = async (
  id: number | string,
  contactoId: number | string,
  data: ContactoCooperativaNewForm
) => {
  return await axios
    .put<{ data: ContactoCooperativa }>(`${COOPERATIVAS_PATH}/${id}/contactos/${contactoId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**************************************************
 *
 *            Direcciones
 *
 *************************************************/

/**
 *  @description Recupera desde la API las direcciones de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<DireccionCooperativa[]>} ContactoCooperativa
 */

export const getCooperativaDirecciones = async (id: number | string) => {
  return await axios
    .get<{ data: DireccionCooperativa[] }>(`${COOPERATIVAS_PATH}/${id}/direcciones`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API una nueva dirección para una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @param {DireccionCooperativaNewForm} data Datos de la dirección
 * @returns {Promise<DireccionCooperativa>} DireccionCooperativa
 */
export const newCooperativaDireccion = async (
  id: number | string,
  data: DireccionCooperativaNewForm
) => {
  return await axios
    .post<{ data: DireccionCooperativa }>(`${COOPERATIVAS_PATH}/${id}/direcciones`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API una dirección de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @param {number | string} direccionId Identificador de la dirección
 * @returns {Promise<DireccionCooperativa>} DireccionCooperativa
 */
export const updateCooperativaDireccion = async (
  id: number | string,
  direccionId: number | string,
  data: DireccionCooperativaNewForm
) => {
  return await axios
    .put<{ data: DireccionCooperativa }>(
      `${COOPERATIVAS_PATH}/${id}/direcciones/${direccionId}`,
      data
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API una dirección de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @param {number | string} direccionId Identificador de la dirección
 * @returns {Promise<void>} DireccionCooperativa
 */
export const deleteCooperativaDireccion = async (
  id: number | string,
  direccionId: number | string
) => {
  return await axios
    .delete<{ data: DireccionCooperativa }>(`${COOPERATIVAS_PATH}/${id}/direcciones/${direccionId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**************************************************
 *
 *            Actividades
 *
 *************************************************/

const ACTIVIDADES_PATH = 'cooperativa_actividad_afip';

/**
 * @description Recupera desde la API las actividades de una cooperativa
 * @returns {Promise<PaginatedResponse<ActividadCooperativa>>} ActividadCooperativa
 */
export const getCooperativaActividades = async (params: ActividadCooperativaQueryParams) => {
  return await axios
    .get<PaginatedResponse<ActividadCooperativa>>(ACTIVIDADES_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la API una nueva actividad para una cooperativa
 * @param {ActividadCooperativa} data Datos de la actividad
 * @returns {Promise<ActividadCooperativa>} ActividadCooperativa
 */
export const newCooperativaActividad = async (data: ActividadCooperativaNewForm) => {
  return await axios
    .post<{ data: ActividadCooperativa }>(ACTIVIDADES_PATH, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API una actividad de una cooperativa
 * @param {number | string} actividadId Identificador de la actividad
 * @returns {Promise<ActividadCooperativa>} ActividadCooperativa
 */
export const updateCooperativaActividad = async (
  actividadId: number | string,
  data: ActividadCooperativaNewForm
) => {
  return await axios
    .put<{ data: ActividadCooperativa }>(`${ACTIVIDADES_PATH}/${actividadId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API una actividad de una cooperativa
 * @param {number | string} actividadId Identificador de la actividad
 * @returns {Promise<void>} ActividadCooperativa
 */
export const deleteCooperativaActividad = async (actividadId: number | string) => {
  return await axios.delete<void>(`${ACTIVIDADES_PATH}/${actividadId}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Actividades nativas
 *
 *************************************************/

const ACTIVIDADES_NATIVAS_PATH = 'cooperativa_actividad';

/**
 * @description Recupera desde la API las actividades nativas de una cooperativa
 * @returns {Promise<PaginatedResponse<ActividadCooperativaNativa>>} ActividadCooperativaNativa
 */

export const getCooperativaActividadesNativas = async (params: ActividadCooperativaQueryParams) => {
  return await axios
    .get<PaginatedResponse<ActividadCooperativaNativa>>(ACTIVIDADES_NATIVAS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};
