import { memo, useEffect } from 'react';

// Components
import { SubsidioDetailsContainer } from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchSubsidio } from '../../../../store/slices/subsidioDetalle';
import { fetchEstadosSubsidios } from '../../../../store/slices/estadosSubsidios';

const LOADING_SLICES = ['subsidioDetalle'] as const;

export const CooperativaSubsidioDetailsPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string; id_subsidio: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id && params.id_subsidio) {
      dispatch(fetchSubsidio(params.id_subsidio));
    }
  }, [dispatch, params.id_subsidio, params.id]);

  useEffect(() => {
    dispatch(fetchEstadosSubsidios());
  }, [dispatch]);

  return <SubsidioDetailsContainer loading={loading} />;
});

CooperativaSubsidioDetailsPage.displayName = 'CooperativaSubsidioDetailsPage';

export default CooperativaSubsidioDetailsPage;
