import axios from '../config/axios';

import type { TipoAlerta, TipoAlertaQueryParams } from '../interfaces/tipoAlerta';

const PATH = `tipos_alertas`;

/**
 * @description Recupera desde la API los tipos de alerta
 * @returns {Promise<TipoAlerta[]>} TipoAlertas
 */
export const getTiposAlertas = async (params: TipoAlertaQueryParams = {}) => {
  return await axios.get<{ data: TipoAlerta[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
