// Components
import { EditOutlined } from '@ant-design/icons';
import CustomFromDrawer from '../../../components/drawer/form';
import { ProFormDependency, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { updateEmpresaAnterior } from '../../../services/empresaAnterior.service';

// Interfaces
import { memo, type FC } from 'react';
import type {
  EmpresaAnterior,
  EmpresaAnteriorNewFormValues
} from '../../../interfaces/empresaAnterior';

interface EditEmpresaConvenioProps {
  empresa: EmpresaAnterior;
  onRefresh?: () => void;
}

export const EditEmpresaConvenio: FC<EditEmpresaConvenioProps> = memo((props) => {
  const { empresa } = props;
  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  return (
    <CustomFromDrawer<EmpresaAnteriorNewFormValues>
      maxWidth={700}
      title="Editar datos"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        ...empresa
      }}
      // @ts-ignore
      onValuesChange={(changedValues, values, form) => {
        if (!values.convenio) {
          form?.current?.setFieldsValue({
            nro_exp_convenio: undefined
          });
        }
      }}
      onFinish={async (values) => {
        return updateEmpresaAnterior(empresa.id, {
          ...values,
          nro_exp_convenio: values.nro_exp_convenio || ''
        })
          .then(() => {
            message.success('Empresa actualizada correctamente');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos de la empresa, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos de la empresa'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormSelect
            name="convenio"
            placeholder="Seleccione una opción"
            label="Tiene convenio"
            options={[
              { label: 'Sí', value: 1 },
              { label: 'No', value: 0 }
            ]}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <ProFormDependency name={['convenio']}>
          {({ convenio }) => (
            <>
              <Col span={24}>
                <ProFormText
                  disabled={!convenio}
                  name="nro_exp_convenio"
                  label="Número de expediente de convenio"
                  placeholder="Ingrese el número de expediente de convenio"
                  rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
                />
              </Col>
            </>
          )}
        </ProFormDependency>
      </Row>
    </CustomFromDrawer>
  );
});

EditEmpresaConvenio.displayName = 'EditEmpresaConvenio';
