/* eslint-disable @typescript-eslint/no-empty-interface */
import { FC, memo } from 'react';
import ReactHtmlParser from 'react-html-parser';

// Redux

// Services
import ProCard from '@ant-design/pro-card';
import { useAppSelector } from '../../../../../../store/store';
import { EditSubsidioActuacionesDetails } from '../../../../components/editSubsidioActuaciones';

interface SubsidioActuacionesDetailsContainerProps {}

export const SubsidioActuacionesDetailsContainer: FC<SubsidioActuacionesDetailsContainerProps> =
  memo(() => {
    const user = useAppSelector((state) => state.auth.user!);
    const subsidio = useAppSelector((state) => state.subsidioDetalle.subsidio!);

    return (
      <ProCard
        collapsible
        title="Actuaciones"
        extra={
          user && user.permissions.includes('edit_subsidios') ? (
            <EditSubsidioActuacionesDetails />
          ) : null
        }
      >
        {ReactHtmlParser(subsidio?.actuaciones || 'Sin especificar')}
      </ProCard>
    );
  });

SubsidioActuacionesDetailsContainer.displayName = 'SubsidioActuacionesDetailsContainer';
