/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef } from 'react';

// Components
import { Link } from 'react-router-dom';
import {
  FileOutlined,
  TeamOutlined,
  AuditOutlined,
  IdcardOutlined,
  SolutionOutlined
} from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import { Button, Card, Col, Divider } from 'antd';
import { ProFormSelect } from '@ant-design/pro-form';
import ProForm, { ProFormDatePicker } from '@ant-design/pro-form';
import { Modal, Row, Space, notification as Notification } from 'antd';
import { ProFormDependency, ProFormList, ProFormText } from '@ant-design/pro-form';

// Utils
import dayjs from 'dayjs';
import {
  setLocalStorageWithExpiry,
  getFromLocalStorageWithExpiry
} from '../../../../../utils/localstorage';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';
import { DatePickerDefaultProps } from '../../../../../constants/form';

// Services
import { newEmpresaAnterior } from '../../../../../services/empresaAnterior.service';

// Interfaces
import type { ProFormInstance } from '@ant-design/pro-form';
import type { EmpresaAnteriorNewFormValues } from '../../../../../interfaces/empresaAnterior';

export const NewEmpresaForm = memo(() => {
  const form = useRef<ProFormInstance>();

  const user = useAppSelector((state) => state.auth.user!);
  const actividadesAfip = useAppSelector((state) => state.actividadesAfip.entities);
  const cooperativa = useAppSelector((state) => state.cooperativaDetalle.cooperativa!);

  const [modal, modalContext] = Modal.useModal();
  const [notification, notificationContext] = Notification.useNotification();

  useEffect(() => {
    const initialData =
      getFromLocalStorageWithExpiry(`new_empresa_formdata_${cooperativa.id}`) || {};

    console.log(initialData);

    if (Object.keys(initialData).length > 0) {
      Modal.destroyAll();
      modal.confirm({
        width: 600,
        centered: true,
        title: 'Datos guardados',
        content:
          'Se encontraron datos guardados de un formulario anterior, ¿desea cargarlos en el formulario?',
        okText: 'Sí',
        cancelText: 'No',
        onOk: () => {
          const data = {
            ...initialData,
            fecha_nro_disposicion_praer: initialData.fecha_nro_disposicion_praer
              ? dayjs(initialData.fecha_nro_disposicion_praer, 'DD/MM/YYYY')
              : undefined,
            leyes:
              initialData.leyes && initialData.leyes.length > 0
                ? initialData.leyes.map((l: any) => ({
                    ...l,
                    fecha_sancion: l.fecha_sancion
                      ? dayjs(l.fecha_sancion, 'DD/MM/YYYY')
                      : undefined,
                    fecha_promulgacion: l.fecha_promulgacion
                      ? dayjs(l.fecha_promulgacion, 'DD/MM/YYYY')
                      : undefined,
                    fecha_publicacion_bo: l.fecha_publicacion_bo
                      ? dayjs(l.fecha_publicacion_bo, 'DD/MM/YYYY')
                      : undefined,
                    fecha_vencimiento: l.fecha_vencimiento
                      ? dayjs(l.fecha_vencimiento, 'DD/MM/YYYY')
                      : undefined
                  }))
                : [{}]
          };
          form.current?.setFieldsValue(data);
        }
      });
    }
  }, []);

  const onFinish = async (values: EmpresaAnteriorNewFormValues) => {
    const data: EmpresaAnteriorNewFormValues = {
      ...values,
      cooperativas_id: cooperativa.id,
      fecha_nro_disposicion_praer: values.fecha_nro_disposicion_praer
        ? dayjs(values.fecha_nro_disposicion_praer).format('YYYY-MM-DD')
        : undefined,
      leyes: (values.leyes || []).map((l) => ({
        ...l,
        fecha_sancion: dayjs(l.fecha_sancion).format('YYYY-MM-DD'),
        fecha_vencimiento: dayjs(l.fecha_vencimiento).format('YYYY-MM-DD'),
        fecha_promulgacion: dayjs(l.fecha_promulgacion).format('YYYY-MM-DD'),
        fecha_publicacion_bo: dayjs(l.fecha_publicacion_bo).format('YYYY-MM-DD')
      }))
    };

    return newEmpresaAnterior(data)
      .then((response) => {
        localStorage.removeItem(`new_empresa_formdata_${cooperativa.id}`);
        modal.success({
          width: 800,
          centered: true,
          keyboard: false,
          closable: false,
          maskClosable: false,
          title: 'Empresa anterior registrada',
          content: 'La empresa anterior se registró correctamente',
          footer: (
            <Space
              wrap
              align="end"
              style={{ marginTop: 20, width: '100%', flexDirection: 'row-reverse' }}
            >
              <Link
                to={`${ROUTES.COOPERATIVAS_EMPRESA_ANTERIOR_DETAILS.replace(
                  ':id_empresa_anterior',
                  response.id.toString()
                ).replace(':id', cooperativa.id.toString())}}`}
              >
                <Button type="primary">Ver detalle</Button>
              </Link>

              <Link
                to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                  ':id',
                  cooperativa.id.toString()
                )}?seccion=empresas`}
              >
                <Button>Volver a la cooperativa</Button>
              </Link>

              <Button
                type="dashed"
                onClick={() => {
                  form.current?.resetFields();
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  Modal.destroyAll();
                }}
              >
                Registrar nueva empresa
              </Button>
            </Space>
          )
        });

        return true;
      })
      .catch((error) => {
        let description =
          'Ocurrió un error inesperado al registrar la empresa, por favor intente nuevamente';
        if (error && error.response.data.message) {
          description = error.response.data.message;
        }
        if (error && error.response.data.errors) {
          const errors = Object.keys(error.response.data.errors).map((key) => ({
            name: key,
            errors: error.response.data.errors[key]
          }));

          form.current?.setFields(errors);
        }
        notification.error({
          description,
          placement: 'bottomRight',
          message: 'Error al registrar la empresa'
        });
        return false;
      });
  };

  return (
    <div style={{ maxWidth: 1100, margin: '0 auto' }}>
      {modalContext}
      {notificationContext}

      <ProForm<EmpresaAnteriorNewFormValues>
        formRef={form}
        autoComplete="off"
        scrollToFirstError
        onFinish={onFinish}
        submitter={{
          render: (actions) => (
            <Card style={{ marginTop: 20 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    {...actions.submitButtonProps}
                    onClick={actions.submit || actions.submit}
                  >
                    Confirmar
                  </Button>
                </Col>

                <Col span={24}>
                  <Link
                    to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                      ':id',
                      cooperativa.id.toString()
                    )}?seccion=empresas`}
                  >
                    <Button style={{ width: '100%' }}>Cancelar</Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        }}
        onValuesChange={(changedValues, values) => {
          setLocalStorageWithExpiry(`new_empresa_formdata_${cooperativa.id}`, values);
        }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ProCard
              headerBordered
              collapsible
              title={
                <Space>
                  <SolutionOutlined />
                  <span>Datos generales</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormSelect
                    name="reempadrono_inaes"
                    placeholder="Seleccione una opción"
                    label="Reempadrono INAES"
                    options={[
                      { label: 'Sí', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormSelect
                    name="sentencia_firme"
                    placeholder="Seleccione una opción"
                    label="Sentencia firme"
                    options={[
                      { label: 'Sí', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24}>
                  <ProFormText
                    name="situacion_dominial_bienes"
                    label="Situación dominial de los bienes"
                    placeholder="Ingrese la situación dominial de los bienes"
                    rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
                  />
                </Col>

                <Col span={24}>
                  <ProFormText
                    name="liquidacion_judicial"
                    label="Liquidación judicial"
                    placeholder="Ingrese la liquidación judicial"
                    rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
                  />
                </Col>

                <Col span={24}>
                  <ProFormText
                    name="tasacion_fiscalia_estado"
                    label="Tasación fiscalía de estado"
                    placeholder="Ingrese la tasación fiscalía de estado"
                    rules={[
                      { required: true, message: 'Este campo es obligatorio' },
                      { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
                    ]}
                  />
                </Col>
              </Row>
            </ProCard>
          </Col>

          <Col span={24}>
            <ProCard
              headerBordered
              collapsible
              title={
                <Space>
                  <FileOutlined />
                  <span>Inversa</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormSelect
                    name="inversa"
                    placeholder="Seleccione una opción"
                    label="Tiene inversa"
                    options={[
                      { label: 'Sí', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                    onChange={(value) => {
                      if (!value) {
                        form.current?.setFieldsValue({
                          expediente_inversa: undefined
                        });
                      }
                    }}
                  />
                </Col>

                <ProFormDependency name={['inversa']}>
                  {({ inversa }) => (
                    <>
                      <Col span={24} lg={12}>
                        <ProFormText
                          disabled={!inversa}
                          name="expediente_inversa"
                          label="Número de expediente de inversa"
                          placeholder="Ingrese el número de expediente de inversa"
                          rules={[
                            { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
                          ]}
                        />
                      </Col>
                    </>
                  )}
                </ProFormDependency>
              </Row>
            </ProCard>
          </Col>

          <Col span={24}>
            <ProCard
              headerBordered
              collapsible
              title={
                <Space>
                  <TeamOutlined />
                  <span>Convenio</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormSelect
                    name="convenio"
                    placeholder="Seleccione una opción"
                    label="Tiene convenio"
                    options={[
                      { label: 'Sí', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                    onChange={(value) => {
                      if (!value) {
                        form.current?.setFieldsValue({
                          nro_exp_convenio: undefined
                        });
                      }
                    }}
                  />
                </Col>

                <ProFormDependency name={['convenio']}>
                  {({ convenio }) => (
                    <>
                      <Col span={24} lg={12}>
                        <ProFormText
                          disabled={!convenio}
                          name="nro_exp_convenio"
                          label="Número de expediente de convenio"
                          placeholder="Ingrese el número de expediente de convenio"
                          rules={[
                            { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
                          ]}
                        />
                      </Col>
                    </>
                  )}
                </ProFormDependency>
              </Row>
            </ProCard>
          </Col>

          <Col span={24}>
            <ProCard
              collapsible
              headerBordered
              title={
                <Space>
                  <SolutionOutlined />
                  <span>PRAER</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormSelect
                    name="solicitud_inscripcion_praer"
                    placeholder="Seleccione una opción"
                    label="Tiene solicitud de inscripción en PRAER"
                    options={[
                      { label: 'Sí', value: 1 },
                      { label: 'No', value: 0 }
                    ]}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                    onChange={(value) => {
                      if (!value) {
                        form.current?.setFieldsValue({
                          nro_registro_praer: undefined,
                          nro_disposicion_praer: undefined,
                          fecha_nro_disposicion_praer: undefined
                        });
                      }
                    }}
                  />
                </Col>

                <ProFormDependency name={['solicitud_inscripcion_praer']}>
                  {({ solicitud_inscripcion_praer }) => (
                    <>
                      <Col span={24} lg={12}>
                        <ProFormText
                          name="nro_registro_praer"
                          label="Número de registro PRAER"
                          disabled={!solicitud_inscripcion_praer}
                          placeholder="Ingrese el número de registro PRAER"
                          rules={[
                            { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
                          ]}
                        />
                      </Col>

                      <Col span={24} lg={12}>
                        <ProFormText
                          name="nro_disposicion_praer"
                          label="Número de disposición PRAER"
                          disabled={!solicitud_inscripcion_praer}
                          placeholder="Ingrese el número de disposición PRAER"
                          rules={[
                            { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
                          ]}
                        />
                      </Col>

                      <Col span={24} lg={12}>
                        <ProFormDatePicker
                          name="fecha_nro_disposicion_praer"
                          label="Fecha de disposición PRAER"
                          disabled={!solicitud_inscripcion_praer}
                          placeholder="Seleccione la fecha de disposición PRAER"
                          fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
                        />
                      </Col>
                    </>
                  )}
                </ProFormDependency>
              </Row>
            </ProCard>
          </Col>

          {user && user.permissions.includes('add_empresa_anterior_actividades') ? (
            <Col span={24}>
              <ProCard
                collapsible
                headerBordered
                title={
                  <Space>
                    <IdcardOutlined />
                    <span>Actividades</span>
                  </Space>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col span={24} lg={24}>
                    <ProFormSelect
                      mode="multiple"
                      name="actividades_afip_ids"
                      placeholder="Seleccione una o varias actividades"
                      label="Actividades que realiza la empresa"
                      options={actividadesAfip.map((actividad) => ({
                        label: actividad.nombre,
                        value: actividad.id
                      }))}
                    />
                  </Col>
                </Row>
              </ProCard>
            </Col>
          ) : null}

          {user && user.permissions.includes('add_leyes_expropiacion') ? (
            <Col span={24}>
              <ProCard
                collapsible
                headerBordered
                title={
                  <Space>
                    <AuditOutlined />
                    <span>Leyes de expropiación</span>
                  </Space>
                }
              >
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <ProFormList
                      min={1}
                      name="leyes"
                      initialValue={[{}]}
                      alwaysShowItemLabel
                      copyIconProps={false}
                      deleteIconProps={{
                        tooltipText: 'Eliminar'
                      }}
                      style={{ width: '100%' }}
                      creatorButtonProps={{
                        position: 'bottom',
                        creatorButtonText: 'Registrar nueva ley'
                      }}
                    >
                      {(index) => (
                        <Row key={index.key} gutter={16}>
                          <Col span={24}>
                            <ProFormText
                              name="descripcion"
                              label="Descripción"
                              placeholder="Ingrese la descripción"
                              rules={[
                                { required: true, message: 'Este campo es obligatorio' },
                                {
                                  max: 45,
                                  message: 'Este campo no puede tener más de 45 caracteres'
                                }
                              ]}
                            />
                          </Col>

                          <Col span={24} lg={12}>
                            <ProFormDatePicker
                              name="fecha_sancion"
                              label="Fecha de sanción"
                              placeholder="Seleccione la fecha de sanción"
                              fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
                              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                            />
                          </Col>

                          <Col span={24} lg={12}>
                            <ProFormDatePicker
                              name="fecha_promulgacion"
                              label="Fecha de promulgación"
                              placeholder="Seleccione la fecha de promulgación"
                              fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
                              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                            />
                          </Col>

                          <Col span={24} lg={12}>
                            <ProFormDatePicker
                              name="fecha_publicacion_bo"
                              label="Fecha de publicación en el boletín oficial"
                              placeholder="Seleccione la fecha de publicación en el boletín oficial"
                              fieldProps={{ ...DatePickerDefaultProps, format: 'DD/MM/YYYY' }}
                              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                            />
                          </Col>

                          <Col span={24} lg={12}>
                            <ProFormDatePicker
                              name="fecha_vencimiento"
                              label="Fecha de vencimiento"
                              placeholder="Seleccione la fecha de vencimiento"
                              fieldProps={{
                                format: 'DD/MM/YYYY',
                                style: { width: '100%' }
                              }}
                              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                            />
                          </Col>

                          <Col span={24}>
                            <Divider />
                          </Col>
                        </Row>
                      )}
                    </ProFormList>
                  </Col>
                </Row>
              </ProCard>
            </Col>
          ) : null}
        </Row>
      </ProForm>
    </div>
  );
});

NewEmpresaForm.displayName = 'NewEmpresaForm';

export default NewEmpresaForm;
