/* eslint-disable @typescript-eslint/no-empty-interface */
// Components
import { EditOutlined } from '@ant-design/icons';
import { Quill } from '../../../components/form/Quill';
import { DrawerForm, ProFormItem } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { fetchAsyncSubsidio } from '../../../store/slices/subsidioDetalle';

// Services
import { updateSubsidio } from '../../../services/subsidios.service';

// Interfaces
import { memo, type FC } from 'react';
import type { NewSubsidioFormValues } from '../../../interfaces/subsidio';

interface EditSubsidioActuacionesDetailsProps {}

export const EditSubsidioActuacionesDetails: FC<EditSubsidioActuacionesDetailsProps> = memo(() => {
  const dispatch = useAppDispatch();

  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const subsidio = useAppSelector((state) => state.subsidioDetalle.subsidio!);

  const onRefresh = () => {
    dispatch(fetchAsyncSubsidio(subsidio?.id as number));
  };

  return (
    <DrawerForm<NewSubsidioFormValues>
      title="Editar actuaciones"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        actuaciones: subsidio.actuaciones || ''
      }}
      onFinish={async (values) => {
        return updateSubsidio(subsidio.id, values)
          .then(() => {
            message.success('Datos actualizados correctamente');
            onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos del subsidio, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos del subsidio'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormItem name="actuaciones">
            <Quill />
          </ProFormItem>
        </Col>
      </Row>
    </DrawerForm>
  );
});

EditSubsidioActuacionesDetails.displayName = 'EditSubsidioActuacionesDetails';
