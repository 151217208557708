import axios from '../config/axios';

import type { TipoCargo, TipoCargoQueryParams } from '../interfaces/tipoCargo';

const PATH = `tipos_cargo`;

/**
 * @description Recupera desde la API los tipos de cargos
 * @returns {Promise<TipoCargo[]>} TipoCargos
 */
export const getTiposCargos = async (params: TipoCargoQueryParams = {}) => {
  return await axios.get<{ data: TipoCargo[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
