import axios from '../config/axios';
import { PaginatedResponse } from '../interfaces/api';

import type {
  Consejo,
  ConsejoDetallado,
  ConsejoIntegrante,
  ConsejoIntegranteNewForm,
  ConsejoNewForm
} from '../interfaces/consejo';

/**************************************************
 *
 *            Consejos
 *
 *************************************************/

/**
 * @description Recupera desde la API los consejos de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Consejo[]>} Consejos
 */
export const getConsejos = async (id: number | string, params: any = {}) => {
  return await axios
    .get<PaginatedResponse<ConsejoDetallado>>(`cooperativas/${id}/consejos`, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un consejo de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Consejo>} Consejo
 */
export const getConsejo = async (cooperativa_id: number | string, id: number | string) => {
  return await axios
    .get<{ data: ConsejoDetallado }>(`cooperativas/${cooperativa_id}/consejos/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un consejo de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Consejo>} Consejo
 */
export const newConsejo = async (id: number | string, data: ConsejoNewForm) => {
  return await axios
    .post<{ data: Consejo }>(`cooperativas/${id}/consejos`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un consejo de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Consejo>} Consejo
 */
export const updateConsejo = async (
  cooperativa_id: number | string,
  id: number | string,
  data: Omit<ConsejoNewForm, 'integrantes'>
) => {
  return await axios
    .patch<{ data: Consejo }>(`cooperativas/${cooperativa_id}/consejos/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un consejo de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<Consejo>} Consejo
 */
export const deleteConsejo = async (cooperativa_id: number | string, id: number | string) => {
  return await axios
    .delete<void>(`cooperativas/${cooperativa_id}/consejos/${id}`)
    .then((response) => {
      return response.data;
    });
};

/**************************************************
 *
 *            Integrantes
 *
 *************************************************/

/**
 * @description Recupera desde la API los integrantes de un consejo de una cooperativa
 * @param {number | string} id Identificador del consejo
 * @returns {Promise<ConsejoIntegrante>} Consejo
 */
export const getIntegrantes = async (id: number | string) => {
  return await axios
    .get<PaginatedResponse<ConsejoIntegrante>>(`consejos/${id}/integrantes`)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un integrante de un consejo de una cooperativa
 * @param {number | string} id Identificador del consejo
 * @returns {Promise<ConsejoIntegrante>} Consejo
 */
export const getIntegrante = async (consejo_id: number | string, id: number | string) => {
  return await axios
    .get<{ data: ConsejoIntegrante }>(`consejos/${consejo_id}/integrantes/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un integrante de un consejo de una cooperativa
 * @param {number | string} id Identificador del consejo
 * @returns {Promise<ConsejoIntegrante>} Consejo
 */
export const newIntegrante = async (id: number | string, data: ConsejoIntegranteNewForm) => {
  return await axios
    .post<{ data: ConsejoIntegrante }>(`consejos/${id}/integrantes`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la API un integrante de un consejo de una cooperativa
 * @param {number | string} id Identificador del consejo
 * @returns {Promise<ConsejoIntegrante>} Consejo
 */
export const updateIntegrante = async (
  consejo_id: number | string,
  id: number | string,
  data: ConsejoIntegranteNewForm
) => {
  return await axios
    .patch<{ data: ConsejoIntegrante }>(`consejos/${consejo_id}/integrantes/${id}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la API un integrante de un consejo de una cooperativa
 * @param {number | string} id Identificador del consejo
 * @returns {Promise<void>} Consejo
 */
export const deleteIntegrante = async (consejo_id: number | string, id: number | string) => {
  return await axios.delete<void>(`consejos/${consejo_id}/integrantes/${id}`).then((response) => {
    return response.data;
  });
};
