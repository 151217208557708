import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as TiposDocumentosService from '../../services/tiposDocumentos.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TiposDocumentosService.getTiposDocumentos>>[number];

export const fetchTiposDocumentos = createAsyncThunk<Entity[]>(
  'tiposDocumentos/fetch',
  (_params, api) => {
    const { tiposDocumentos } = api.getState() as RootState;
    if (tiposDocumentos.entities.length) return Promise.resolve(tiposDocumentos.entities);
    return TiposDocumentosService.getTiposDocumentos();
  }
);

interface TiposDocumentosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposDocumentosState = { loading: false, entities: [] };

const tiposDocumentosSlice = createSlice({
  name: 'tiposDocumentos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposDocumentos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposDocumentos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposDocumentos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposDocumentosActions = { ...tiposDocumentosSlice.actions, fetchTiposDocumentos };

export default tiposDocumentosSlice.reducer;
