import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as TipoAlertasService from '../../services/tiposAlertas.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof TipoAlertasService.getTiposAlertas>>[number];

export const fetchTipoAlertas = createAsyncThunk<Entity[]>('tiposAlertas/fetch', (_params, api) => {
  const { tiposAlertas } = api.getState() as RootState;
  if (tiposAlertas.entities.length) return Promise.resolve(tiposAlertas.entities);
  return TipoAlertasService.getTiposAlertas();
});

interface TipoAlertasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TipoAlertasState = { loading: false, entities: [] };

const tiposAlertasSlice = createSlice({
  name: 'tiposAlertas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTipoAlertas.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTipoAlertas.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTipoAlertas.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const tiposAlertasActions = tiposAlertasSlice.actions;

export default tiposAlertasSlice.reducer;
