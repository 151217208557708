import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Modal, message as Message, notification as Notification, Dropdown, Button } from 'antd';

// Redux
import { useAppSelector } from '../../../../../../store/store';

// Services
import { deleteRubrica } from '../../../../../../services/rubricas.service';

// Constants
import { ROUTES } from '../../../../../../constants/routes-constants';

// Interfaces
import type { MenuProps } from 'antd';
import { ObleaRubricaPdf } from './export';
import { PDFDownloadLink } from '@react-pdf/renderer';

export const RubricaActionsMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user!);
  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);
  const cooperativa = rubrica?.cooperativa;

  const [modal, modalContext] = Modal.useModal();
  const [notification, notificationContext] = Notification.useNotification();

  if (!rubrica) return null;

  const items: MenuProps['items'] = [
    {
      key: '0',
      icon: <DownloadOutlined />,
      label: (
        <PDFDownloadLink
          document={<ObleaRubricaPdf rubricas={[rubrica]} cooperativa={cooperativa!} />}
          fileName={`Oblea - Rubrica #${rubrica.id}.pdf`}
        >
          {() => 'Descargar oblea'}
        </PDFDownloadLink>
      )
    }
  ];

  if (user.permissions.includes('delete_rubricas')) {
    items.push({
      key: '1',
      icon: <DeleteOutlined />,
      label: 'Eliminar rubrica',
      onClick: () =>
        modal.confirm({
          width: 600,
          centered: true,
          title: '¿Está seguro que desea eliminar esta rubrica?',
          content: 'Esta acción no se puede deshacer.',
          okText: 'Sí, eliminar',
          okType: 'danger',
          okButtonProps: { type: 'primary' },
          cancelText: 'Cancelar',
          onOk: async () => {
            return deleteRubrica(rubrica.id)
              .then(() => {
                Message.success('Rubrica eliminada con éxito.');
                navigate(
                  ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa.id}`) +
                    '?seccion=rubricas'
                );
              })
              .catch((error) => {
                notification.error({
                  message: 'Error',
                  placement: 'bottomRight',
                  description:
                    error?.response?.data?.message ||
                    'Ocurrió un error inesperado al intentar eliminar la rubrica.'
                });
              });
          }
        })
    });
  }

  return items.length ? (
    <>
      {modalContext}
      {notificationContext}

      <Dropdown menu={{ items }} placement="bottomLeft">
        <Button icon={<DownOutlined />} shape="round">
          Acciones
        </Button>
      </Dropdown>
    </>
  ) : null;
};
