import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as tiposDatosAdicionalesService from '../../services/tiposDatosAdicionales.service';

import type { RootState } from '../store';

type Entity = Awaited<
  ReturnType<typeof tiposDatosAdicionalesService.getTiposDatosAdicionales>
>[number];

export const fetchTiposDatosAdicionales = createAsyncThunk<Entity[]>(
  'TiposDatosAdicionales/fetch',
  (_params, api) => {
    const { tiposDatosAdicionales } = api.getState() as RootState;
    if (tiposDatosAdicionales.entities.length)
      return Promise.resolve(tiposDatosAdicionales.entities);
    return tiposDatosAdicionalesService.getTiposDatosAdicionales();
  }
);

interface TiposDatosAdicionalesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposDatosAdicionalesState = { loading: false, entities: [] };

const tiposDatosAdicionalesSlice = createSlice({
  name: 'tiposDatosAdicionales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposDatosAdicionales.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchTiposDatosAdicionales.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposDatosAdicionales.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const TiposDatosAdicionalesActions = {
  ...tiposDatosAdicionalesSlice.actions,
  fetchTiposDatosAdicionales
};

export default tiposDatosAdicionalesSlice.reducer;
