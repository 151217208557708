import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as NacionalidadesService from '../../services/nacionalidades.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof NacionalidadesService.getNacionalidades>>[number];

export const fetchNacionalidades = createAsyncThunk<Entity[]>(
  'nacionalidades/fetch',
  (_params, api) => {
    const { nacionalidades } = api.getState() as RootState;
    if (nacionalidades.entities.length) return Promise.resolve(nacionalidades.entities);
    return NacionalidadesService.getNacionalidades();
  }
);

interface NacionalidadesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: NacionalidadesState = { loading: false, entities: [] };

const nacionalidadesSlice = createSlice({
  name: 'nacionalidades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNacionalidades.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchNacionalidades.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchNacionalidades.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const nacionalidadesActions = { ...nacionalidadesSlice.actions, fetchNacionalidades };

export default nacionalidadesSlice.reducer;
