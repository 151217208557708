import axios from '../config/axios';

import type {
  Rubrica,
  RubricaDetalle,
  RubricasQueryParams,
  NewRubricaFormValues
} from '../interfaces/rubrica';
import type { PaginatedResponse } from '../interfaces/api';

/**************************************************
 *
 *            Rubricas
 *
 *************************************************/

const RUBRICAS_PATH = 'rubricas';

/**
 * @description Recupera desde la API los rubricas de una cooperativa
 * @returns {Promise<PaginatedResponse<Rubrica>>} Rubricas
 */
export const getRubricas = async (params: RubricasQueryParams = {}) => {
  return await axios.get<PaginatedResponse<Rubrica>>(RUBRICAS_PATH, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API un rubrica de una cooperativa
 * @param {number | string} id Identificador del rubrica
 * @returns {Promise<Rubrica>} Rubrica
 */
export const getRubrica = async (id: number | string) => {
  return await axios.get<{ data: RubricaDetalle }>(`${RUBRICAS_PATH}/${id}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Registra en la API un rubrica de una cooperativa
 * @returns {Promise<Rubrica>} Rubrica
 */
export const newRubrica = async (data: NewRubricaFormValues) => {
  return await axios.post<{ data: Rubrica }>(RUBRICAS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza en la API un rubrica de una cooperativa
 * @param {number | string} id Identificador del rubrica
 * @returns {Promise<Rubrica>} Rubrica
 */
export const updateRubrica = async (id: number | string, data: NewRubricaFormValues) => {
  return await axios.put<{ data: Rubrica }>(`${RUBRICAS_PATH}/${id}`, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Elimina en la API un rubrica de una cooperativa
 * @param {number | string} id Identificador del rubrica
 * @returns {Promise<Rubrica>} Rubrica
 */
export const deleteRubrica = async (id: number | string) => {
  return await axios.delete<void>(`${RUBRICAS_PATH}/${id}`).then((response) => {
    return response.data;
  });
};
