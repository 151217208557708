import axios from '../config/axios';

import type { TipoContacto, TipoContactoQueryParams } from '../interfaces/tipoContacto';

const PATH = `tipos_contactos`;

/**
 * @description Recupera desde la API los tipos de contactos
 * @returns {Promise<TipoContacto[]>} Listado de Tipos de contactos
 */
export const getTiposContactos = async (params: TipoContactoQueryParams = {}) => {
  return await axios.get<{ data: TipoContacto[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
