// Components
import { EditOutlined } from '@ant-design/icons';
import CustomFromDrawer from '../../../components/drawer/form';
import { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { ProFormDatePicker, ProFormDigit } from '@ant-design/pro-form';
import ProFormDatePickerYear from '@ant-design/pro-form/es/components/DatePicker/YearPicker';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Utils
import dayjs from 'dayjs';

// Redux
import { useAppSelector } from '../../../store/store';
import { updateSubsidio } from '../../../services/subsidios.service';

// Constants
import { DatePickerDefaultProps } from '../../../constants/form';

// Interfaces
import { memo, type FC } from 'react';
import type { NewSubsidioFormValues, Subsidio } from '../../../interfaces/subsidio';

interface EditSubsidioDetailsProps {
  subsidio: Subsidio;
  onRefresh?: () => void;
}

export const EditSubsidioDetails: FC<EditSubsidioDetailsProps> = memo((props) => {
  const { subsidio } = props;
  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const estados = useAppSelector((state) => state.estadosSubsidios.entities);

  return (
    <CustomFromDrawer<NewSubsidioFormValues>
      maxWidth={700}
      title="Editar datos del subsidio"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        ...subsidio,
        fecha_acreditacion: subsidio.fecha_acreditacion
          ? dayjs(subsidio.fecha_acreditacion)
          : undefined,
        estado_subsidio_id: subsidio.estado_subsidio ? subsidio.estado_subsidio.id : undefined
      }}
      onFinish={async (values) => {
        if (values.fecha_acreditacion) {
          values.fecha_acreditacion = dayjs(values.fecha_acreditacion, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          );
        }

        return updateSubsidio(subsidio.id, values)
          .then(() => {
            message.success('Subsidio actualizado correctamente');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos del subsidio, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos del subsidio'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24} lg={12}>
          <ProFormText
            name="expediente"
            label="Número de expediente"
            placeholder="Ingrese el número de expediente"
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormText
            name="numero_resolucion"
            label="Número de resolución"
            placeholder="Ingrese el número de resolución"
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDigit
            min={1}
            name="monto"
            label="Monto"
            placeholder="Ingrese el monto del subsidio"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormSelect
            showSearch
            label="Estado"
            name="estado_subsidio_id"
            fieldProps={{
              style: { width: '100% !important' }
            }}
            options={estados.map((estado) => ({
              value: estado.id,
              label: estado.nombre
            }))}
            placeholder="Seleccione un estado para el subsidio"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePickerYear
            required
            name="anio"
            label="Año"
            placeholder="Seleccione el año del subsidio"
            fieldProps={{ ...DatePickerDefaultProps, format: 'YYYY' }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            required
            name="fecha_acreditacion"
            label="Fecha de acreditación"
            fieldProps={DatePickerDefaultProps}
            placeholder="Seleccione la fecha de acreditación del subsidio"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>
      </Row>
    </CustomFromDrawer>
  );
});

EditSubsidioDetails.displayName = 'EditSubsidioDetails';
