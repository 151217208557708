/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useRef } from 'react';

// Components
import { Link } from 'react-router-dom';
import { Button, Card, Col } from 'antd';
import ProCard from '@ant-design/pro-card';
import { ProFormDigit } from '@ant-design/pro-form';
import { SolutionOutlined } from '@ant-design/icons';
import { Quill } from '../../../../../components/form/Quill';
import ProForm, { ProFormDatePicker } from '@ant-design/pro-form';
import { ProFormItem, ProFormSelect } from '@ant-design/pro-form';
import { Modal, Row, Space, notification as Notification } from 'antd';

// Utils
import dayjs from 'dayjs';
import {
  setLocalStorageWithExpiry,
  getFromLocalStorageWithExpiry
} from '../../../../../utils/localstorage';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';
import { DatePickerDefaultProps } from '../../../../../constants/form';

// Services
import { newRubrica } from '../../../../../services/rubricas.service';

// Interfaces
import type { ProFormInstance } from '@ant-design/pro-form';
import type { NewRubricaFormValues } from '../../../../../interfaces/rubrica';

export const NewRubricaForm = memo(() => {
  const form = useRef<ProFormInstance>();
  const libros = useAppSelector((state) => state.rubricasLibros.entities);
  const cooperativa = useAppSelector((state) => state.cooperativaDetalle.cooperativa!);

  const [modal, modalContext] = Modal.useModal();
  const [notification, notificationContext] = Notification.useNotification();

  useEffect(() => {
    const initialData =
      getFromLocalStorageWithExpiry(`new_rubrica_formdata_${cooperativa.id}`) || {};

    if (Object.keys(initialData).length > 0) {
      Modal.destroyAll();
      modal.confirm({
        width: 600,
        centered: true,
        title: 'Datos guardados',
        content:
          'Se encontraron datos guardados de un formulario anterior, ¿desea cargarlos en el formulario?',
        okText: 'Sí',
        cancelText: 'No',
        onOk: () => {
          form.current?.setFieldsValue({
            ...initialData,
            fecha_acreditacion: initialData.fecha
              ? dayjs(initialData.fecha, 'DD/MM/YYYY')
              : undefined
          });
        }
      });
    }
  }, []);

  const onFinish = async (values: NewRubricaFormValues & { hojas: number[] }) => {
    const data: NewRubricaFormValues = {
      ...values,
      // desde_hoja: values.hojas[0],
      // hasta_hoja: values.hojas[1],
      cooperativas_id: cooperativa.id,
      fecha_entrada: dayjs(values.fecha_entrada, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      fecha_entrega: values.fecha_entrega
        ? dayjs(values.fecha_entrega, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : undefined,
      fecha_rubrica: dayjs(values.fecha_rubrica, 'DD/MM/YYYY').format('YYYY-MM-DD')
    };

    return newRubrica(data)
      .then((response) => {
        localStorage.removeItem(`new_rubrica_formdata_${cooperativa.id}`);
        modal.success({
          width: 800,
          centered: true,
          keyboard: false,
          closable: false,
          maskClosable: false,
          title: 'Rubrica creado',
          content: 'La rubrica se creó correctamente.',
          footer: (
            <Space
              wrap
              align="end"
              style={{ marginTop: 20, width: '100%', flexDirection: 'row-reverse' }}
            >
              <Link
                to={`${ROUTES.COOPERATIVAS_RUBRICA_DETAILS.replace(
                  ':id_rubrica',
                  response.id.toString()
                ).replace(':id', cooperativa.id.toString())}}`}
              >
                <Button type="primary">Ver detalle</Button>
              </Link>

              <Link
                to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                  ':id',
                  cooperativa.id.toString()
                )}?seccion=rubricas`}
              >
                <Button>Volver a la cooperativa</Button>
              </Link>

              <Button
                type="dashed"
                onClick={() => {
                  form.current?.resetFields();
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  Modal.destroyAll();
                }}
              >
                Cargar nueva rubrica
              </Button>
            </Space>
          )
        });

        return true;
      })
      .catch((error) => {
        let description =
          'Ocurrió un error inesperado al crear la rubrica, por favor intente nuevamente';
        if (error && error.response.data.message) {
          description = error.response.data.message;
        }
        if (error && error.response.data.errors) {
          const errors = Object.keys(error.response.data.errors).map((key) => ({
            name: key,
            errors: error.response.data.errors[key]
          }));

          form.current?.setFields(errors);
        }
        notification.error({
          description,
          placement: 'bottomRight',
          message: 'Error al crear la rubrica'
        });
        return false;
      });
  };

  return (
    <div style={{ maxWidth: 1100, margin: '0 auto' }}>
      {modalContext}
      {notificationContext}

      <ProForm<NewRubricaFormValues & { hojas: number[] }>
        formRef={form}
        autoComplete="off"
        scrollToFirstError
        onFinish={onFinish}
        submitter={{
          render: (actions) => (
            <Card style={{ marginTop: 20 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    {...actions.submitButtonProps}
                    onClick={actions.submit || actions.submit}
                  >
                    Confirmar
                  </Button>
                </Col>

                <Col span={24}>
                  <Link
                    to={`${ROUTES.COOPERATIVAS_DETAILS.replace(
                      ':id',
                      cooperativa.id.toString()
                    )}?seccion=rubricas`}
                  >
                    <Button style={{ width: '100%' }}>Cancelar</Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          )
        }}
        onValuesChange={(changedValues, values) => {
          setLocalStorageWithExpiry(`new_rubrica_formdata_${cooperativa.id}`, values);
        }}
      >
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ProCard
              headerBordered
              collapsible={false}
              title={
                <Space>
                  <SolutionOutlined />
                  <span>Datos generales</span>
                </Space>
              }
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <ProFormDatePicker
                    required
                    name="fecha_entrada"
                    label="Fecha de entrada"
                    initialValue={dayjs()}
                    fieldProps={DatePickerDefaultProps}
                    placeholder="Seleccione la fecha de entrada del rubrica"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDatePicker
                    name="fecha_entrega"
                    label="Fecha de entrega"
                    fieldProps={DatePickerDefaultProps}
                    placeholder="Seleccione la fecha de entrega del rubrica"
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDatePicker
                    required
                    name="fecha_rubrica"
                    label="Fecha de rubrica"
                    fieldProps={DatePickerDefaultProps}
                    placeholder="Seleccione la fecha de rubrica"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormSelect
                    showSearch
                    label="Libro"
                    name="rubrica_libros_id"
                    fieldProps={{
                      style: { width: '100% !important' }
                    }}
                    options={libros.map((libro) => ({
                      value: libro.id,
                      label: libro.nombre
                    }))}
                    placeholder="Seleccione un libro para el rubrica"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDigit
                    min={1}
                    name="numero_libro"
                    label="Número de libro"
                    placeholder="Ingrese el número de libro"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                <Col span={24} lg={12}>
                  <ProFormDigit
                    min={1}
                    name="cantidad_folios"
                    label="Cantidad de folios"
                    placeholder="Ingrese el número de libro"
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col>

                {/* <Col span={24}>
                  <ProFormDigitRange
                    name="hojas"
                    label="Hojas"
                    placeholder={['Desde', 'Hasta']}
                    fieldProps={{
                      style: { width: '100% !important' }
                    }}
                    rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                  />
                </Col> */}

                <Col span={24}>
                  <ProFormItem label="Notas" name="notas">
                    <Quill />
                  </ProFormItem>
                </Col>

                <Col span={24}>
                  <ProFormItem label="Observaciones" name="observaciones">
                    <Quill />
                  </ProFormItem>
                </Col>
              </Row>
            </ProCard>
          </Col>
        </Row>
      </ProForm>
    </div>
  );
});

NewRubricaForm.displayName = 'NewRubricaForm';

export default NewRubricaForm;
