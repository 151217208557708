import { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { RubricasDetailsCard } from './details';
import { Link, useNavigate } from 'react-router-dom';
import { RubricaCommentsDetailsContainer } from './observations';
import { PageContainer, PageContainerProps } from '@ant-design/pro-layout';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

// Services
import { RubricaActionsMenu } from './actions';
import { RubricaNotasDetailsContainer } from './notas';

interface RubricaDetailsContainerProps {
  loading: boolean;
}

export const RubricaDetailsContainer: FC<RubricaDetailsContainerProps> = memo((props) => {
  const { loading } = props;

  const navigate = useNavigate();
  const rubrica = useAppSelector((state) => state.rubricaDetalle.rubrica!);
  const cooperativa = rubrica?.cooperativa;

  const headerConfig = {
    breadcrumb: {
      itemRender(route) {
        return <Link to={route.path!}>{route.breadcrumbName}</Link>;
      },
      items: [
        {
          path: ROUTES.COOPERATIVAS_LIST,
          breadcrumbName: 'Cooperativas'
        },
        {
          path: ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`),
          breadcrumbName: cooperativa?.id || '...'
        },
        {
          path:
            ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=rubricas',
          breadcrumbName: 'Rubricas'
        },
        {
          path: '',
          breadcrumbName: 'Detalles'
        }
      ]
    }
  } as PageContainerProps['header'];

  return (
    <PageContainer
      header={headerConfig}
      title="Detalles de la rúbrica"
      loading={loading || !rubrica}
      extra={<RubricaActionsMenu />}
      onBack={() => {
        navigate(
          ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=rubricas'
        );
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} xl={7}>
          <RubricasDetailsCard />
        </Col>

        <Col span={24} xl={17}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <RubricaNotasDetailsContainer />
            </Col>
            <Col span={24}>
              <RubricaCommentsDetailsContainer />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
});

RubricaDetailsContainer.displayName = 'RubricaDetailsContainer';
