import { FC, memo } from 'react';
import { Col, Row } from 'antd';
import { SubsidiosDetailsCard } from './details';
import { Link, useNavigate } from 'react-router-dom';
import { SubsidioCommentsDetailsContainer } from './observations';
import { PageContainer, PageContainerProps } from '@ant-design/pro-layout';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

// Services
import { SubsidioActionsMenu } from './actions';
import { SubsidioActuacionesDetailsContainer } from './actuaciones';

interface SubsidioDetailsContainerProps {
  loading: boolean;
}

export const SubsidioDetailsContainer: FC<SubsidioDetailsContainerProps> = memo((props) => {
  const { loading } = props;

  const navigate = useNavigate();
  const subsidio = useAppSelector((state) => state.subsidioDetalle.subsidio!);
  const cooperativa = subsidio?.cooperativa;

  const headerConfig = {
    breadcrumb: {
      itemRender(route) {
        return <Link to={route.path!}>{route.breadcrumbName}</Link>;
      },
      items: [
        {
          path: ROUTES.COOPERATIVAS_LIST,
          breadcrumbName: 'Cooperativas'
        },
        {
          path: ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`),
          breadcrumbName: cooperativa?.id || '...'
        },
        {
          path:
            ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=subsidios',
          breadcrumbName: 'Subsidios'
        },
        {
          path: '',
          breadcrumbName: 'Detalles'
        }
      ]
    }
  } as PageContainerProps['header'];

  return (
    <PageContainer
      header={headerConfig}
      title="Detalles del subsidio"
      loading={loading || !subsidio}
      extra={<SubsidioActionsMenu />}
      onBack={() => {
        navigate(
          ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=subsidios'
        );
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} xl={7}>
          <SubsidiosDetailsCard />
        </Col>

        <Col span={24} xl={17}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <SubsidioCommentsDetailsContainer />
            </Col>
            <Col span={24}>
              <SubsidioActuacionesDetailsContainer />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
});

SubsidioDetailsContainer.displayName = 'SubsidioDetailsContainer';
