import { Dropdown } from "antd";
import { DropDownProps } from "antd/es/dropdown";

export interface HeaderDropdownProps extends Omit<DropDownProps, "overlay"> {
  overlayClassName?: string;
  placement?:
    | "bottomLeft"
    | "bottomRight"
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomCenter";
}

const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ ...restProps }) => (
  <Dropdown {...restProps} />
);

export default HeaderDropdown;
