import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import type { FC } from 'react';
import type { Rubrica } from '../../../../../../interfaces/rubrica';
import { CooperativaShort } from '../../../../../../interfaces/cooperativa';
import dayjs from 'dayjs';
import { decodeHtmlCharCodes } from '../../../../../../utils/decodeHtml';
import { parseAddress } from '../../../../../../utils/parseAddress';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    minHeight: 200,
    border: '1px solid #000'
  },
  header: {
    marginBottom: 5
  },
  headerTitle: {
    textAlign: 'center',
    fontSize: 11,
    marginBottom: 13,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 600
  },
  item: {
    marginBottom: 5,
    flexDirection: 'row'
  },
  itemTitle: {
    fontSize: 9,
    marginBottom: 10,
    fontFamily: 'Helvetica-Bold'
  },
  itemValue: {
    fontSize: 9,
    marginLeft: 20,
    marginBottom: 10,
    maxWidth: '79%'
  }
});

// Create Document Component
export const ObleaRubricaPdf: FC<{
  size?: 'A4' | 'LETTER';
  rubricas: Rubrica[];
  cooperativa: CooperativaShort;
}> = (props) => {
  const { rubricas, cooperativa, size = 'A4' } = props;

  const legalAddress = cooperativa.direcciones.find((d) => d.tipo_direccion.nombre === 'Legal');

  return (
    <Document>
      <Page size={size}>
        {rubricas.map((rubrica, index) => (
          <View
            style={[styles.section, index === 0 ? {} : { marginTop: 110 }]}
            key={rubrica.id}
            wrap={false}
          >
            <View style={styles.header}>
              <Text style={styles.headerTitle}>
                DIRECCIÓN PROVINCIAL DE REGISTRO Y FISCALIZACIÓN DE COOPERATIVAS
              </Text>
              <Text style={styles.headerTitle}>
                DIRECCIÓN DE REGISTRO, ARCHIVO Y ASUNTOS LEGALES
              </Text>
              <Text style={styles.headerTitle}>
                DEPARTAMENTO DE REGISTRO Y ARCHIVO DE COOPERATIVAS
              </Text>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <View>
                <Text style={styles.itemTitle}>Número de Rúbrica:</Text>
                <Text style={styles.itemTitle}>Libro:</Text>
                <Text style={styles.itemTitle}>N° de Libro:</Text>
                <Text style={{ ...styles.itemTitle, minHeight: 40 }}>Pertenece A:</Text>
              </View>

              <View>
                <Text style={styles.itemValue}>{rubrica.id}</Text>
                <Text style={styles.itemValue}>{rubrica.rubrica_libro.nombre}</Text>
                <Text style={styles.itemValue}>{rubrica.numero_libro}</Text>
                <Text style={{ ...styles.itemValue, marginBottom: 25 }}>{cooperativa.nombre}</Text>
              </View>
            </View>

            <View style={{ marginTop: -15, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...styles.itemTitle, marginRight: 30 }}>N° de Registro:</Text>
                  <Text style={styles.itemValue}>{cooperativa.id}</Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...styles.itemTitle, marginRight: 23 }}>Domicilio Legal:</Text>
                  <Text style={styles.itemValue}>
                    {legalAddress ? parseAddress(legalAddress) : 'Sin especificar'}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...styles.itemTitle, marginRight: 52 }}>Localidad:</Text>
                  <Text style={styles.itemValue}>
                    {legalAddress?.localidad?.nombre || 'Sin especificar'}
                  </Text>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...styles.itemTitle, marginRight: 52 }}>Consta de:</Text>
                  <Text style={styles.itemValue}>{rubrica.cantidad_folios} Folios</Text>
                </View>
              </View>

              <View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={styles.itemTitle}>Matrícula Nacional:</Text>
                  <Text style={styles.itemValue}>{cooperativa.nro_matricula}</Text>
                </View>
                <View>
                  <Text style={styles.itemValue}> </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...styles.itemTitle }}>Partido:</Text>
                  <Text style={styles.itemValue}>
                    {legalAddress?.localidad?.partido?.nombre || 'Sin especificar'}
                  </Text>
                </View>
              </View>
              <View></View>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Text style={{ ...styles.itemTitle, marginRight: 20 }}>Observaciones:</Text>
              <Text style={styles.itemValue}>
                {decodeHtmlCharCodes(rubrica.observaciones || '-')}
              </Text>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Text style={{ ...styles.itemValue, marginRight: 5, marginBottom: 0 }}>
                La Plata, {dayjs(rubrica.fecha_rubrica).format('DD/MM/YYYY')}
              </Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
};
