import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as tiposDatosRequeridosService from '../../services/tiposDatosRequeridos.service';

import type { RootState } from '../store';
import { orderBy } from 'lodash';

type Entity = Awaited<
  ReturnType<typeof tiposDatosRequeridosService.getTiposDatosRequeridos>
>[number];

export const fetchTiposDatosRequeridos = createAsyncThunk<Entity[]>(
  'TiposDatosRequeridos/fetch',
  (_params, api) => {
    const { tiposDatosRequeridos } = api.getState() as RootState;
    if (tiposDatosRequeridos.entities.length) return Promise.resolve(tiposDatosRequeridos.entities);
    return tiposDatosRequeridosService.getTiposDatosRequeridos();
  }
);

interface TiposDatosRequeridosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: TiposDatosRequeridosState = { loading: false, entities: [] };

const tiposDatosRequeridosSlice = createSlice({
  name: 'tiposDatosRequeridos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTiposDatosRequeridos.fulfilled, (state, action) => {
      state.entities = orderBy(action.payload, 'order');
      state.loading = false;
    });

    builder.addCase(fetchTiposDatosRequeridos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTiposDatosRequeridos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const TiposDatosRequeridosActions = {
  ...tiposDatosRequeridosSlice.actions,
  fetchTiposDatosRequeridos
};

export default tiposDatosRequeridosSlice.reducer;
