/* eslint-disable @typescript-eslint/no-empty-interface */

import { memo } from 'react';
import { Avatar } from 'antd';
import ProList from '@ant-design/pro-list';
import {
  FileOutlined,
  GlobalOutlined,
  CalendarOutlined,
  FieldNumberOutlined
} from '@ant-design/icons';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { fetchAsyncSubsidio } from '../../../../../../store/slices/subsidioDetalle';

// Utils
import dayjs from 'dayjs';
import { EditSubsidioDetails } from '../../../../components/editSubsidio';

export const SubsidiosDetailsCard = memo(() => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user!);
  const subsidio = useAppSelector((state) => state.subsidioDetalle.subsidio!);

  const onRefresh = () => {
    dispatch(fetchAsyncSubsidio(subsidio.id));
  };

  const dataSource = [
    {
      label: 'Número de expediente',
      avatar: <Avatar icon={<FieldNumberOutlined />} />,
      value: subsidio?.expediente || 'Sin especificar'
    },
    {
      label: 'Número de resolución',
      avatar: <Avatar icon={<FileOutlined />} />,
      value: subsidio?.numero_resolucion || 'Sin especificar'
    },
    {
      label: 'Año',
      avatar: <Avatar icon={<CalendarOutlined />} />,
      value: subsidio?.anio || 'Sin especificar'
    },
    {
      label: 'Fecha de acreditación',
      avatar: <Avatar icon={<CalendarOutlined />} />,
      value: subsidio?.fecha_acreditacion
        ? dayjs(subsidio.fecha_acreditacion).format('DD/MM/YYYY')
        : 'Sin especificar'
    },
    {
      label: 'Estado',
      avatar: <Avatar icon={<GlobalOutlined />} />,
      value: subsidio?.estado_subsidio ? (
        <div>{subsidio?.estado_subsidio.nombre}</div>
      ) : (
        'Sin especificar'
      )
    }
  ];

  return (
    <ProList
      bordered
      dataSource={dataSource}
      cardProps={{
        title: 'Datos generales',
        bodyStyle: { paddingTop: 20 },
        extra:
          user && user.permissions.includes('edit_subsidios') ? (
            <EditSubsidioDetails onRefresh={onRefresh} subsidio={subsidio} />
          ) : null
      }}
      metas={{
        avatar: {},
        title: { dataIndex: 'label' },
        description: { dataIndex: 'value' }
      }}
    ></ProList>
  );
});

SubsidiosDetailsCard.displayName = 'SubsidiosDetailsCard';
