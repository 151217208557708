import axios from '../config/axios';

import type { TipoDireccion, TipoDireccionQueryParams } from '../interfaces/tipoDireccion';

const PATH = `tipos_direcciones`;

/**
 * @description Recupera desde la API los tipos de direcciones
 * @returns {Promise<TipoDireccion[]>} Listado de Tipos de Direcciones
 */
export const getTiposDirecciones = async (params: TipoDireccionQueryParams = {}) => {
  return await axios.get<{ data: TipoDireccion[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
