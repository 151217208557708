import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as LocalidadesService from '../../services/localidades.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof LocalidadesService.getLocalidades>>[number];

export const fetchLocalidades = createAsyncThunk<Entity[]>('localidades/fetch', (_params, api) => {
  const { localidades } = api.getState() as RootState;
  if (localidades.entities.length) return Promise.resolve(localidades.entities);
  return LocalidadesService.getLocalidades();
});

interface LocalidadesState {
  loading: boolean;
  entities: Entity[];
}

const initialState: LocalidadesState = { loading: false, entities: [] };

const localidadesSlice = createSlice({
  name: 'localidades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocalidades.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchLocalidades.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchLocalidades.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const localidadesActions = { ...localidadesSlice.actions, fetchLocalidades };

export default localidadesSlice.reducer;
