import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as PartidosService from '../../services/partidos.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof PartidosService.getPartidos>>[number];

export const fetchPartidos = createAsyncThunk<Entity[]>('partidos/fetch', (_params, api) => {
  const { partidos } = api.getState() as RootState;
  if (partidos.entities.length) return Promise.resolve(partidos.entities);
  return PartidosService.getPartidos();
});

interface PartidosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: PartidosState = { loading: false, entities: [] };

const partidosSlice = createSlice({
  name: 'partidos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPartidos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchPartidos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchPartidos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const partidosActions = { ...partidosSlice.actions, fetchPartidos };

export default partidosSlice.reducer;
