import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/api';
import type {
  CooperativaFacturacion,
  NewFacturacionFormValues,
  CooperativaFacturacionQueryParams,
  CooperativaFacturacionDetalle
} from '../interfaces/facturacion';

/**************************************************
 *
 *            Facturaciones
 *
 *************************************************/

const FACTURACIONES_PATH = `facturaciones`;

/**
 * @description Recupera desde la API los facturaciones de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<CooperativaFacturacion[]>} Facturaciones
 */
export const getFacturaciones = async (params: CooperativaFacturacionQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<CooperativaFacturacion>>(FACTURACIONES_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un facturacion de una cooperativa
 * @param {number | string} id Identificador de la cooperativa
 * @returns {Promise<CooperativaFacturacion>} Facturacion
 */
export const getFacturacion = async (id: number | string) => {
  return await axios
    .get<{ data: CooperativaFacturacion }>(`${FACTURACIONES_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un facturacion de una cooperativa
 * @returns {Promise<CooperativaFacturacion>} Facturacion
 */
export const newFacturacion = async (data: NewFacturacionFormValues) => {
  return await axios
    .post<{ data: CooperativaFacturacion }>(FACTURACIONES_PATH, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un facturacion de una cooperativa
 * @param {number | string} id Identificador de la facturación
 * @returns {Promise<CooperativaFacturacion>} Facturacion
 */
export const updateFacturacion = async (
  id: number | string,
  data: Partial<NewFacturacionFormValues>
) => {
  return await axios
    .put<{ data: CooperativaFacturacion }>(`${FACTURACIONES_PATH}/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un facturacion de una cooperativa
 * @param {number | string} id Identificador de la facturacion
 * @returns {Promise<CooperativaFacturacion>} Facturacion
 */
export const deleteFacturacion = async (id: number | string) => {
  return await axios.delete<void>(`${FACTURACIONES_PATH}/${id}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Facturaciones detalles
 *
 *************************************************/

/**
 * @description Recupera desde la API los detalles de una facturacion
 * @param {number | string} id Identificador de la facturacion
 * @returns {Promise<CooperativaFacturacionDetalle[]>} Facturacion
 * @deprecated
 */
export const getFacturacionDetalles = async (id: number | string) => {
  return await axios
    .get<{ data: CooperativaFacturacionDetalle[] }>(`${FACTURACIONES_PATH}/${id}/detalles`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API los detalles de una facturacion
 * @param {number | string} id_facturacion Identificador de la factuacion
 * @param {number | string} id Identificador de la facturacion
 * @returns {Promise<CooperativaFacturacionDetalle[]>} Facturacion
 */
export const getFacturacionDetalle = async (
  id_facturacion: number | string,
  id: number | string
) => {
  return await axios
    .get<{ data: CooperativaFacturacionDetalle }>(
      `${FACTURACIONES_PATH}/${id_facturacion}/detalles/${id}`
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un detalle de una facturacion
 * @param {number | string} id_facturacion Identificador de la factuacion
 * @returns {Promise<CooperativaFacturacionDetalle>} Facturacion
 */
export const newFacturacionDetalle = async (
  id_facturacion: number | string,
  data: Partial<CooperativaFacturacionDetalle>
) => {
  return await axios
    .post<{ data: CooperativaFacturacionDetalle }>(
      `${FACTURACIONES_PATH}/${id_facturacion}/detalles`,
      data
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API un detalle de una facturacion
 * @param {number | string} id_facturacion Identificador de la factuacion
 * @param {number | string} id Identificador del detalle
 * @returns {Promise<CooperativaFacturacionDetalle>} Facturacion
 */
export const updateFacturacionDetalle = async (
  id_facturacion: number | string,
  id: number | string,
  data: Partial<CooperativaFacturacionDetalle>
) => {
  return await axios
    .put<{ data: CooperativaFacturacionDetalle }>(
      `${FACTURACIONES_PATH}/${id_facturacion}/detalles/${id}`,
      data
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un detalle de una facturacion
 * @param {number | string} id_facturacion Identificador de la factuacion
 * @param {number | string} id Identificador del detalle
 * @returns {Promise<void>} Facturacion
 */
export const deleteFacturacionDetalle = async (
  id_facturacion: number | string,
  id: number | string
) => {
  return await axios
    .delete<void>(`${FACTURACIONES_PATH}/${id_facturacion}/detalles/${id}`)
    .then((response) => {
      return response.data;
    });
};
