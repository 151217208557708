import { memo, useEffect } from 'react';

// Components
import NewCooperativaRubricaContainer from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchRubricasLibros } from '../../../../store/slices/rubricasLibros';
import { fetchCooperativa } from '../../../../store/slices/cooperativaDetalle';

const LOADING_SLICES = ['rubricasLibros'] as const;

export const NewCooperativaRubricaPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id) {
      dispatch(fetchRubricasLibros());
      dispatch(fetchCooperativa(params.id));
    }
  }, [dispatch, params.id]);

  return <NewCooperativaRubricaContainer loading={loading} />;
});

NewCooperativaRubricaPage.displayName = 'NewCooperativaRubricaPage';

export default NewCooperativaRubricaPage;
