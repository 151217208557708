import { memo, useEffect } from 'react';

// Components
import NewCooperativaSubsidioContainer from './components/container';

// Utils
import { useParams } from 'react-router-dom';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import { fetchCooperativa } from '../../../../store/slices/cooperativaDetalle';
import { fetchEstadosSubsidios } from '../../../../store/slices/estadosSubsidios';

const LOADING_SLICES = ['estadosSubsidios'] as const;

export const NewCooperativaSubsidioPage = memo(() => {
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();

  const loading = useAppSelector((state) => LOADING_SLICES.some((slice) => state[slice].loading));

  useEffect(() => {
    if (params.id) {
      dispatch(fetchEstadosSubsidios());
      dispatch(fetchCooperativa(params.id));
    }
  }, [dispatch, params.id]);

  return <NewCooperativaSubsidioContainer loading={loading} />;
});

NewCooperativaSubsidioPage.displayName = 'NewCooperativaSubsidioPage';

export default NewCooperativaSubsidioPage;
