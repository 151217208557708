export const formatCuit = (cuit: string): string => {
  const cuitRegex = /^(\d{2})(\d{8})(\d{1})$/;
  const cuitMatch = cuitRegex.exec(cuit);

  if (!cuitMatch) {
    return cuit;
  }

  const [, prefix, body, suffix] = cuitMatch;

  return `${prefix}-${body}-${suffix}`;
};
