import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as EstadosCivilesService from '../../services/estadosCiviles.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof EstadosCivilesService.getEstadosCiviles>>[number];

export const fetchEstadosCiviles = createAsyncThunk<Entity[]>(
  'estadosCiviles/fetch',
  (_params, api) => {
    const { estadosCiviles } = api.getState() as RootState;
    if (estadosCiviles.entities.length) return Promise.resolve(estadosCiviles.entities);
    return EstadosCivilesService.getEstadosCiviles();
  }
);

interface EstadosCooperativasState {
  loading: boolean;
  entities: Entity[];
}

const initialState: EstadosCooperativasState = { loading: false, entities: [] };

const estadosCivilesSlice = createSlice({
  name: 'estados_civiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEstadosCiviles.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchEstadosCiviles.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchEstadosCiviles.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const estadosCivilesActions = { ...estadosCivilesSlice.actions, fetchEstadosCiviles };

export default estadosCivilesSlice.reducer;
