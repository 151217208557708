import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as DocumentacionesAvalService from '../../services/documentacionesAval.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof DocumentacionesAvalService.getDocumentacionesAval>>[number];

export const fetchDocumentacionesAval = createAsyncThunk<Entity[]>(
  'documentacionesAval/fetch',
  (_params, api) => {
    const { documentacionesAval } = api.getState() as RootState;
    if (documentacionesAval.entities.length) return Promise.resolve(documentacionesAval.entities);
    return DocumentacionesAvalService.getDocumentacionesAval();
  }
);

interface DocumentacionAvalState {
  loading: boolean;
  entities: Entity[];
}

const initialState: DocumentacionAvalState = { loading: false, entities: [] };

const documentacionesAvalSlice = createSlice({
  name: 'documentaciones_aval',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDocumentacionesAval.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchDocumentacionesAval.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchDocumentacionesAval.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const documentacionesAvalActions = {
  ...documentacionesAvalSlice.actions,
  fetchDocumentacionesAval
};

export default documentacionesAvalSlice.reducer;
