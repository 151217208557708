import { memo } from 'react';
import StatusComponent from './StatusComponent';

const Pagina404: React.FC = memo(() => {
  return (
    <StatusComponent
      status="404"
      title="Oops 404!"
      // headerTitle="Error 404"
      subTitle="Parece que no encontramos lo que estabas buscando."
    />
  );
});

Pagina404.displayName = 'Pagina404';

export default Pagina404;
