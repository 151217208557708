import axios from '../config/axios';

import type { PaginatedResponse } from '../interfaces/api';
import type {
  Asamblea,
  AsambleaData,
  AsambleaDetallada,
  AsambleaQueryParams,
  AsambleaNewFormValues,
  AsambleaDataNewFormValues,
  DatoRequerido,
  DatoRequeridoNewFormValues
} from '../interfaces/asamblea';

/**************************************************
 *
 *            Asambleas
 *
 *************************************************/

const ASAMBLEAS_PATH = 'asambleas';

/**
 * @description Recupera desde la API las asambleas de una cooperativa
 * @returns {Promise<PaginatedResponse<Asamblea>>} Asambleas
 */
export const getAsambleas = async (params: AsambleaQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Asamblea>>(ASAMBLEAS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API una asamblea de una cooperativa
 * @returns {Promise<Asamblea>} Asamblea
 */
export const getAsamblea = async (asambleaId: number | string) => {
  return await axios
    .get<{ data: AsambleaDetallada }>(`${ASAMBLEAS_PATH}/${asambleaId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Crea una asamblea
 * @returns {Promise<Asamblea>} Asamblea
 */
export const newAsamblea = async (data: AsambleaNewFormValues) => {
  return await axios.post<{ data: Asamblea }>(ASAMBLEAS_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza una asamblea
 * @returns {Promise<Asamblea>} Asamblea
 */
export const updateAsamblea = async (asambleaId: number | string, data: AsambleaNewFormValues) => {
  return await axios
    .put<{ data: Asamblea }>(`${ASAMBLEAS_PATH}/${asambleaId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina una asamblea
 * @returns {Promise<void>} Asamblea
 */
export const deleteAsamblea = async (asambleaId: number | string) => {
  return await axios.delete<void>(`${ASAMBLEAS_PATH}/${asambleaId}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Asamblea Dato
 *
 *************************************************/

const ASAMBLEA_DATOS_PATH = 'dato_asamblea';

/**
 * @description Recupera desde la API los datos de una asamblea
 * @returns {Promise<AsambleaData>} Asamblea
 */
export const getAsambleaDatas = async (params = {}) => {
  return await axios
    .get<PaginatedResponse<AsambleaData>>(ASAMBLEA_DATOS_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API una asamblea
 * @returns {Promise<AsambleaData>} Asamblea
 */
export const getAsambleaData = async (asambleaDataId: number | string) => {
  return await axios
    .get<{ data: AsambleaData }>(`${ASAMBLEA_DATOS_PATH}/${asambleaDataId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Crea una asamblea
 * @returns {Promise<AsambleaData>} Asamblea
 */
export const newAsambleaData = async (data: AsambleaDataNewFormValues) => {
  return await axios
    .post<{ data: AsambleaData }>(`${ASAMBLEA_DATOS_PATH}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza una asamblea data
 * @returns {Promise<AsambleaData>} Asamblea
 */
export const updateAsambleaData = async (
  asambleaDataId: number | string,
  data: AsambleaDataNewFormValues
) => {
  return await axios
    .put<{ data: AsambleaData }>(`${ASAMBLEA_DATOS_PATH}/${asambleaDataId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina una asamblea
 * @returns {Promise<void>} Asamblea
 */
export const deleteAsambleaData = async (asambleaDataId: number | string) => {
  return await axios.delete<void>(`${ASAMBLEA_DATOS_PATH}/${asambleaDataId}`).then((response) => {
    return response.data;
  });
};

/**************************************************
 *
 *            Tipos de datos requeridos
 *
 *************************************************/

const ASAMBLEA_DATOS_REQUERIDOS_PATH = 'asamblea_dato_requerido';

/**
 * @description Recupera desde la API los tipos de datos requeridos
 * @returns {Promise<DatoRequerido>} Asamblea
 */
export const getAsambleaRequiredDataTypes = async () => {
  return await axios
    .get<PaginatedResponse<DatoRequerido>>(ASAMBLEA_DATOS_REQUERIDOS_PATH)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Recupera desde la API los tipos de datos requeridos
 * @param {number | string} datoRequeridoId
 * @returns {Promise<DatoRequerido>} Asamblea
 */
export const getAsambleaRequiredDataType = async (datoRequeridoId: number | string) => {
  return await axios
    .get<{ data: DatoRequerido }>(`${ASAMBLEA_DATOS_REQUERIDOS_PATH}/${datoRequeridoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Crea un tipo de dato requerido
 * @returns {Promise<DatoRequerido>} Asamblea
 */
export const newAsambleaRequiredDataType = async (data: DatoRequeridoNewFormValues) => {
  return await axios
    .post<{ data: DatoRequerido }>(ASAMBLEA_DATOS_REQUERIDOS_PATH, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza un tipo de dato requerido
 * @returns {Promise<DatoRequerido>} Asamblea
 */
export const updateAsambleaRequiredDataType = async (
  datoRequeridoId: number | string,
  data: DatoRequeridoNewFormValues
) => {
  return await axios
    .put<{ data: DatoRequerido }>(`${ASAMBLEA_DATOS_REQUERIDOS_PATH}/${datoRequeridoId}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina un tipo de dato requerido
 * @returns {Promise<void>} Asamblea
 */
export const deleteAsambleaRequiredDataType = async (datoRequeridoId: number | string) => {
  return await axios
    .delete<void>(`${ASAMBLEA_DATOS_REQUERIDOS_PATH}/${datoRequeridoId}`)
    .then((response) => {
      return response.data;
    });
};
