import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as OrigenesFechaEntradaService from '../../services/origenesFechaEntrada.service';

import type { RootState } from '../store';

type Entity = Awaited<
  ReturnType<typeof OrigenesFechaEntradaService.getOrigenesFechaEntrada>
>[number];

export const fetchOrigenesFechaEntrada = createAsyncThunk<Entity[]>(
  'origenesFechaEntrada/fetch',
  (_params, api) => {
    const { origenesFechaEntrada } = api.getState() as RootState;
    if (origenesFechaEntrada.entities.length) return Promise.resolve(origenesFechaEntrada.entities);
    return OrigenesFechaEntradaService.getOrigenesFechaEntrada();
  }
);

interface OrigenesFechaEntradaState {
  loading: boolean;
  entities: Entity[];
}

const initialState: OrigenesFechaEntradaState = { loading: false, entities: [] };

const origenesFechaEntradaSlice = createSlice({
  name: 'origenesFechaEntrada',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrigenesFechaEntrada.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchOrigenesFechaEntrada.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchOrigenesFechaEntrada.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const origenesFechaEntradaActions = {
  ...origenesFechaEntradaSlice.actions,
  fetchOrigenesFechaEntrada
};

export default origenesFechaEntradaSlice.reducer;
