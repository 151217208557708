import { Col, Row } from 'antd';
import { EmpresaMenu } from './menu';
import { FC, memo, useState } from 'react';
import { EmpresaDetailsCard } from './details';
import { Link, useNavigate } from 'react-router-dom';
import { PageContainer, PageContainerProps } from '@ant-design/pro-layout';

// Redux
import { useAppSelector } from '../../../../../store/store';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

// Services
import { EmpresaActionsMenu } from './actions';
import { EmpresaDetailsActivities } from './activities';
import { EmpresaDetailsLaws } from './laws';

interface EmpresaDetailsContainerProps {
  loading: boolean;
}

export const EmpresaDetailsContainer: FC<EmpresaDetailsContainerProps> = memo((props) => {
  const { loading } = props;

  const navigate = useNavigate();
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);
  const cooperativa = empresa?.cooperativa;

  const headerConfig = {
    breadcrumb: {
      itemRender(route) {
        return <Link to={route.path!}>{route.breadcrumbName}</Link>;
      },
      items: [
        {
          path: ROUTES.COOPERATIVAS_LIST,
          breadcrumbName: 'Cooperativas'
        },
        {
          path: ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`),
          breadcrumbName: cooperativa?.id || '...'
        },
        {
          path:
            ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=empresas',
          breadcrumbName: 'Empresas anteriores'
        },
        {
          path: '',
          breadcrumbName: 'Detalles'
        }
      ]
    }
  } as PageContainerProps['header'];

  const [tab, setTab] = useState('1');

  return (
    <PageContainer
      header={headerConfig}
      loading={loading || !empresa}
      extra={<EmpresaActionsMenu />}
      title="Detalles de la empresa recuperada"
      onBack={() => {
        navigate(
          ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) + '?seccion=empresas'
        );
      }}
    >
      <Row gutter={[16, 16]}>
        <Col span={24} xl={6}>
          <EmpresaMenu tab={tab} setTab={setTab} />
        </Col>

        <Col span={24} xl={18}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {tab === '1' ? <EmpresaDetailsCard /> : null}
              {tab === '2' ? <EmpresaDetailsActivities /> : null}
              {tab === '3' ? <EmpresaDetailsLaws /> : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
});

EmpresaDetailsContainer.displayName = 'EmpresaDetailsContainer';
