/* eslint-disable @typescript-eslint/no-empty-interface */

import { memo } from 'react';
import { Avatar, Card, Col, List, Row } from 'antd';
import {
  FileOutlined,
  GlobalOutlined,
  CalendarOutlined,
  FieldNumberOutlined
} from '@ant-design/icons';

// Redux
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';

// Utils
import dayjs from 'dayjs';
import ProCard from '@ant-design/pro-card';
import { fetchAsyncEmpresa } from '../../../../../../store/slices/empresaDetalle';

import { EditEmpresaInversa } from '../../../../components/editEmpresaInversa';
import { EditEmpresaConvenio } from '../../../../components/editEmpresaConvenio';
import { EditEmpresaGeneralData } from '../../../../components/editEmpresaGeneralData';
import { EditEmpresaPRAER } from '../../../../components/editEmpresaPRAER';

interface DataSourceItem {
  label: string;
  value: React.ReactNode;
  avatar: React.ReactNode;
}

const ListComponent = (props: { dataSource: DataSourceItem[] }) => {
  return (
    <List<DataSourceItem>
      split
      {...props}
      grid={{ gutter: 16, column: 1, md: 2, lg: 3, xl: 3, xxl: 3 }}
      renderItem={(item) => (
        <List.Item>
          <Card>
            <Card.Meta title={item.label} avatar={item.avatar} description={item.value}></Card.Meta>
          </Card>
        </List.Item>
      )}
    ></List>
  );
};

export const EmpresaDetailsCard = memo(() => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user!);
  const empresa = useAppSelector((state) => state.empresaDetalle.empresa!);

  const onRefresh = () => {
    dispatch(fetchAsyncEmpresa(empresa.id));
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ProCard
          collapsible
          title="Datos generales"
          bodyStyle={{ marginTop: 20 }}
          extra={
            user && user.permissions.includes('edit_empresas_anteriores') ? (
              <EditEmpresaGeneralData onRefresh={onRefresh} empresa={empresa} />
            ) : null
          }
        >
          <ListComponent
            dataSource={[
              {
                label: 'Reempadrono INAES',
                value: empresa.reempadrono_inaes ? 'Sí' : 'No',
                avatar: <Avatar icon={<FieldNumberOutlined />} />
              },
              {
                label: 'Sentencia firme',
                avatar: <Avatar icon={<FileOutlined />} />,
                value: empresa.sentencia_firme ? 'Sí' : 'No'
              },
              {
                label: 'Situación dominial de los bienes',
                avatar: <Avatar icon={<CalendarOutlined />} />,
                value: empresa.situacion_dominial_bienes || 'Sin informar'
              },
              {
                label: 'Liquidación judicial',
                avatar: <Avatar icon={<CalendarOutlined />} />,
                value: empresa.liquidacion_judicial || 'Sin informar'
              },
              {
                label: 'Tasación fiscalía de estado',
                avatar: <Avatar icon={<GlobalOutlined />} />,
                value: empresa.tasacion_fiscalia_estado || 'Sin informar'
              }
            ]}
          />
        </ProCard>
      </Col>

      <Col span={24}>
        <ProCard
          defaultCollapsed
          collapsible
          title="Inversa"
          bodyStyle={{ marginTop: 20 }}
          extra={
            user && user.permissions.includes('edit_empresas_anteriores') ? (
              <EditEmpresaInversa onRefresh={onRefresh} empresa={empresa} />
            ) : null
          }
        >
          <ListComponent
            dataSource={[
              {
                label: 'Posee',
                avatar: <Avatar icon={<GlobalOutlined />} />,
                value: empresa.inversa ? 'Sí' : 'No'
              },
              {
                label: 'Expediente',
                avatar: <Avatar icon={<FileOutlined />} />,
                value: empresa.expediente_inversa || 'No posee'
              }
            ]}
          />
        </ProCard>
      </Col>

      <Col span={24}>
        <ProCard
          defaultCollapsed
          collapsible
          title="Convenio"
          bodyStyle={{ marginTop: 20 }}
          extra={
            user && user.permissions.includes('edit_empresas_anteriores') ? (
              <EditEmpresaConvenio onRefresh={onRefresh} empresa={empresa} />
            ) : null
          }
        >
          <ListComponent
            dataSource={[
              {
                label: 'Posee',
                avatar: <Avatar icon={<GlobalOutlined />} />,
                value: empresa.convenio ? 'Sí' : 'No'
              },
              {
                label: 'Expediente',
                avatar: <Avatar icon={<FileOutlined />} />,
                value: empresa.nro_exp_convenio || 'No posee'
              }
            ]}
          />
        </ProCard>
      </Col>

      <Col span={24}>
        <ProCard
          collapsible
          defaultCollapsed
          title="PRAER"
          bodyStyle={{ marginTop: 20 }}
          extra={
            user && user.permissions.includes('edit_empresas_anteriores') ? (
              <EditEmpresaPRAER onRefresh={onRefresh} empresa={empresa} />
            ) : null
          }
        >
          <ListComponent
            dataSource={[
              {
                label: 'Posee solicitud de inscripción',
                avatar: <Avatar icon={<GlobalOutlined />} />,
                value: empresa.solicitud_inscripcion_praer ? 'Sí' : 'No'
              },
              {
                label: 'Número de registro',
                avatar: <Avatar icon={<FileOutlined />} />,
                value: empresa.nro_registro_praer || 'No posee'
              },
              {
                label: 'Número de disposición de inscripción',
                avatar: <Avatar icon={<FileOutlined />} />,
                value: empresa.nro_disposicion_praer || 'No posee'
              },
              {
                label: 'Fecha de disposición de inscripción',
                avatar: <Avatar icon={<CalendarOutlined />} />,
                value: empresa.fecha_nro_disposicion_praer
                  ? dayjs(empresa.fecha_nro_disposicion_praer).format('DD/MM/YYYY')
                  : 'No posee'
              }
            ]}
          />
        </ProCard>
      </Col>
    </Row>
  );
});

EmpresaDetailsCard.displayName = 'EmpresaDetailsCard';
