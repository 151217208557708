/* eslint-disable no-useless-catch */
import axios from '../config/axios';
import { API_URL } from '../config';

import type { IUser } from '../interfaces/user';

/**
 * @description Recupera desde la API el usuario actual
 * @throws {Error} Si el usuario no está autenticado
 * @returns {Promise<IUser>} Usuario actual
 */
const getCurrentUser = async () => {
  return await axios.get<{ data: IUser }>(`${API_URL}/me`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Cambia la contraseña del usuario actual
 * @param {Object} data Objeto con las contraseñas
 * @param {string} data.old_password Contraseña actual
 * @param {string} data.new_password Nueva contraseña
 * @throws {Error} Si la contraseña actual no es correcta
 * @returns {Promise<void>}
 */
const changePassword = async (data: { old_password: string; new_password: string }) => {
  return await axios.patch(`${API_URL}/me/password`, data).then((response) => {
    return response.data;
  });
};

const MeService = {
  getCurrentUser,
  changePassword
};

export default MeService;
