// Components
import { EditOutlined } from '@ant-design/icons';
import CustomFromDrawer from '../../../components/drawer/form';
import { ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Redux
import { updateEmpresaAnterior } from '../../../services/empresaAnterior.service';

// Interfaces
import { memo, type FC } from 'react';
import type {
  EmpresaAnterior,
  EmpresaAnteriorNewFormValues
} from '../../../interfaces/empresaAnterior';

interface EditEmpresaGeneralDataProps {
  empresa: EmpresaAnterior;
  onRefresh?: () => void;
}

export const EditEmpresaGeneralData: FC<EditEmpresaGeneralDataProps> = memo((props) => {
  const { empresa } = props;
  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  return (
    <CustomFromDrawer<EmpresaAnteriorNewFormValues>
      maxWidth={700}
      title="Editar datos generales"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        ...empresa
      }}
      onFinish={async (values) => {
        return updateEmpresaAnterior(empresa.id, values)
          .then(() => {
            message.success('Empresa actualizada correctamente');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos de la empresa, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos de la empresa'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ProFormSelect
            name="reempadrono_inaes"
            placeholder="Seleccione una opción"
            label="Reempadrono INAES"
            options={[
              { label: 'Sí', value: 1 },
              { label: 'No', value: 0 }
            ]}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24}>
          <ProFormSelect
            name="sentencia_firme"
            label="Sentencia firme"
            placeholder="Seleccione una opción"
            options={[
              { label: 'Sí', value: 1 },
              { label: 'No', value: 0 }
            ]}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24}>
          <ProFormText
            name="situacion_dominial_bienes"
            label="Situación dominial de los bienes"
            placeholder="Ingrese la situación dominial de los bienes"
            rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
          />
        </Col>

        <Col span={24}>
          <ProFormText
            name="liquidacion_judicial"
            label="Liquidación judicial"
            placeholder="Ingrese la liquidación judicial"
            rules={[{ max: 45, message: 'Este campo no puede tener más de 45 caracteres' }]}
          />
        </Col>

        <Col span={24}>
          <ProFormText
            name="tasacion_fiscalia_estado"
            label="Tasación fiscalía de estado"
            placeholder="Ingrese la tasación fiscalía de estado"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              { max: 45, message: 'Este campo no puede tener más de 45 caracteres' }
            ]}
          />
        </Col>
      </Row>
    </CustomFromDrawer>
  );
});

EditEmpresaGeneralData.displayName = 'EditEmpresaGeneralData';
