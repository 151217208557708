import { memo } from 'react';
import StatusComponent from './StatusComponent';

const Pagina403: React.FC = memo(() => {
  return (
    <StatusComponent
      status="403"
      title="Oops 403!"
      subTitle="Parece que no tienes permisos para ver esta página."
    />
  );
});

Pagina403.displayName = 'Pagina403';

export default Pagina403;
