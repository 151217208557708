import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as SexosService from '../../services/sexos.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof SexosService.getSexos>>[number];

export const fetchSexos = createAsyncThunk<Entity[]>('sexos/fetch', (_params, api) => {
  const { sexos } = api.getState() as RootState;
  if (sexos.entities.length) return Promise.resolve(sexos.entities);
  return SexosService.getSexos();
});

interface SexosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: SexosState = { loading: false, entities: [] };

const sexosSlice = createSlice({
  name: 'sexos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSexos.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchSexos.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSexos.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const sexosActions = { ...sexosSlice.actions, fetchSexos };

export default sexosSlice.reducer;
