import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Services
import * as ConsejoService from '../../services/consejos.service';
import * as RubricasService from '../../services/rubricas.service';
import * as AsambleasService from '../../services/asambleas.service';
import * as ExpedientesService from '../../services/expedientes.service';
import * as CooperativasService from '../../services/cooperativas.service';

type EntityActividadNativa = Awaited<
  ReturnType<typeof CooperativasService.getCooperativaActividadesNativas>
>;
type Entity = Awaited<ReturnType<typeof CooperativasService.getCooperativa>>;
type EntityRubrica = Awaited<ReturnType<typeof RubricasService.getRubricas>>;
type EntityAsamblea = Awaited<ReturnType<typeof AsambleasService.getAsambleas>>;
type EntityExpediente = Awaited<ReturnType<typeof ExpedientesService.getExpedientes>>;
type EntityActividad = Awaited<ReturnType<typeof CooperativasService.getCooperativaActividades>>;
type EntityContactos = Awaited<ReturnType<typeof CooperativasService.getCooperativaDatosContacto>>;
type EntityDirecciones = Awaited<ReturnType<typeof CooperativasService.getCooperativaDirecciones>>;
type EntityConsejo = Awaited<ReturnType<typeof ConsejoService.getConsejos>>;

export const fetchCooperativa = createAsyncThunk<Entity, number | string>(
  'cooperativaDetalle/fetch',
  (id) => {
    return CooperativasService.getCooperativa(id);
  }
);

export const fetchAsyncCooperativa = createAsyncThunk<Entity, number | string>(
  'cooperativaDetalle/fetchAsync',
  (id) => {
    return CooperativasService.getCooperativa(id);
  }
);

export const fetchAsyncActividades = createAsyncThunk<EntityActividad, number>(
  'cooperativaDetalle/fetchAsyncActividades',
  (id) => {
    return CooperativasService.getCooperativaActividades({
      cooperativas_id: id,
      perPage: 100
    });
  }
);

export const fetchAsyncExpedientes = createAsyncThunk<EntityExpediente, number>(
  'cooperativaDetalle/fetchAsyncExpedientes',
  (id) => {
    return ExpedientesService.getExpedientes({
      cooperativas_id: id,
      perPage: 100
    });
  }
);

export const fetchAsyncRubricas = createAsyncThunk<EntityRubrica, number>(
  'cooperativaDetalle/fetchAsyncRubricas',
  (id) => {
    return RubricasService.getRubricas({
      cooperativas_id: id,
      perPage: 100
    });
  }
);

export const fetchAsyncAsambleas = createAsyncThunk<EntityAsamblea, number>(
  'cooperativaDetalle/fetchAsyncAsambleas',
  (id) => {
    return AsambleasService.getAsambleas({
      cooperativas_id: id
    });
  }
);

export const fetchAsyncActividadesNativas = createAsyncThunk<EntityActividadNativa, number>(
  'cooperativaDetalle/fetchAsyncActividadesNativas',
  (id) => {
    return CooperativasService.getCooperativaActividadesNativas({
      cooperativas_id: id
    });
  }
);

export const fetchAsyncContactos = createAsyncThunk<EntityContactos, number>(
  'cooperativaDetalle/fetchAsyncContactos',
  (id) => {
    return CooperativasService.getCooperativaDatosContacto(id);
  }
);

export const fetchAsyncDirecciones = createAsyncThunk<EntityDirecciones, number>(
  'cooperativaDetalle/fetchAsyncDirecciones',
  (id) => {
    return CooperativasService.getCooperativaDirecciones(id);
  }
);

export const fetchAsyncConsejos = createAsyncThunk<EntityConsejo, number>(
  'cooperativaDetalle/fetchAsyncConsejos',
  (id) => {
    return ConsejoService.getConsejos(id, { perPage: 1 });
  }
);

interface CooperativaDetalleState {
  loading: boolean;
  cooperativa: Entity | null;

  contactos: EntityContactos | [];
  direcciones: EntityDirecciones | [];
  consejos: EntityConsejo['data'] | [];
  rubricas: EntityRubrica['data'] | [];
  asambleas: EntityAsamblea['data'] | [];
  actividades: EntityActividad['data'] | [];
  expedientes: EntityExpediente['data'] | [];
  actividadesNativas: EntityActividadNativa['data'] | [];
}

const initialState: CooperativaDetalleState = {
  loading: false,
  cooperativa: null,

  consejos: [],
  rubricas: [],
  asambleas: [],
  contactos: [],
  actividades: [],
  expedientes: [],
  direcciones: [],
  actividadesNativas: []
};

const CooperativaDetalleSlice = createSlice({
  name: 'cooperativa_detalle',
  initialState,
  reducers: {
    setConsejos: (state, action) => {
      state.consejos = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCooperativa.fulfilled, (state, action) => {
      state.cooperativa = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchCooperativa.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchCooperativa.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncCooperativa.fulfilled, (state, action) => {
      state.cooperativa = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsyncActividades.fulfilled, (state, action) => {
      state.actividades = action.payload.data;
      state.loading = false;
    });

    builder.addCase(fetchAsyncExpedientes.fulfilled, (state, action) => {
      state.expedientes = action.payload.data;
      state.loading = false;
    });

    builder.addCase(fetchAsyncRubricas.fulfilled, (state, action) => {
      state.rubricas = action.payload.data;
      state.loading = false;
    });

    builder.addCase(fetchAsyncAsambleas.fulfilled, (state, action) => {
      state.asambleas = action.payload.data;
      state.loading = false;
    });

    builder.addCase(fetchAsyncActividadesNativas.fulfilled, (state, action) => {
      state.actividadesNativas = action.payload.data;
      state.loading = false;
    });

    builder.addCase(fetchAsyncContactos.fulfilled, (state, action) => {
      state.contactos = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsyncDirecciones.fulfilled, (state, action) => {
      state.direcciones = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchAsyncConsejos.fulfilled, (state, action) => {
      state.consejos = action.payload.data;
      state.loading = false;
    });
  }
});

export const CooperativaDetalleActions = { fetchCooperativa, ...CooperativaDetalleSlice.actions };

export default CooperativaDetalleSlice.reducer;
