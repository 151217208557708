import { FC, memo } from 'react';

// Components
import NewSubsidioForm from './form';
import { Link } from 'react-router-dom';
import { PageContainer, PageContainerProps } from '@ant-design/pro-layout';

// Constants
import { ROUTES } from '../../../../../constants/routes-constants';

// Redux
import { useAppSelector } from '../../../../../store/store';
import { formatCuit } from '../../../../../utils/formatCuit';

// Constants

interface NewCooperativaRubricaContainerProps {
  loading: boolean;
}

export const NewCooperativaRubricaContainer: FC<NewCooperativaRubricaContainerProps> = memo(
  (props) => {
    const { loading } = props;

    const cooperativa = useAppSelector((state) => state.cooperativaDetalle.cooperativa!);

    const headerConfig = {
      breadcrumb: {
        itemRender(route) {
          return <Link to={route.path!}>{route.breadcrumbName}</Link>;
        },
        items: [
          {
            path: ROUTES.COOPERATIVAS_LIST,
            breadcrumbName: 'Cooperativas'
          },
          {
            path: ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`),
            breadcrumbName: cooperativa?.cuit
              ? formatCuit(cooperativa?.cuit)
              : cooperativa?.nombre || '...'
          },
          {
            path:
              ROUTES.COOPERATIVAS_DETAILS.replace(':id', `${cooperativa?.id}`) +
              '?seccion=rubricas',
            breadcrumbName: 'Rubricas'
          },
          {
            path: '',
            breadcrumbName: 'Nuevo'
          }
        ]
      }
    } as PageContainerProps['header'];

    return (
      <PageContainer
        header={headerConfig}
        title="Nueva rubrica"
        loading={loading || !cooperativa}
        content="Desde esta sección se pueden cargar los datos de una nueva rubrica para la cooperativa seleccionada."
      >
        <NewSubsidioForm />
      </PageContainer>
    );
  }
);

NewCooperativaRubricaContainer.displayName = 'NewCooperativaRubricaContainer';

export default NewCooperativaRubricaContainer;
