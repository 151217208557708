import axios from '../config/axios';

import type { EstadoSubsidio, EstadosSubsidiosQueryParams } from '../interfaces/estadosSubsidios';

const PATH = `estados_subsidios`;

/**
 * @description Recupera desde la API los estados civiles
 * @returns {Promise<EstadoSubsidio[]>} Estados civiles
 */
export const getEstadosSubsidio = async (params: EstadosSubsidiosQueryParams = {}) => {
  return await axios.get<{ data: EstadoSubsidio[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
