// Components
import { Button } from 'antd';
import { RouteContext } from '@ant-design/pro-layout';
import { useEffect, useRef, useState } from 'react';
import { DrawerForm, ProFormInstance, DrawerFormProps } from '@ant-design/pro-form';

type DrawerProps<T = Record<string, any>> = DrawerFormProps<T> & {
  maxWidth?: number;
  onValuesChange?: (
    changedValues: any,
    values: any,
    formRef?: React.MutableRefObject<ProFormInstance | undefined>
  ) => void;
  onFinish:
    | (((
        formData: T,
        formRef?: React.MutableRefObject<ProFormInstance | undefined>
      ) => Promise<boolean | void>) &
        ((
          formData: T,
          formRef?: React.MutableRefObject<ProFormInstance | undefined>
        ) => Promise<any>))
    | undefined;
} & { defaultOpen?: boolean };

export function CustomFromDrawer<T extends Record<string, any>>(props: DrawerProps<T>) {
  const formRef = useRef<ProFormInstance>();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(props.defaultOpen || false);

  /**
   *  Se registra un evento para que se pueda hacer un submit del formulario
   *  apretando la tecla Enter del teclado
   */
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (open && !loading) {
          formRef.current?.submit();
        }
      }
    };
    if (open) {
      document.addEventListener('keydown', listener);
    } else {
      document.removeEventListener('keydown', listener);
    }
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [open, loading]);

  return (
    <RouteContext.Consumer>
      {({ isMobile }) => (
        <DrawerForm
          formRef={formRef}
          requiredMark={false}
          onOpenChange={setOpen}
          width={isMobile ? '100%' : props.maxWidth || 700}
          submitter={{
            render: (actions: any) => {
              return [
                <Button
                  disabled={loading}
                  key="cancel"
                  onClick={() => {
                    actions.reset();
                  }}
                >
                  Limpiar
                </Button>,
                <Button
                  type="primary"
                  key="ok"
                  loading={loading}
                  onClick={() => {
                    actions.submit();
                  }}
                >
                  Confirmar
                </Button>
              ];
            }
          }}
          drawerProps={{
            forceRender: true,
            closable: !loading,
            destroyOnClose: true,
            ...(props.drawerProps || {})
          }}
          {...props}
          onValuesChange={(changedValues, allValues) => {
            if (props.onValuesChange) {
              props.onValuesChange(changedValues, allValues, formRef);
            }
          }}
          // Request que se ejecuta cuando el formulario es válido
          onFinish={async (formData) => {
            setLoading(true);
            if (props.onFinish)
              return props.onFinish(formData, formRef).finally(() => {
                setLoading(false);
              });
          }}
        >
          {props.children}
        </DrawerForm>
      )}
    </RouteContext.Consumer>
  );
}

CustomFromDrawer.displayName = 'CustomFromDrawer';

export default CustomFromDrawer;
