import axios from '../config/axios';

import type {
  Expediente,
  ExpedienteDetalle,
  ExpedientesQueryParams,
  NewExpedienteFormValues
} from '../interfaces/expediente';
import type { PaginatedResponse } from '../interfaces/api';

/**************************************************
 *
 *            Expedientes
 *
 *************************************************/

const EXPEDIENTES_PATH = 'expedientes';

/**
 * @description Recupera desde la API los expedientes de una cooperativa
 * @returns {Promise<PaginatedResponse<Expediente>>} Expedientes
 */
export const getExpedientes = async (params: ExpedientesQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Expediente>>(EXPEDIENTES_PATH, { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API un expediente de una cooperativa
 * @param {number | string} id Identificador del expediente
 * @returns {Promise<Expediente>} Expediente
 */
export const getExpediente = async (id: number | string) => {
  return await axios
    .get<{ data: ExpedienteDetalle }>(`${EXPEDIENTES_PATH}/${id}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la API un expediente de una cooperativa
 * @returns {Promise<Expediente>} Expediente
 */
export const newExpediente = async (data: NewExpedienteFormValues) => {
  return await axios.post<{ data: Expediente }>(EXPEDIENTES_PATH, data).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Actualiza en la API un expediente de una cooperativa
 * @param {number | string} id Identificador del expediente
 * @returns {Promise<Expediente>} Expediente
 */
export const updateExpediente = async (id: number | string, data: NewExpedienteFormValues) => {
  return await axios
    .put<{ data: Expediente }>(`${EXPEDIENTES_PATH}/${id}`, data)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Elimina en la API un expediente de una cooperativa
 * @param {number | string} id Identificador del expediente
 * @returns {Promise<Expediente>} Expediente
 */
export const deleteExpediente = async (id: number | string) => {
  return await axios.delete<void>(`${EXPEDIENTES_PATH}/${id}`).then((response) => {
    return response.data;
  });
};
