import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as RubricasLibrosService from '../../services/rubricaLibros.service';

import type { RootState } from '../store';

type Entity = Awaited<ReturnType<typeof RubricasLibrosService.getRubricasLibros>>[number];

export const fetchRubricasLibros = createAsyncThunk<Entity[]>(
  'rubricasLibros/fetch',
  (_params, api) => {
    const { rubricasLibros } = api.getState() as RootState;
    if (rubricasLibros.entities.length) return Promise.resolve(rubricasLibros.entities);
    return RubricasLibrosService.getRubricasLibros();
  }
);

interface RubricasLibrosState {
  loading: boolean;
  entities: Entity[];
}

const initialState: RubricasLibrosState = { loading: false, entities: [] };

const rubricasLibrosSlice = createSlice({
  name: 'rubricasLibros',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRubricasLibros.fulfilled, (state, action) => {
      state.entities = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchRubricasLibros.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchRubricasLibros.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const rubricasLibrosActions = rubricasLibrosSlice.actions;

export default rubricasLibrosSlice.reducer;
