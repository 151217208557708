import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as FiscalizacionsService from '../../services/fiscalizaciones.service';

type Entity = Awaited<ReturnType<typeof FiscalizacionsService.getFiscalizacion>>;

export const fetchFiscalizacion = createAsyncThunk<
  Entity,
  { cooperativa_id: number | string; fiscalizacion_id: number | string }
>('fiscalizacionDetalle/fetch', (params) => {
  return FiscalizacionsService.getFiscalizacion(params.cooperativa_id, params.fiscalizacion_id);
});

export const fetchAsyncFiscalizacion = createAsyncThunk<
  Entity,
  { cooperativa_id: number | string; fiscalizacion_id: number | string }
>('fiscalizacionDetalle/fetchAsync', (params) => {
  return FiscalizacionsService.getFiscalizacion(params.cooperativa_id, params.fiscalizacion_id);
});

interface FiscalizacionDetalleState {
  loading: boolean;
  fiscalizacion: Entity | null;
}

const initialState: FiscalizacionDetalleState = { loading: false, fiscalizacion: null };

const FiscalizacionDetalleSlice = createSlice({
  name: 'fiscalizacion_detalle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFiscalizacion.fulfilled, (state, action) => {
      state.fiscalizacion = action.payload;
      state.loading = false;
    });

    builder.addCase(fetchFiscalizacion.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchFiscalizacion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAsyncFiscalizacion.fulfilled, (state, action) => {
      state.fiscalizacion = action.payload;
      state.loading = false;
    });
  }
});

export const fiscalizacionDetalleActions = {
  fetchFiscalizacion,
  ...FiscalizacionDetalleSlice.actions
};

export default FiscalizacionDetalleSlice.reducer;
