// Components
import { EditOutlined } from '@ant-design/icons';
import { ProFormSelect } from '@ant-design/pro-form';
import CustomFromDrawer from '../../../components/drawer/form';
import { ProFormDatePicker, ProFormDigit } from '@ant-design/pro-form';
import { Button, message as Message, Row, Tooltip, notification as Notification, Col } from 'antd';

// Utils
import dayjs from 'dayjs';

// Redux
import { useAppSelector } from '../../../store/store';
import { updateRubrica } from '../../../services/rubricas.service';

// Constants
import { DatePickerDefaultProps } from '../../../constants/form';

// Interfaces
import { memo, type FC } from 'react';
import type { NewRubricaFormValues, Rubrica } from '../../../interfaces/rubrica';

interface EditRubricaDetailsProps {
  rubrica: Rubrica;
  onRefresh?: () => void;
}

export const EditRubricaDetails: FC<EditRubricaDetailsProps> = memo((props) => {
  const { rubrica } = props;
  const [message, messageContext] = Message.useMessage();
  const [notification, notificationContext] = Notification.useNotification();

  const libros = useAppSelector((state) => state.rubricasLibros.entities);

  return (
    <CustomFromDrawer<NewRubricaFormValues>
      maxWidth={700}
      title="Editar datos de la rúbrica"
      trigger={
        <Tooltip title="Editar datos">
          <Button type="primary" size="small" shape="round" icon={<EditOutlined />} />
        </Tooltip>
      }
      initialValues={{
        ...rubrica,
        hojas: [rubrica.desde_hoja, rubrica.hasta_hoja],
        fecha_entrada: rubrica.fecha_entrada ? dayjs(rubrica.fecha_entrada) : undefined,
        fecha_entrega: rubrica.fecha_entrega ? dayjs(rubrica.fecha_entrega) : undefined,
        fecha_rubrica: rubrica.fecha_rubrica ? dayjs(rubrica.fecha_rubrica) : undefined,
        rubrica_libros_id: rubrica.rubrica_libro ? rubrica.rubrica_libro.id : undefined
      }}
      onFinish={async (values) => {
        if (values.fecha_entrada) {
          values.fecha_entrada = dayjs(values.fecha_entrada, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        if (values.fecha_entrega) {
          values.fecha_entrega = dayjs(values.fecha_entrega, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        if (values.fecha_rubrica) {
          values.fecha_rubrica = dayjs(values.fecha_rubrica, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        if (!values.fecha_entrega) {
          values.fecha_entrega = null;
        }

        return updateRubrica(rubrica.id, values)
          .then(() => {
            message.success('Rubrica actualizada correctamente');
            if (props.onRefresh) props.onRefresh();
            return true;
          })
          .catch((error) => {
            let description =
              'Ocurrió un error innesperado al editar los datos de la rúbrica, por favor intente nuevamente';
            if (error && error.response.data.message) {
              description = error.response.data.message;
            }
            notification.error({
              description,
              placement: 'bottomRight',
              message: 'Error al actualizar los datos de la rúbrica'
            });
            return false;
          });
      }}
    >
      {messageContext}
      {notificationContext}
      <Row gutter={[16, 16]}>
        <Col span={24} lg={12}>
          <ProFormDatePicker
            required
            name="fecha_entrada"
            label="Fecha de entrada"
            fieldProps={DatePickerDefaultProps}
            placeholder="Seleccione la fecha de entrada del rubrica"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            name="fecha_entrega"
            label="Fecha de entrega"
            fieldProps={DatePickerDefaultProps}
            placeholder="Seleccione la fecha de entrega del rubrica"
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDatePicker
            required
            name="fecha_rubrica"
            label="Fecha de rubrica"
            fieldProps={DatePickerDefaultProps}
            placeholder="Seleccione la fecha de rubrica"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormSelect
            showSearch
            label="Libro"
            name="rubrica_libros_id"
            fieldProps={{
              style: { width: '100% !important' }
            }}
            options={libros.map((libro) => ({
              value: libro.id,
              label: libro.nombre
            }))}
            placeholder="Seleccione un libro para el rubrica"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDigit
            min={1}
            name="numero_libro"
            label="Número de libro"
            placeholder="Ingrese el número de libro"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        <Col span={24} lg={12}>
          <ProFormDigit
            min={1}
            name="cantidad_folios"
            label="Cantidad de folios"
            placeholder="Ingrese el número de libro"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col>

        {/* <Col span={24}>
          <ProFormDigitRange
            name="hojas"
            label="Hojas"
            placeholder={['Desde', 'Hasta']}
            fieldProps={{
              style: { width: '100% !important' }
            }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
          />
        </Col> */}
      </Row>
    </CustomFromDrawer>
  );
});

EditRubricaDetails.displayName = 'EditRubricaDetails';
