import axios from '../config/axios';

import type { TipoAsamblea, TipoAsambleaQueryParams } from '../interfaces/tipoAsamblea';

const PATH = `tipos_asambleas`;

/**
 * @description Recupera desde la API los tipos de asambleas
 * @returns {Promise<TipoAsamblea[]>} TipoAsambleas
 */
export const getTiposAsambleas = async (params: TipoAsambleaQueryParams = {}) => {
  return await axios.get<{ data: TipoAsamblea[] }>(PATH, { params }).then((response) => {
    return response.data.data;
  });
};
